import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import Protected from '../protected'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import ProjectPageV2 from '../../pages/projectPage/projectPageV2.tsx'
import './projectSliderLayoutV2.scss'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { startSetAllNotification } from '../../store/notification/notificationSlice'
import { setChatbotTokenInReducerThunk } from '../../store/orgs/orgsThunk'

function ProjectSliderComponent(props) {
  const location = useLocation()

  const dispatch = useDispatch()
  const projectId = location.pathname.includes('projects') ? props?.projectId : null

  const { isProjectSliderOpen } = useCustomSelector((state) => ({
    isProjectSliderOpen: state.appInfo.isProjectSliderOpen
  }))

  useEffect(() => {
    dispatch(startSetAllNotification(props?.orgId))
    dispatch(setChatbotTokenInReducerThunk())
  }, [props?.orgId])

  return (
    <Box className='flex justify-start overflow-y-scroll w-full h-screen'>
      <Box
        className={`${
          projectId && projectId !== `proj${props?.orgId}` && !isProjectSliderOpen && (props?.scriptId || props?.interfaceId)
            ? 'hidden'
            : 'flex'
        } ${
          !isProjectSliderOpen && props?.scriptId
            ? 'drawer'
            : !isProjectSliderOpen && props?.pluginId
            ? 'slider_collapsed'
            : 'h-screen relative'
        } ${isProjectSliderOpen ? 'drawer-open' : ''} project_slider `}
      >
        <Protected>
          <ProjectPageV2 />
        </Protected>
      </Box>

      {location.pathname.includes('notification') && <Box id='notification-embed-parent' className='w-full h-screen !z-[100]' />}
      <Outlet />
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(ProjectSliderComponent), [
    ParamsEnums.pluginId,
    ParamsEnums.projectId,
    ParamsEnums.embedding,
    ParamsEnums.scriptId,
    ParamsEnums.orgId,
    ParamsEnums.interfaceId,
    ParamsEnums.pluginId
  ])
)
