import React from 'react'
import { Chip } from '@mui/material'
import SwitchSteps from '../common/switchStepsV2.tsx'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, Tabnames } from '../../enums'
import { useCustomSelector } from '../../utils/deepCheckSelector'

function SliderActionButton({ tabName, disableOffButton = false, scriptId }) {
  // Fetching data using useCustomSelector hook
  const { isBasicFlow } = useCustomSelector((state) => ({
    compareSliderOpen: state.appInfo.compareSliderOpen,
    isBasicFlow: state?.flowJsonV2?.[scriptId]?.metaData?.isBasicFlow
  }))

  const isPublishedTab = tabName === Tabnames.PUBLISH

  return (
    !isBasicFlow &&
    (tabName === 'draft' ? ( // Render different UI based on tabName
      !isPublishedTab && !disableOffButton && <SwitchSteps />
    ) : (
      <Chip label='Published version' /> // Chip showing published version
    ))
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(SliderActionButton), [ParamsEnums.tabName, ParamsEnums.scriptId]) // Memoized component
)
