import { useNavigate } from 'react-router-dom'
import React, { useMemo, useEffect, useState } from 'react'
import { List, ListItemText, ListItemButton, ListItem, Box, Typography } from '@mui/material'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { ParamsEnums, Tabnames } from '../../enums'
import { getGlobalData } from '../../api/index'
import './search.scss'
import config from '../../config'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'

function Search({ orgId, filterQuery, scriptId }) {
  const navigate = useNavigate()
  const [globalData, setGlobalData] = useState({ data: [], isSearching: false })

  const { allProjects } = useCustomSelector((state) => ({
    allProjects: state?.projects?.projects[orgId]
  }))

  useEffect(() => {
    const fetchData = async () => {
      if (!filterQuery || /^\s*$/.test(filterQuery)) {
        setGlobalData((prev) => ({ ...prev, isSearching: false }))
        return
      }
      try {
        const data = await getGlobalData(filterQuery, orgId)
        setGlobalData({ data: data.data.data.data, isSearching: true })
      } catch (error) {
        setGlobalData((prev) => ({ ...prev, isSearching: false }))
      }
    }

    fetchData()
  }, [filterQuery, orgId])

  const groupedOptions = useMemo(() => {
    const grouped = globalData?.data?.reduce((acc, project) => {
      project.script_id.forEach((script) => {
        const groupKey = project.project_id === `proj${orgId}` ? script.serviceName || script.serviceId : project.project_id

        if (!acc[groupKey]) acc[groupKey] = []
        acc[groupKey].push({ ...script, project_id: project.project_id })
      })
      return acc
    }, {})

    return grouped || {}
  }, [globalData, orgId])

  const handleOptionClick = (option) => {
    if (option?.id) {
      navigate(
        `${config.projectsBaseUrl}/${orgId}/${option?.project_id}/service/${option?.serviceId}${config.workflowBaseUrl}/${option?.id}/${Tabnames.DRAFT}`
      )
    }
  }

  return (
    <Box className='w-full flex flex-col justify-end gap-4 p-3 h-screen overflow-scroll-y' sx={{ paddingBottom: '150px !important' }}>
      {Object.entries(groupedOptions).map(([group, options]) => (
        <List key={group}>
          <Typography variant='smallHeading'>
            {allProjects?.active?.[group]?.title || allProjects?.deleted?.[group]?.title || group}
          </Typography>
          {options.map((option) => (
            <ListItem disablePadding active={option?.id === scriptId} key={option.id}>
              <ListItemButton onClick={() => handleOptionClick(option)}>
                <ListItemText primary={option.title || `${option.serviceName || option.serviceId} Flow`} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ))}
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(Search), [ParamsEnums.orgId, ParamsEnums.scriptId]))
