import React from 'react'
import { useDispatch } from 'react-redux'
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined'
import { Box, Card, CardContent, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'
import { cardData } from '../../utils/utilities'
import CustomModal from '../../components/customModal/customModal'
import { closeModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'

// eslint-disable-next-line
const SupportDialog: React.FC<any> = () => {
  const dispatch = useDispatch()
  const { modalType } = useCustomSelector((state) => ({
    modalType: state.appInfo.modalType
  }))
  const handleClose = () => {
    dispatch(closeModalRedux({}))
  }
  return (
    <CustomModal fullScreen width='70vw' height='90vh' openModal={modalType === `modelForOpenTalktosupport`} onClose={handleClose}>
      <IconButton variant='iconbuttonModal' aria-label='close' className='w-fit' onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent className='m-auto max-w-[800px]'>
        <Box className='flex flex-col gap-4 p-0'>
          <DialogTitle id='alert-dialog-title' className='text-center p-0'>
            <Typography variant='h5' fontWeight='bold'>
              Our help options
            </Typography>
            <Typography fontWeight='bold'>Explore our resources and get the help you need:</Typography>
          </DialogTitle>
          <Box className='help-options-dialog flex flex-col items-center !gap-4 w-full'>
            {cardData.map((cardDetails: any, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <Link key={index} to={cardDetails.url} target='_blank' className='no-underline w-full'>
                <Card className='card-data'>
                  <CardContent className='flex justify-between items-center'>
                    <Typography component='div' className='flex items-center gap-4'>
                      <img src={cardDetails.imgSrc} alt={cardDetails.imgAlt} style={{ width: cardDetails.imgWidth || '60px' }} />
                      <Typography component='div'>
                        <Typography variant='h6' fontWeight='bold' sx={{ fontSize: cardDetails.textSize }}>
                          {cardDetails.title}
                        </Typography>
                        <Typography className='font-12'>{cardDetails.description}</Typography>
                      </Typography>
                    </Typography>
                    <ArrowOutwardOutlinedIcon />
                  </CardContent>
                </Card>
              </Link>
            ))}
          </Box>
        </Box>
      </DialogContent>
    </CustomModal>
  )
}

export default SupportDialog
