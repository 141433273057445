import { forwardRef, useEffect, useState } from 'react'
import ChatbotComponent from './ChatbotComponent.tsx'

async function getToken(embedToken) {
  const response = await fetch('https://routes.msg91.com/api/proxy/1258584/32nghul25/chatbot/loginuser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: embedToken
    }
  })
  const data = await response.json()
  return data
}
const ReactChatbot = forwardRef((props, ref) => {
  const { embedToken, onNewMessageRecieved } = props
  const [tokenDetails, setTokenDetails] = useState({})

  const {
    // config,
    token,
    userId,
    chatbot_id
  } = tokenDetails

  async function setToken() {
    const tokenD = await getToken(embedToken)
    setTokenDetails(tokenD?.data)
  }
  useEffect(() => {
    if (embedToken) {
      setToken()
    }
  }, [embedToken])

  const authorizeUserAndSetDetails = () => {
    localStorage.setItem('chatbotToken', token)
    setVerifiedState('verified')
    localStorage.setItem('chatbotUserId', userId)
  }

  useEffect(() => {
    if (token) authorizeUserAndSetDetails()
  }, [token])

  const [verifiedState, setVerifiedState] = useState('pending')
  switch (verifiedState) {
    case 'pending':
      return 'loading..'
    case 'failed':
      return 'failed!'
    case 'verified':
      return <ChatbotComponent onNewMessageRecieved={onNewMessageRecieved} chatbotRef={ref} interfaceId={chatbot_id} {...props} />
    default:
      return null
  }
})
ReactChatbot.displayName = 'ReactChatbot'
export default ReactChatbot
