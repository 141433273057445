import { useEffect, useState } from 'react'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import './authCongoPage.scss'
import { Box } from '@mui/system'
import { Alert, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'

const getAllQueryParams = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const params = {}

  // Iterate over query parameters using forEach
  queryParams.forEach((value, key) => {
    params[key] = value
  })

  return params
}

type AuthLevelPageProps = {
  setAuthLevel: (level: string) => void
  authLevel: string
}
/**
 * Extracts all query parameters from the current URL.
 *
 * @returns {Object} An object containing all query parameters.
 */
export function AuthLevel({ setAuthLevel, authLevel }: AuthLevelPageProps) {
  const [dropdown, setDropdown] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const { orgid, projectid, mode, level, scriptid, orgOrProjectName } = getAllQueryParams()
  const handleChange = async (e) => {
    setAuthLevel(e.target.value)
  }

  const setDropdownTrue = () => {
    if (!(level?.length || mode === 'embed')) {
      setDropdown(true)
    } else {
      setShowAlert(true)
    }
  }
  useEffect(() => {
    if (level?.length) setAuthLevel(level === 'org' ? orgid : projectid)
  }, [level])

  return (
    <Box className='w-100'>
      {!dropdown && (
        <Typography
          onClick={() => {
            setDropdownTrue()
          }}
          className=' w-100'
        >
          This auth will be available for entire {level || 'org'} -{' '}
          <span className='org-level'>
            {`${orgOrProjectName}` || level || `org`} {(level?.length || mode === 'embed') && ''}
          </span>
        </Typography>
      )}
      {showAlert && (
        <Alert severity='warning' className='py-2' style={{ maxWidth: '500px' }}>
          You cant change connection level here
        </Alert>
      )}
      {dropdown && (
        <Box>
          <Typography className='pt-2'>Choose where you want to add your connection</Typography>
          <Box className='py-2 w-100'>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>level</InputLabel>
              <Select labelId='demo-simple-select-label' id='demo-simple-select' value={authLevel} label='Age' onChange={handleChange}>
                <MenuItem value={orgid}>Org level</MenuItem>
                {projectid && <MenuItem value={projectid}>Folder level</MenuItem>}
                {scriptid && <MenuItem value={scriptid}>Script level</MenuItem>}
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}
    </Box>
  )
}
