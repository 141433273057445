import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { IconButton } from '@mui/material'
import './InterfaceChatbot.scss'

function MoveToDownButton({ movetoDown, showScrollButton }: any) {
  if (!showScrollButton) return null
  return (
    <IconButton
      onClick={movetoDown}
      className='move-to-down-button'
      sx={{
        position: 'fixed',
        bottom: '20px',
        right: '20px'
      }}
    >
      <KeyboardDoubleArrowDownIcon color='inherit' />
    </IconButton>
  )
}

export default MoveToDownButton
