/* eslint-disable no-new-func */
export const evalVariableAndCodeFromContext = (code = '', context = {}, _ = null) => {
  try {
    if (!code || !context) return ''
    const myFunction = new Function('context', '_', code)
    const data = myFunction(context, _)
    return { message: data, success: true }
  } catch (error) {
    return { message: `Error:: ${error?.message}`, success: false }
  }
}
