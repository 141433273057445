import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/system'
import { Button, Typography, LinearProgress, DialogTitle, DialogActions, DialogContent } from '@mui/material'
import FolderCopyIcon from '@mui/icons-material/FolderCopy'
import DescriptionIcon from '@mui/icons-material/Description'
import './duplicateFlowModal.scss'
import CustomDropDown from '../../../customDropDown/customDropDown'
import { closeModalRedux } from '../../../../store/appInfo/appInfoSlice.ts'
import { useCustomSelector } from '../../../../utils/deepCheckSelector'
import { getProjects } from '../../../../api/index'
import { errorToast } from '../../../customToast'
import addUrlDataHoc from '../../../../hoc/addUrlDataHoc.tsx'
import { ApiTypes, ParamsEnums, THUNK_CALL_STATUS, Tabnames } from '../../../../enums'
import config from '../../../../config'
import { moveProjectThunk, saveProjects } from '../../../../store/projects/projectsThunk'
import { duplicateScript, moveScript, saveScripts } from '../../../../store/scripts/scriptsThunk'
import CustomModal from '../../../customModal/customModal'
import CreateInputModalV2 from '../../../projectdashboard/customInputModal/createInputModalV2.tsx'
import { switchOrgId, validateOrgName } from '../../../../utils/utilities'
import { saveOrgs } from '../../../../store/orgs/orgsThunk'
import { selectActiveOrgs } from '../../../../store/orgs/orgsSelector'
import { ThunkResponseType } from '../../../../types/types.ts'

interface DuplicateFlowModalType {
  orgId: string
  projectId: string
  data: any
  title: string
  pluginId: string
  triggerId: string
  createFlow: boolean
  customStyle: string
  serviceId: string
  events: string
}

/**
 * component to   duplicate an existing flow or move it to a different project or organization.
 * component to create flow from the integration page (/make flow page)
 *
 * It provides a form for selecting the target organization and project, and optionally setting a new title for the duplicated flow.
 * It supports various modal types, such as duplicating scripts, moving scripts, and creating new flows.
 *
 * @param {string} props.orgId - The ID of the current organization.
 * @param {string} props.projectId - The ID of the current project.
 * @param {Object} props.data - Additional data required for the operation.
 * @param {string} props.title - The title of the modal dialog.
 * @param {string} props.pluginId - The plugin ID of the trigger to navigate to the fow page with service id
 * @param {string} props.triggerId - The ID of the trigger to make the flow form integration page.
 * @param {boolean} props.createFlow - Flag indicating whether the component is used to create a new flow.
 * @param {string} props.customStyle - Custom CSS class for styling the modal.
 * @param {string} props.serviceId - The ID of the service associated with the flow.
 * @param {string} props.scriptId - The ID of the script associated with the flow.
 * @param {string} props.events - The IDs comma seperated string of the events associated with the flow.
 * @returns {JSX.Element} - The rendered component.
 */

function DuplicateFlowModal({
  orgId,
  projectId,
  data,
  title,
  pluginId,
  triggerId,
  createFlow = false,
  customStyle,
  serviceId,
  events
}: DuplicateFlowModalType) {
  const dispatch = useDispatch()
  const [orgFields, setOrgFields] = useState({})
  const [projectFields, setProjectFields] = useState({})
  const [orgValue, setOrgValue] = useState(orgId)
  const [projectValue, setProjectValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState('')
  const [newTitle, setNewTitle] = useState('')
  const [disableButton, setDisableButton] = useState(false)
  const finalProjectId = projectId || `proj${orgId}`
  const { orgList, duplicateFlowId, modalType, sortedOrgList } = useCustomSelector((state) => ({
    orgList: state.orgs.orgs,
    duplicateFlowId: state.appInfo.duplicateFlowId,
    modalType: state.appInfo.modalType,
    sortedOrgList: selectActiveOrgs(state)?.sort((a, b) => a?.title?.localeCompare(b?.title)) || []
  }))
  const titleRef = useRef()

  const navigate = useNavigate()

  const eventArray = events && events?.split(',').map((event) => event.trim())
  /**
   * useEffect hook to update orgFields when orgList changes
   */
  useEffect(() => {
    // Get the keys of orgList object
    const orgKeys = Object.keys(orgList)

    // Initialize an empty object to store the transformed data
    let data = {}

    // Iterate through each key in orgList
    orgKeys.forEach((keys) => {
      // Transform the data by extracting the title from each orgList entry
      data = { ...data, [keys]: orgList[keys].name }
    })

    // Set the transformed data as orgFields
    setOrgFields(data)

    // Check if modalType is 'MoveProject'
    if (modalType === 'MoveProject') {
      // Remove the orgId entry from orgFields
      setOrgFields((prevOrg) => {
        delete prevOrg[orgId]
        return prevOrg
      })
    }
  }, [orgList])

  useEffect(() => {
    if (orgValue === '') return
    getOrgProject(orgValue)
  }, [])

  const getOrgProject = async (orgIdToMove: string) => {
    let projectList
    try {
      setIsLoading(true)
      let createDefaultProject = false
      if (!orgFields?.[orgIdToMove]?.meta?.org_status) {
        createDefaultProject = true
      }
      projectList = (await getProjects(orgIdToMove, ApiTypes.FLOW, createDefaultProject)).data.data?.projects
      projectList?.push({ id: `proj${orgIdToMove}`, title: 'No Folder', status: '1' })
      // eslint-disable-next-line
      modalType === 'MoveScript' &&
        orgIdToMove === orgId &&
        projectList?.length !== 1 &&
        (projectList = projectList.filter((item) => item.id !== finalProjectId))
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
      return
    }
    const projectKeys = Object.keys(projectList)
    let data = {}
    projectKeys.forEach((keys) => {
      if (projectList?.[keys]?.status === '1') {
        data = { ...data, [projectList[keys].id]: projectList[keys].title }
      }
    })
    setProjectFields(data)
  }

  const handleSelect = (e) => {
    if (e.target.value === 'org') setOpenModal(e.target.value)
    else {
      setOrgValue(e.target.value)
      getOrgProject(e.target.value)
    }
  }

  const handleSelectProject = (e) => {
    if (e.target.value === 'project') setOpenModal(e.target.value)
    else setProjectValue(e.target.value)
  }

  const handleCloseBtn = () => {
    if (createFlow) navigate(-1)
    dispatch(closeModalRedux())
  }

  const handleDuplicateClick = async () => {
    if (titleRef?.current?.value?.trim()?.length === 0 && projectValue && orgValue)
      return errorToast('Please fill all the required details')
    setDisableButton(true)
    if (titleRef?.current?.value?.trim()?.length < 1) {
      setDisableButton(false)

      return errorToast('Title cannot be empty')
    }
    try {
      dispatch(
        duplicateScript({
          newProjectId: projectValue,
          duplicateFlowId,
          newflowtitle: titleRef.current.value,
          currentProjectid: finalProjectId
        })
      ).then(async (e) => {
        setDisableButton(false)
        try {
          if (e.error) return
          if (orgId !== orgValue) await switchOrgId(orgValue, ' ')
        } catch (error) {
          errorToast("You don't have access to this organization")
          navigate(`${config.orgBaseUrl}`)
        }
        navigate(
          `${config.projectsBaseUrl}/${orgValue}/${projectValue}${serviceId ? `/service/${serviceId}` : ''}${config.workflowBaseUrl}/${
            e.payload?.data?.id
          }/${Tabnames.DRAFT}`
        )
        handleCloseBtn()
      })
    } catch (error) {
      setDisableButton(false)
      console.error(error)
    }
    return ''
  }

  const handleCreateFlow = async () => {
    if (titleRef.current.value.trim().length === 0 && projectValue && orgValue) {
      return errorToast('Please fill all the required details')
    }
    if (titleRef.current.value.trim().length < 1) {
      return errorToast('Title cannot be empty')
    }
    setDisableButton(true)
    try {
      await dispatch(
        saveScripts({
          project_id: projectValue,
          org_id: orgValue.toString(),
          title: titleRef.current.value,
          triggerDetails: {
            eventId: triggerId
          },
          functionDetails: {
            eventIdArray: eventArray || [],
            type: 'plugin'
          }
        })
      ).then(async (e) => {
        setDisableButton(false)
        if (e.error) {
          return
        }
        if (e?.meta?.requestStatus === THUNK_CALL_STATUS.FULFILLED) {
          await switchOrgId(orgValue, ' ')
          navigate(
            `${config.projectsBaseUrl}/${orgValue}/${projectValue}${pluginId ? `/service/${pluginId}` : ''}${config.workflowBaseUrl}/${
              e?.payload?.id
            }/${Tabnames.DRAFT}`
          )
        }
      })
    } catch (error) {
      console.error(error)
    }
    return ''
  }

  /**
   * Handle move button click event
   */
  const handleMoveClick = async () => {
    try {
      dispatch(moveScript({ currentProjectid: finalProjectId, newProjectId: projectValue, duplicateFlowId })).then(
        async (e: ThunkResponseType<{ data: any }>) => {
          if (e?.meta?.requestStatus === THUNK_CALL_STATUS.FULFILLED) {
            await switchOrgId(orgValue, ' ')
            handleCloseBtn()
            navigate(
              `${config.projectsBaseUrl}/${orgValue}/${projectValue}${serviceId ? `/service/${serviceId}` : ''}${config.workflowBaseUrl}/${
                e.payload?.data?.id
              }/${Tabnames.DRAFT}`
            )
          }
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Handles the click event for moving a project.
   */
  const handleProjectMoveClick = async () => {
    try {
      // Prepare the data to send
      const dataToSend = {
        id: data?.current?.projectId,
        new_org_id: orgValue,
        old_org_id: orgId,
        status: data?.current?.status
      }

      // Close the button
      handleCloseBtn()

      // Dispatch the moveProjectThunk action
      dispatch(moveProjectThunk(dataToSend))

      // Navigate to the new project URL
      navigate(`${config.projectsBaseUrl}/${orgValue}`)
    } catch (error) {
      console.error(error)
    }
  }

  const createLocation = async (e) => {
    e.preventDefault()
    if (openModal === 'org') {
      if (!validateOrgName(newTitle, sortedOrgList)) return
      await dispatch(
        saveOrgs({
          name: newTitle || '',
          count: sortedOrgList?.length
        })
      ).then((data: ThunkResponseType<{ id: string }>) => {
        if (data?.meta?.requestStatus === THUNK_CALL_STATUS.FULFILLED && data?.payload?.id) {
          setOpenModal('')
          setOrgValue(data?.payload?.id)
        }
      })
    } else if (openModal === 'project') {
      if (!(newTitle?.trim()?.length > 4)) {
        errorToast('Folder name too short')
        return
      }
      if (Object.values(projectFields)?.includes(newTitle?.trim())) {
        errorToast('Folder name already exists')
        return
      }

      const data = await dispatch(
        saveProjects({
          title: newTitle,
          org_id: orgValue.toString(),
          type: ApiTypes.FLOW
        })
      )
      if (data?.payload && data?.meta?.requestStatus === THUNK_CALL_STATUS.FULFILLED) {
        setProjectFields((prev) => ({ ...prev, [data?.payload?.payload?.id]: newTitle }))
        setProjectValue(data?.payload?.payload?.id)
        setOpenModal('')
      }
    }
  }
  return (
    <Box className=' duplicateFlowModal pb-3 pt-2'>
      {title && <DialogTitle id='customized-dialog-title'>{title || ''}</DialogTitle>}
      <DialogContent className={customStyle}>
        <Typography className='mt-2'> Select Organizations</Typography>
        <CustomDropDown
          fields={orgFields}
          value={orgValue}
          FormControlClassName='formcontrol-org mt-2'
          SelectClassName='select-dropdown'
          handleSelectChange={handleSelect}
          startIcon={<FolderCopyIcon />}
        />
        {isLoading && <LinearProgress />}
        {modalType !== 'MoveProject' && (
          <>
            <Typography className='mt-2'>Folders</Typography>
            <CustomDropDown
              fields={projectFields}
              value={projectValue}
              FormControlClassName='formcontrol-org mt-2'
              SelectClassName='select-dropdown'
              handleSelectChange={handleSelectProject}
              isDisable={!orgValue}
              // createField='project'
              startIcon={<DescriptionIcon />}
            />
          </>
        )}
        {(modalType === 'duplicateScript' || createFlow) && (
          <>
            <Typography className='mt-2'>Enter Flow Title</Typography>
            <TextField
              className='mt-1 d-block'
              inputProps={{ maxLength: '50' }}
              size='normal'
              inputRef={titleRef}
              id='outlined-basic'
              variant='outlined'
              disabled={!(orgValue && projectValue)}
              required
            />
          </>
        )}
      </DialogContent>
      <DialogActions className='px-4'>
        {modalType === 'duplicateScript' && (
          <Button variant='contained' onClick={handleDuplicateClick} disabled={disableButton}>
            {disableButton ? 'Duplicating...' : 'Duplicate'}
          </Button>
        )}
        {modalType === 'MoveScript' && (
          <Button variant='contained' disabled={!projectValue} onClick={handleMoveClick}>
            Move
          </Button>
        )}
        {modalType === 'MoveProject' && (
          <Button variant='contained' onClick={handleProjectMoveClick}>
            Move
          </Button>
        )}
        {createFlow && (
          <Button variant='contained' onClick={handleCreateFlow} disabled={disableButton}>
            {disableButton ? 'Creating Flow...' : 'Create Flow'}
          </Button>
        )}
        <Button onClick={handleCloseBtn}>Cancel</Button>
      </DialogActions>

      <CustomModal fullScreen={false} openModal={openModal || false}>
        <CreateInputModalV2
          title={`Create ${openModal}`}
          buttonTag={`Create ${openModal}`}
          handleSubmit={createLocation}
          setValue={setNewTitle}
          label={`${openModal} title`}
          onClose={setOpenModal}
        />
      </CustomModal>
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(DuplicateFlowModal), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.triggerId,
    ParamsEnums.serviceId,
    ParamsEnums.events
  ])
)
