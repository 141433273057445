import React from 'react'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import { Button, CircularProgress, Box } from '@mui/material'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, Tabnames } from '../../../enums'

/**
 * Type definition for the props of DryRunButton.
 */
type PreviewButtonProps = {
  slugName: string
  tabName: string
  testFunction?: () => void
  isRunning: boolean
}

/**
 * PreviewButton component for initiating a dry run of a function.
 *
 * This component is responsible for rendering a button that, when clicked, initiates a dry run of a function.
 * It handles the state of the button during the dry run process, displaying a loading indicator when the process is ongoing.
 *
 * @param {string} slugName - The slug name of the function.
 * @param {string} tabName - The name of the tab where the function is located.
 * @param {Function} [testFunction] - An optional function to be executed before the dry run.
 * @param {boolean} [isRunning] - Indicates button status.
 *
 * @returns {JSX.Element} - The rendered PreviewButton component.
 */
function PreviewButton({ slugName, isRunning, testFunction = () => {}, tabName }: PreviewButtonProps) {
  return (
    <Box>
      <Button
        variant='outlined'
        key={slugName}
        disabled={isRunning || tabName === Tabnames.PUBLISH}
        onClick={() => {
          testFunction()
        }}
        endIcon={isRunning ? <CircularProgress size={18} /> : <PlayArrowRoundedIcon />}
      >
        Test
      </Button>
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(PreviewButton), [ParamsEnums.slugName, ParamsEnums.tabName]))
