import React, { useEffect, useState } from 'react'
import { Box, TextField, Typography, Autocomplete, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useDispatch } from 'react-redux'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { ParamsEnums } from '../../enums'
import { renameOrgThunk } from '../../store/orgs/orgsThunk'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import './SettingsPanel.scss'
import { extractDomainFromString, validateOrgName } from '../../utils/utilities'
import { selectActiveOrgs } from '../../store/orgs/orgsSelector'
import { errorToast } from '../customToast'
import timezoneData from './timezone'
import { isValidDomainName } from '../../pages/developerHub/utils/utility.ts'

export function Header({ isCron }) {
  return (
    <Box className={`flex flex-col w-full ${isCron ? 'pl-3 pb-3' : 'p-3'}`}>
      <Typography variant={isCron ? 'h5' : 'h6'}>Edit workspace settings</Typography>
    </Box>
  )
}

export function Name({ handleRenameOnClick, loading, name }) {
  return (
    <Box className='flex flex-col gap-1'>
      <Typography variant='body1' gutterBottom fontWeight='600'>
        Name
      </Typography>
      <TextField
        className='w-full'
        defaultValue={name}
        inputProps={{ maxLength: 25 }}
        disabled={loading}
        dhcustompadding='true'
        onBlur={(event) => {
          const value = event.target.value.trim()
          if (!value) {
            errorToast('Name field cannot be empty')
          } else if (value !== name) {
            handleRenameOnClick(value)
          }
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
            handleRenameOnClick(event.target.value.trim())
          }
        }}
      />
    </Box>
  )
}

export function TimeZoneSection({ children, error, timezone, handleTimezoneChange, setTimezone = () => {}, required = false }) {
  const defaultProps = {
    options: timezoneData,
    getOptionLabel: (option: { identifier: string; offSet: string }) => {
      return `${option.identifier} ${option.offSet}`
    }
  }

  return (
    <Box className='flex flex-col gap-1'>
      <Typography variant='body1' gutterBottom fontWeight={!required ? '600' : null}>
        Timezone
      </Typography>
      <Autocomplete
        {...defaultProps}
        id='timezone-select'
        options={timezoneData}
        getOptionLabel={(option) => `${option.identifier} (${option.offSet || ''})`}
        value={timezone}
        isOptionEqualToValue={(option, value) => option.identifier === value.identifier && option.offSet === value.offSet}
        onChange={(event, newValue) => {
          if (newValue) {
            setTimezone(newValue)
            handleTimezoneChange(newValue)
          }
        }}
        renderInput={(params) => (
          <TextField {...params} variant='outlined' required={required} error={error} helperText={required ? error?.message : null} />
        )}
      />
      {children}
    </Box>
  )
}

// eslint-disable-next-line
TimeZoneSection.Information = () => (
  <Typography variant='body2' color='textSecondary' gutterBottom>
    All logs, data, and other relevant information will be displayed according to this timezone. Additionally, your Metrics data will be
    stored, and cron jobs will be scheduled based on this timezone.
  </Typography>
)

export function DomainSection({ orgDomain, setOrgDomain, updateDomainUrlInOrg, loading, orgLoading, handleDrawerClose }) {
  return (
    <Box className='flex flex-col gap-1'>
      <Typography variant='body1' gutterBottom fontWeight='600'>
        Domain URL
      </Typography>
      <TextField
        className='w-full'
        value={orgDomain}
        dhcustompadding='true'
        onChange={(e) => {
          setOrgDomain(e.target.value)
        }}
      />
      <Typography variant='body2' color='textSecondary'>
        Example: www.msg91.com
      </Typography>
      <Box className=' mt-4 w-full'>
        <Box className='flex gap-3'>
          <LoadingButton
            onClick={() => {
              updateDomainUrlInOrg()
            }}
            variant='contained'
            disabled={loading}
            loading={orgLoading}
          >
            Save
          </LoadingButton>
          <Button variant='outlined' onClick={handleDrawerClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

function WorkspaceSetting({
  orgId,
  handleDrawerClose = () => {},
  isCron = false,
  orgLoading = false
}: {
  orgId: string
  handleDrawerClose: () => void
  isCron: boolean
  orgLoading: boolean
}) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const { orgObj, sortedOrgList, timezoneIdentifier, timezoneOffset } = useCustomSelector((state: any) => ({
    orgObj: state?.orgs?.orgs?.[orgId],
    sortedOrgList: selectActiveOrgs(state)?.sort((a, b) => a?.title?.localeCompare(b?.title)) || [],
    timezoneIdentifier: state?.orgs?.orgs?.[orgId]?.meta?.timezoneIdentifier,
    timezoneOffset: state?.orgs?.orgs?.[orgId]?.timezone
  }))

  const [orgDomain, setOrgDomain] = useState(orgObj?.meta?.domainUrl)
  const [timezone, setTimezone] = useState<{ identifier: string; offSet: string } | null>(null)

  // Function to handle renaming the organization
  const handleRenameOnClick = async (value: string) => {
    if (!value.trim()) {
      errorToast('Name field cannot be empty')
      return
    }
    if (value.includes(' ')) {
      errorToast('Name field cannot contain spaces')
      return
    }
    if (value && validateOrgName(value, sortedOrgList)) {
      if (!loading) {
        setLoading(true)
        try {
          await dispatch(renameOrgThunk({ body: { title: value }, orgId }))
        } catch (error) {
          errorToast('Unable to update organization. Please try again.')
        } finally {
          setLoading(false)
        }
      }
    }
  }

  // Handle the timezone change logic
  useEffect(() => {
    if (timezoneIdentifier && timezoneOffset) {
      setTimezone({ identifier: timezoneIdentifier, offSet: timezoneOffset })
    }
  }, [timezoneIdentifier, timezoneOffset])

  /**
   * Updates the domain URL in the organization metadata.
   * Validates the domain name before dispatching the update.
   */
  const [orgMeta, setOrgMeta] = useState({})
  const handleTimezoneChange = (newValue: { identifier: string; offSet: string }) => {
    if (newValue) {
      try {
        setOrgMeta({ ...orgObj?.meta, timezoneIdentifier: newValue.identifier })
      } catch (error) {
        errorToast('Unable to update timezone. Please try again.')
      }
    }
  }

  const updateDomainUrlInOrg = async () => {
    handleDrawerClose()
    const trimmedOrgDomain = orgDomain ? orgDomain.trim() : ''
    const extractedOrgDomain = extractDomainFromString(trimmedOrgDomain)
    const domainurl = isValidDomainName(extractedOrgDomain)
    if (extractedOrgDomain && !domainurl) {
      errorToast('Domain is not valid')
      return
    }
    const updatedOrgMeta = { ...orgMeta, domainUrl: extractedOrgDomain }
    await dispatch(renameOrgThunk({ body: { meta: updatedOrgMeta, timezone: timezone?.offSet }, orgId }))
  }

  return (
    <Box>
      <Header isCron={isCron} />
      <Box className='px-3 py-1 flex flex-col gap-4'>
        <Name handleRenameOnClick={handleRenameOnClick} loading={loading} name={orgObj?.name} />
        <TimeZoneSection handleTimezoneChange={handleTimezoneChange} setTimezone={setTimezone} timezone={timezone}>
          <TimeZoneSection.Information />
        </TimeZoneSection>
        <DomainSection
          handleDrawerClose={handleDrawerClose}
          loading={loading}
          orgDomain={orgDomain}
          orgLoading={orgLoading}
          setOrgDomain={setOrgDomain}
          updateDomainUrlInOrg={updateDomainUrlInOrg}
        />
      </Box>
    </Box>
  )
}
export default addUrlDataHoc(React.memo(WorkspaceSetting), [ParamsEnums.orgId, ParamsEnums.projectId])

export const calculateRemainingDays = (expiry) => {
  if (!expiry) return 0
  const targetDate = new Date(expiry)
  const currentDate = new Date()
  const timeDifference = targetDate - currentDate
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
  return daysRemaining
}
