import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import './pages/pageNotFound/notFoundPage.scss'
import NotFoundPageSvg from './pages/pageNotFound/NotFoundPageSvg.tsx'

export default function ForbiddenErrorPage() {
  return (
    <Box className='notFound flex-col-center-center gap-3'>
      <NotFoundPageSvg />
      <Typography variant='h1' className='font-bold'>
        Not Authorized
      </Typography>
      <Typography className='color-grey'>Sorry, Either the field is missing or You are not authorized to access this flow!</Typography>
      <Button variant='contained' className='page-notfound-btn' component={Link} to='/'>
        HOME
      </Button>
    </Box>
  )
}
