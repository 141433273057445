import { useMediaQuery } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { useDispatch } from 'react-redux'
import { closeModalRedux } from '../../store/appInfo/appInfoSlice.ts'

export default function CustomModal(props) {
  const dispatch = useDispatch()
  const isWideScreen = useMediaQuery('(min-width:1024px)')
  const handleKeyPress = () => {
    if (props?.onClose) {
      props?.onClose()
    } else {
      dispatch(closeModalRedux())
    }
  }

  return (
    <Dialog
      fullScreen={props?.fullScreen}
      scroll='body'
      PaperProps={{
        sx: {
          width: isWideScreen ? props?.width || '50vw' : '100%',
          maxWidth: isWideScreen ? 'unset !important' : undefined,
          backgroundColor: props?.bgcolor || '#fff',
          height: props?.height || 'unset'
        }
      }}
      open={props?.openModal}
      onClose={handleKeyPress}
      aria-labelledby='responsive-dialog-title'
    >
      {props?.children}
    </Dialog>
  )
}
