/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { defaultStyle, getSubstringIndex } from './utils'

function Suggestion({ id, focused, ignoreAccents, index, onClick, onMouseEnter, query, renderSuggestion, suggestion, style, group = '' }) {
  const rest = { onClick, onMouseEnter }

  const renderContent = () => {
    const display = getDisplay()
    const highlightedDisplay = renderHighlightedDisplay(display, query)

    if (renderSuggestion) {
      return renderSuggestion(suggestion, query, highlightedDisplay, index, focused)
    }

    return highlightedDisplay
  }

  const getDisplay = () => {
    if (typeof suggestion === 'string') {
      return suggestion
    }

    const { id, display } = suggestion

    if (id === undefined || !display) {
      return id
    }
    const lastIndexOfDot = display.lastIndexOf('.')
    return lastIndexOfDot > 0 ? display?.slice(lastIndexOfDot + 1) : display
  }

  const renderHighlightedDisplay = (display) => {
    const i = getSubstringIndex(display, query, ignoreAccents)

    if (i === -1) {
      return <span {...style('display')}>{display}</span>
    }

    return (
      <span {...style('display')}>
        {display.substring(0, i)}
        <b {...style('highlight')}>{display.substring(i, i + query.length)}</b>
        {display.substring(i + query.length)}
      </span>
    )
  }
  return (
    <li id={id} role='option' aria-selected={focused} {...rest} {...style} className='border-bottom-none'>
      {renderContent()}
    </li>
  )
}

Suggestion.propTypes = {
  id: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  ignoreAccents: PropTypes.bool,

  suggestion: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      display: PropTypes.string
    })
  ]).isRequired,
  renderSuggestion: PropTypes.func,

  focused: PropTypes.bool
}

const styled = defaultStyle(
  {
    cursor: 'pointer'
  },
  (props) => ({ '& .focused': props.focused })
)

export default styled(Suggestion)
