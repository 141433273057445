import React, { useMemo } from 'react'
import MarkdownIt from 'markdown-it'
import insert from 'markdown-it-ins'

// LinkRenderer component for rendering links
const LinkRenderer = React.memo(({ href, children }) => (
  <a href={href} target='_blank' rel='noreferrer'>
    {children}
  </a>
))

LinkRenderer.displayName = 'LinkRenderer'

function CustomMarkdown({ help = '', children = '', ...rest }) {
  // Create the MarkdownIt instance and apply the insert plugin
  const md = useMemo(() => {
    const markdown = new MarkdownIt({ html: true })
    markdown.use(insert) // Add the 'insert' plugin
    markdown.renderer.rules.link_open = (tokens, idx) => {
      const href = tokens[idx].attrGet('href')
      return `<a href="${href}" target="_blank" rel="noreferrer" style="color: blue; text-decoration: underline;">`
    }
    return markdown
  }, [])

  // Render the markdown using MarkdownIt
  const renderedContent = useMemo(() => {
    if (help || children) {
      return md.render(help || children)
    }
    return ''
  }, [help, children, md])

  return (
    <div {...rest}>
      {/* eslint-disable-next-line  */}
      <div dangerouslySetInnerHTML={{ __html: renderedContent }} />
    </div>
  )
}

export default CustomMarkdown
