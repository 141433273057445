import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import React from 'react'
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined'
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined'
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded'
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined'
import SpaceDashboardRoundedIcon from '@mui/icons-material/SpaceDashboardRounded'
import SecurityIcon from '@mui/icons-material/Security'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import ProjectList from '../../components/projectdashboard/projectList/projectList.tsx'
import CreateNewPopover from '../../components/projectdashboard/createNewPopover.tsx'
import LeaveWorkspaceButton from './LeaveWorkspaceButton.tsx'
import SupportDialog from '../supportModel/SupportDialog.tsx'
import config from '../../config'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import selectActiveUser from '../../store/user/userSelector'
import './projectPage.scss'
import { isThreeMonthOld } from '../interface/utils/InterfaceUtils.ts'

function ProjectSliderOptionsAndButtons({ orgId, open, projectId }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { currentOrgCreatedBy, userData, orgCreatedAt } = useCustomSelector((state: $ReduxCoreType) => ({
    currentOrgCreatedBy: state.orgs?.orgs?.[orgId]?.created_by,
    userData: selectActiveUser(state),
    orgCreatedAt: state.orgs?.orgs?.[orgId]?.created_at
  }))
  const handleMetrics = () => {
    navigate(`${config.orgBaseUrl}/${orgId}/metrics`)
  }
  const handleDashBoard = () => {
    navigate(`${config.projectsBaseUrl}/${orgId}`)
  }
  const isThreeMonthOldOrg = isThreeMonthOld(orgCreatedAt)
  return (
    <Box className='flex flex-col w-full h-full overflow-y-scroll border-b-sharp' sx={{ minHeight: 'calc(100vh - 100px)' }}>
      <Box className={`pb-64 lists-when-dropdown-close ${!open && 'visible'}`}>
        <Box className='space-y-2'>
          <CreateNewPopover defaultProjectId={`proj${orgId}`} />
          <List className='mx-2 mb-3 space-y-2'>
            <ListItem
              disablePadding
              disableGutters
              onClick={handleDashBoard}
              className={`${location.pathname === `${config.projectsBaseUrl}/${orgId}` ? 'selected-list-item' : ''} 
              `}
            >
              <ListItemButton>
                <ListItemIcon>
                  {location.pathname === `${config.projectsBaseUrl}/${orgId}` ? (
                    <SpaceDashboardRoundedIcon />
                  ) : (
                    <SpaceDashboardOutlinedIcon />
                  )}
                </ListItemIcon>
                {/* <ListItemText primary='Dashboard' className='!font-semibold !text-base !leading-5'/> */}
                <Typography variant='projectSlider'>Dashboard</Typography>
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              disableGutters
              onClick={handleMetrics}
              className={`${location.pathname.includes('metrics') ? 'selected-list-item' : ''}`}
            >
              <ListItemButton>
                <ListItemIcon>
                  <EqualizerRoundedIcon />
                </ListItemIcon>
                <Typography variant='projectSlider'>Metrics</Typography>
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              onClick={() => navigate(`${config.authBaseUrl}/${orgId}`)}
              className={`${location.pathname.startsWith(config.authBaseUrl) ? 'selected-list-item' : ''}`}
            >
              <ListItemButton>
                <ListItemIcon>
                  <SecurityIcon />
                </ListItemIcon>
                <Typography variant='projectSlider'>Connections</Typography>
              </ListItemButton>
            </ListItem>

            {isThreeMonthOldOrg && (
              <ListItem
                disablePadding
                onClick={() => navigate(`/projects/${orgId}/appsexplore/servicepage`)}
                className={`${location.pathname.startsWith(`/projects/${orgId}/appsexplore`) && !projectId ? 'selected-list-item' : ''}`}
              >
                <ListItemButton>
                  <ListItemIcon className='lists__icon'>
                    <ExtensionOutlinedIcon />
                  </ListItemIcon>
                  <Typography variant='projectSlider'>All Apps</Typography>
                </ListItemButton>
              </ListItem>
            )}
          </List>
          <Divider className='my-2' variant='normal' />

          <Box className='mx-2'>
            <ProjectList isThreeMonthOldOrg={isThreeMonthOldOrg} />
          </Box>
        </Box>
        {/* list of all other menus */}
        <Box className='mb-10 mx-2'>
          <List className='flex flex-col'>
            <ListItemButton
              target='_blank'
              onClick={() => {
                dispatch(updateAppInfoState({ modalType: `modelForOpenTalktosupport` }))
              }}
              disablePadding
              className='list-item py-2'
            >
              <ListItemIcon>
                <HeadsetMicOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary='Talk to Support' />
              <ChevronRightIcon className='transform-8px' />
            </ListItemButton>
            <SupportDialog />
          </List>
        </Box>
      </Box>
      {open && userData?.userId !== currentOrgCreatedBy && <LeaveWorkspaceButton />}
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(ProjectSliderOptionsAndButtons), [ParamsEnums.orgId, ParamsEnums.projectId]))
