import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { Box, Button, Divider, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CreateFlowGradientBG } from '../../assests/assestsIndex.ts'
import config from '../../config'
import { ParamsEnums, Tabnames, THUNK_CALL_STATUS } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { saveScripts } from '../../store/scripts/scriptsThunk'
import AddStepSlider from '../masterSliderComponent/AddStepSlider.tsx'

interface CreateNewFlowModalProps {
  orgId: string
  finalProjectId: string
}

function CreateNewFlowModal({ orgId, finalProjectId }: CreateNewFlowModalProps) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState('')

  const createFlow = (): void => {
    dispatch(updateAppInfoState({ modalType: false }))
    dispatch(
      saveScripts({
        project_id: finalProjectId,
        org_id: orgId
      })
    ).then((e) => {
      if (e?.meta?.requestStatus === THUNK_CALL_STATUS?.FULFILLED) {
        navigate(
          `${config.projectsBaseUrl}/${orgId}/${finalProjectId}${config.workflowBaseUrl}/${e.payload.id}/${Tabnames.DRAFT}?stepId=SET_TRIGGER&slugName=selecttrigger`
        )
      }
    })
  }

  return (
    <Box
      className='w-full flex !md:flex-col'
      sx={{ height: { xs: '70vh', sm: '60vh', md: '60vh', lg: '70vh', xl: '60vh' }, zIndex: 99999999 }}
    >
      <Box className='w-3/5 p-5 overflow-y-scroll'>
        <Typography variant='h4'>Select Trigger</Typography>
        <Box className='mt-3'>
          <AddStepSlider title='' placeholder='Search Trigger' type='trigger' createFlow finalProjectId={finalProjectId} />
        </Box>
      </Box>
      <Divider orientation='vertical' flexItem variant='sharp' />
      <Box className='w-2/5 relative h-full flex justify-center items-center'>
        <img className='absolute object-cover w-full h-full left-0' src={CreateFlowGradientBG} alt='' />
        <Box className='flex-col justify-center items-end p-4 absolute inset-0'>
          <Box className='w-full' sx={{ boxShadow: '5px 5px 0px rgba(0, 0, 0, 0.8)' }}>
            <TextField
              className='bg-white'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder='Ex: When any order created in Shopify, send message on Slack'
              minRows={6}
              multiline
              maxRows={8}
              fullWidth
            />
          </Box>
          <Button
            variant='contained'
            onClick={() => {
              dispatch(updateAppInfoState({ flowByAiWhenCreateFlowText: inputValue.trim() }))
              createFlow()
            }}
            endIcon={<AutoAwesomeIcon />}
            className='mt-3 float-end'
          >
            Start with AI
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(CreateNewFlowModal), [ParamsEnums.orgId]))
