import { Box, Card, Chip, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ALERTTYPES, ParamsEnums } from '../../enums'
import { RenderIcons } from '../../utils/utilities'
import './TemplatesGallery.scss'
import { getAllTemplatesOfOrg } from '../../api/index'
import { sendAlert } from '../../utils/alertUtility.ts'

function Templates({ handleTemplateClick, templatesHeading = 'Templates', orgId }) {
  const [templates, setTemplates] = useState(null)
  useEffect(() => {
    fetchTemplates()
  }, [])
  const fetchTemplates = async () => {
    try {
      setTemplates(null)
      const data = await getAllTemplatesOfOrg(orgId)
      setTemplates(data || [])
    } catch (error) {
      sendAlert(error, ALERTTYPES.FRONTEND, 'getTemplatesTriggerWise api alert')
      setTemplates(null)
    }
  }
  return Array.isArray(templates) && templates?.length ? (
    <Box className='  w-100 flex flex-col'>
      <Typography variant='h6' className='px-2'>
        {templatesHeading}
      </Typography>
      <Box className='container'>
        {templates?.map((template) => {
          return (
            <Box className='p-2 grid-item' key={template?.id}>
              <Card className='templates_box w-full p-2 flex-col-spaceBetween-start' onClick={() => handleTemplateClick(template)}>
                <Box className='column gap-3'>
                  <RenderIcons flowJsonBlocks={template?.published_json_script?.blocks} size='24px' alternate='' />
                  <Typography variant='h6'>{template?.title}</Typography>
                  {template?.metadata?.description}
                </Box>
                <Chip variant='outlined' label='Use it' />
              </Card>
            </Box>
          )
        })}
      </Box>
    </Box>
  ) : (
    !Array.isArray(templates) && (
      <Box className='flex-start-center gap-2 w-100 flex-wrap p-2 pt-3'>
        {Array.from({ length: 4 }, (_, index) => index).map((el) => {
          return <Skeleton height='300px' key={el} variant='rectangular' width='320px' />
        })}
      </Box>
    )
  )
}
export default React.memo(addUrlDataHoc(React.memo(Templates), [ParamsEnums.orgId]))
