import React from 'react'
import { Box, Divider } from '@mui/material'
import { ParamsEnums, Tabnames } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import SliderActionButton from './SliderActionButton'
import CloseSlidersButtonV2 from '../common/closeSlidersButtonV2.tsx'
import StepTitleTextfieldComponent from './StepTitleTextfieldComponent.tsx'

interface StepNameComponentV2Props {
  slugName: string
  stepId: string
  tabName: string
  scriptId: string
}
/**
 * This component is responsible for rendering the step name input field and handling its state.
 * It utilizes the `useCustomSelector` hook to fetch the step's title, type, and other relevant data from the Redux store.
 * The component also includes error handling for invalid input and provides a toggle for editing the step name.
 *
 * @param {Object} props - The component props.
 * @param {string} props.slugName - The slug name of the step.
 * @param {string} props.stepId - The unique identifier of the step.
 * @param {string} props.tabName - The name of the tab under which the step is located.
 * @param {string} props.scriptId - The unique identifier of the script.
 */
function StepNameComponentV2({ slugName, stepId, tabName, scriptId }: StepNameComponentV2Props) {
  // author:@Arpit
  const { currentStepType, brandColor, isBasicFlow } = useCustomSelector((state: $ReduxCoreType) => {
    const flowJson = state?.flowJsonV2?.[scriptId]?.flowJson
    const stepData = state.stepsDataV3?.[scriptId]?.[stepId]?.[tabName]
    return {
      brandColor: stepData?.pluginData?.brandcolor,
      currentStepType: flowJson?.blocks?.[slugName]?.type || state.appInfo.currentStepType,
      isBasicFlow: state?.flowJsonV2?.[scriptId]?.metaData?.isBasicFlow
    }
  })

  return (
    <Box className='column w-100  z-index-98 w-full ' id='step-name-component' key={tabName + currentStepType}>
      {brandColor && <Box sx={{ backgroundColor: brandColor }} className='w-100 brand-label ' />}
      {tabName !== Tabnames.PUBLISH && !isBasicFlow && (
        <Box className='fullscreen-show'>
          <CloseSlidersButtonV2 isBackButton />
        </Box>
      )}
      <Box className='flex-spaceBetween-center gap-2 px-3 py-2 step-header'>
        <StepTitleTextfieldComponent />

        {!isBasicFlow && (
          <Box className='flex-start-center gap-1'>
            <SliderActionButton />
            <CloseSlidersButtonV2 type='icon' />
          </Box>
        )}
      </Box>
      <Divider />
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(StepNameComponentV2), [ParamsEnums.slugName, ParamsEnums.stepId, ParamsEnums.tabName, ParamsEnums.scriptId])
)
