import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Button, ListItem, ListItemButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from '../../enums'
import CustomDialog from '../../components/customDialog/CustomDialog.tsx'
import { switchOrgId } from '../../utils/utilities'
import { leaveCompany } from '../../api'
import { fetchOrgs } from '../../store/orgs/orgsThunk'
import config from '../../config'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { $ReduxCoreType } from '../../types/reduxCore.ts'

function LeaveWorkspaceButton({ orgId }) {
  const { orgs } = useCustomSelector((state: $ReduxCoreType) => ({
    orgs: state?.orgs?.orgs
  }))

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const getRandomOrg = () => {
    const orgsList = Object.entries(orgs)
    let [key, value] = orgsList?.[0]
    if (key === orgId) [key, value] = orgsList?.[1]
    return { key, name: value?.name }
  }
  const handleOptionsBtn = async (currentOrg) => {
    const { key, name } = getRandomOrg()
    await switchOrgId(key, name)
    await leaveCompany({ company_id: Number(currentOrg) })
    navigate(config.orgBaseUrl)
    dispatch(fetchOrgs())
  }

  const handleOpenLeaveDialog = () => setLeaveDialog(true)
  const [openLeaveDialog, setLeaveDialog] = useState(false)
  const handleCloseLeaveDialog = () => setLeaveDialog(false)
  return (
    <>
      {' '}
      <ListItem className='mt-auto mb-3' disablePadding>
        <ListItemButton className='pl-3' onClick={handleOpenLeaveDialog}>
          <Typography>Leave Workspace</Typography>
        </ListItemButton>
      </ListItem>
      <CustomDialog
        open={openLeaveDialog}
        onClose={handleCloseLeaveDialog}
        title='Are you sure you want to leave this Workspace?'
        content='By leaving this Workspace, you will lose access to its resources and data. This action is irreversible, and you will no longer be able to view or interact with this Workspace. Do you wish to proceed?'
        actions={
          <Box className='flex gap-2'>
            <Button variant='outlined' onClick={handleCloseLeaveDialog} autoFocus sx={{ px: 4, py: 1 }}>
              No
            </Button>
            <Button variant='contained' onClick={() => handleOptionsBtn(orgId)} sx={{ px: 4, py: 1 }}>
              Yes
            </Button>
          </Box>
        }
      />
    </>
  )
}
export default React.memo(addUrlDataHoc(React.memo(LeaveWorkspaceButton), [ParamsEnums.orgId]))
