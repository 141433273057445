/* eslint-disable */
import { Add } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { StatusIconAndTime } from '../../components/LogsDataTimeChip/LogsDataTimeChip.tsx'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { setInvocationDataV2 } from '../../store/invocationV2/invocationSliceV2.ts'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { useCustomSelector } from '../deepCheckSelector'
import ContextVariableMenu from './ContextVariableMenu.tsx'

function AddVariableButton({
  useIt = () => {},
  isHidden = false,
  sectionIdOrScriptId,
  sectionKey
}: {
  sectionIdOrScriptId: string
  useIt: () => void
  isHidden: boolean
  sectionKey: string
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  // const { topInvocations, logId, invocationData } = useCustomSelector((state: $ReduxCoreType) => ({
  //   topInvocations: state.invocationV2?.topInvocations?.[sectionIdOrScriptId] || [],
  //   logId: state.invocationV2?.['selectedLog']?.invocationData?.flowHitId,
  //   invocationData: state.invocationV2?.['selectedLog']?.invocationData
  // }))

  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  // const isUserOnDh = !!sectionKey
  // console.log(topInvocations,'topInvocations')
  const handleSetLogData = (value: any) => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set('flowhitid', value?.flowHitId)
    setSearchParams(newSearchParams)
    dispatch(setInvocationDataV2(value))
  }
  return (
    <>
      {' '}
      <IconButton
        className={`bg-primary m-2  ${!anchorEl && isHidden ? 'opacity-0' : ''}`}
        onClick={(e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        }}
      >
        <Add color='white' fontSize='small' />
      </IconButton>
      {/* {!logId && anchorEl && !isUserOnDh && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          className='max-h-80'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuItem style={{ pointerEvents: 'none' }}>Log is not selected, please select a log to add a variable</MenuItem>
          {topInvocations.map((value: any) => {
            const bodyData = JSON.parse(value.requestSnapshot)?.body
            return (
              <MenuItem key={value?.flowHitId} onClick={() => handleSetLogData(value)}>
                <StatusIconAndTime innvocationData={value} isColored={false} isLogSliderOpen={false} />
                <Typography variant='body2' className='ml-2'>
                  - {Object.values(bodyData).join(', ').slice(0, 50)}
                </Typography>
              </MenuItem>
            )
          })}
        </Menu>
      )} */}
      {anchorEl && <ContextVariableMenu useIt={useIt} parentAnchorEl={anchorEl} setParentAnchorEl={setAnchorEl} />}
    </>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(AddVariableButton), [
    ParamsEnums.stepId,
    ParamsEnums.sectionIdOrScriptId,
    ParamsEnums.slugName,
    ParamsEnums.tabName,
    ParamsEnums.sectionKey
  ])
)
