import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { toggleLogsSlider } from '../../store/appInfo/appInfoSlice.ts'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'

function LogsDataTimeChip({ scriptId, title, isColored = false }: { scriptId: string; title: string; isColored?: boolean }) {
  const { innvocationData, isLogSliderOpen } = useCustomSelector((state: $ReduxCoreType) => ({
    innvocationData: state.invocationV2?.[scriptId]?.invocationData,
    isLogSliderOpen: state.appInfo.isLogSliderOpen
  }))

  if (!innvocationData?.requestTimestamp) {
    return null
  }

  return (
    <Box variant='light' className='no-wrap flex-start-center gap-1 mb-1'>
      <Typography>{title || 'Showing data from '}</Typography>
      <StatusIconAndTime innvocationData={innvocationData} isColored={isColored} isLogSliderOpen={isLogSliderOpen} />
    </Box>
  )
}

export function StatusIconAndTime({
  innvocationData,
  isColored,
  isLogSliderOpen
}: {
  innvocationData: any
  isColored: boolean
  isLogSliderOpen: boolean
}) {
  const dispatch = useDispatch()
  const dateTime = new Date(innvocationData?.requestTimestamp)
  const formattedDate = dateTime.toDateString().replace(/\d{4}$/, '') // Format: "Fri Dec 22"
  const formattedTime = dateTime.toTimeString()?.split(' ')[0]

  const handleClick = () => {
    if (!isLogSliderOpen) {
      dispatch(toggleLogsSlider(true))
    }
  }

  return (
    <Box onClick={handleClick} className='flex-start-center gap-1 cursor-pointer'>
      {innvocationData?.status === 'success' ? (
        <FiberManualRecordIcon className='logHeadIcons' color='success' />
      ) : innvocationData?.status === 'PAUSED' ? (
        <PauseCircleFilledOutlinedIcon className='logHeadIcons' color='warning' />
      ) : (
        <FiberManualRecordIcon className='logHeadIcons' color='error' />
      )}
      {isColored ? (
        <Typography
          color={innvocationData?.status === 'success' ? 'success' : innvocationData?.status === 'PAUSED' ? 'warning' : 'error'}
          behaviour='colored'
        >
          {formattedTime}, {formattedDate}
        </Typography>
      ) : (
        <Typography>{`${formattedTime}, ${formattedDate}`}</Typography>
      )}
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(LogsDataTimeChip), [ParamsEnums.scriptId]))
