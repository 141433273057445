import React, { useState, useEffect, useRef } from 'react'
import { Psychology } from '@mui/icons-material'
import '../chipSuggestion.css'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { ParamsEnums, Tabnames } from '../../enums'
import './customAutoSuggest.scss'
import { useCustomSelector } from '../deepCheckSelector'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import MentionsInput from '../react-mentons/src/MentionsInput'
import Mention from '../react-mentons/src/Mention'
import { filterArrayByText, makeDislay, prettifyJson } from '../utilities'
import AddVariableButton from './AddVariableButton.tsx'

/**
 * Function to render a custom auto suggest component with advanced features.
 *
 * @param {object} props - The props object containing various configuration options.
 * @param {string} props.id - The unique identifier for the component.
 * @param {object} props.editableDivRef - Reference to the editable div element.
 * @param {function} props.getHtmlAndValue - Function to get HTML and value from the component.
 * @param {string} props.defaultValue - The default value for the component.
 * @param {boolean} props.hideEditableDiv - Flag to hide the editable div.
 * @param {boolean} props.disabled - Flag to disable the component.
 * @param {string} props.sliderPosition - The position of the slider.
 * @param {function} props.onChangeFunction - Function to handle change events.
 * @param {boolean} props.haveError - Flag indicating if there is an error.
 * @param {string} props.sectionIdOrScriptId - The script identifier.
 * @param {boolean} props.transparent - Flag to make the component transparent.
 * @param {boolean} props.singleLine - Flag to display the component in a single line.
 * @param {string} props.slugName - The slug name for the component.
 * @param {string} props.tabName - The name of the tab.
 * @param {string} props.stepId - The step identifier.
 * @param {string} props.placeholder - The placeholder text for the component.
 * @param {boolean} props.noborder - Flag to hide the border of the component.
 * @param {string} props.value - The value of the component.
 * @param {string} props.plainText - The plain text content of the component.
 * @param {string} props.height - The height of the component.
 * @param {boolean} props.copyText - Flag to enable text copying.
 * @param {boolean} props.hideAddVariablesButton - Flag to hide the add variables button.
 * @param {boolean} props.darkbg - Flag to set a dark background for the component.
 * @param {function} props.onBlurFunction - Function to handle blur events.
 */

function CustomAutoSuggestV2({
  id,
  editableDivRef,
  getHtmlAndValue = () => {},
  onKeyDown = () => {},
  defaultValue,
  hideEditableDiv,
  disabled,
  onChangeFunction = () => {},
  haveError,
  sectionIdOrScriptId,
  transparent = false,
  singleLine = false,
  slugName,
  tabName,
  placeholder = '',
  noborder = false,
  value = '',
  plainText = '',
  height,
  hideSuggestionsOnDryRun = true,
  hideSuggestionsBoolean = false,
  copyText = false,
  hideAddVariablesButton = false,
  darkbg = false,
  onBlurFunction = () => {},
  isShowLoader = false,
  onlyGiveAddButton = false,
  autoFocus = false,
  inputRef = useRef(),
  appendSpaces = false,
  hidePlusButton = false,
  isAiCalling = false
}) {
  const [localData, setLocalData] = useState({ value: value || '', plainText: plainText || '' })
  const cursorPositionRef = useRef({ selectionStart: null, selectionEnd: null })
  const { allSuggestion, stepOrder } = useCustomSelector((state) => ({
    allSuggestion: state.invocationV2?.[sectionIdOrScriptId]?.groupedSuggestions,
    stepOrder: state.flowJsonV2[sectionIdOrScriptId]?.stepOrder || []
  }))

  const isPublishedTab = tabName === Tabnames.PUBLISH
  const triggerRef = useRef('c')

  const mentionRef = useRef()
  const suggestionListRef = useRef([])

  useEffect(() => {
    let valueToset = ''
    let textToset = ''

    if (value !== null && value !== undefined) {
      if (typeof value !== 'string') valueToset = JSON.stringify(value, null, 2)
      else valueToset = prettifyJson(value)
    }

    if (plainText !== null && plainText !== undefined) {
      if (typeof plainText !== 'string') textToset = JSON.stringify(plainText, null, 2)
      else textToset = prettifyJson(plainText)
    }

    setLocalData({
      value: valueToset || textToset || '',
      plainText: textToset || ''
    })
  }, [value, plainText])

  const handleAddSuggestion = (suggestionDetailObj) => {
    if (hideEditableDiv || disabled || isPublishedTab) return
    if (id === 'function-slider') {
      const editor = editableDivRef.current.editor
      const cursorPosition = editor.getCursorPosition() // Get the current cursor position
      editor.session.insert(cursorPosition, suggestionDetailObj.content)
      return
    }
    if (cursorPositionRef.current && mentionRef.current && suggestionDetailObj?.content) {
      const sbstring = suggestionDetailObj?.content?.substring(7) || ''
      mentionRef.current.addMention(
        { id: sbstring, display: sbstring },
        {
          childIndex: 0,
          querySequenceStart: cursorPositionRef.current.selectionStart,
          querySequenceEnd: cursorPositionRef.current.selectionEnd,
          plainTextValue: localData.plainText
        }
      )
    }
  }

  const hideSuggestions = (hideSuggestionsOnDryRun && ['trigger', 'DRY_RUN']?.includes(slugName)) || hideSuggestionsBoolean
  useEffect(() => {
    cursorPositionRef.current = { selectionStart: null, selectionEnd: null }
    suggestionListRef.current = []
    const arr = window.location.pathname.startsWith('/developer/') ? ['authData', 'inputData'] : ['webhookData', ...stepOrder]
    const curIndex = arr?.indexOf(slugName)
    arr?.forEach((key) => {
      if (curIndex <= arr?.indexOf(key) && curIndex !== -1) return
      const group = allSuggestion?.[key] || []
      if (group.length === 0) return
      suggestionListRef.current = suggestionListRef.current.concat(
        group?.map((el) => {
          return { ...el, id: el.id.substring(7) }
        })
      )
    })
  }, [allSuggestion, slugName, stepOrder])
  if (onlyGiveAddButton) {
    if (!isPublishedTab && !hideSuggestions) return <AddVariableButton useIt={handleAddSuggestion} />
    return null
  }

  return (
    <Box className={`w-full ${isAiCalling ? 'ai-div' : ''}`}>
      {!hideEditableDiv && id !== 'function-slider' && (
        <Box
          className={` ${isPublishedTab ? '' : 'input-parent'} ${noborder ? '' : 'input-parent-withborder'} flex justify-start items-end`}
          sx={{
            border: darkbg || noborder ? 'none' : haveError ? '1px solid #8b0000' : '1px solid silver',
            backgroundColor: transparent || disabled || isPublishedTab ? 'transparent' : '#fff'
          }}
        >
          <MentionsInput
            autoFocus={autoFocus}
            placeholder={placeholder}
            ref={mentionRef}
            singleLine={singleLine}
            copyText={copyText}
            allowSpaceInQuery
            allowSuggestionsAboveCursor
            style={{
              control: {
                backgroundColor: transparent || disabled || isPublishedTab ? 'transparent' : 'white',
                fontSize: 14,
                opacity: isPublishedTab || (disabled && !transparent) ? '0.5' : '1',
                fontWeight: 'normal',
                padding: transparent ? '0px' : '8px'
              },
              '& .multiLine': {
                width: '100%',
                minHeight: height || '80px',
                boxSizing: 'border-box',
                control: {
                  fontFamily: 'monospace',
                  minHeight: height || '80px',
                  boxSizing: 'border-box',

                  padding: transparent ? '0px' : '8px'
                },

                highlighter: { border: '1px solid transparent' },
                input: {
                  border: 'none',
                  outline: 'none',
                  color: darkbg ? 'white' : 'black',

                  padding: transparent ? '0px' : '8px'
                }
              },
              '& .singleLine': {
                width: '100%',
                height: '40px',
                border: haveError ? '1px solid #8b0000' : darkbg || noborder ? 'none' : '',
                display: 'inline-block',
                highlighter: {},
                input: {
                  height: '40px',
                  outline: haveError ? '1px solid #8b0000' : darkbg || noborder ? 'none' : '',
                  color: darkbg ? 'white' : 'black'
                },
                control: {
                  height: '40px',
                  padding: '10px',
                  paddingLeft: '4px'
                }
              },
              suggestions: {
                zIndex: 3001,
                list: {
                  zIndex: '30001',

                  backgroundColor: 'white',
                  border: '1px solid rgba(0,0,0,0.15)',
                  fontSize: 14
                },
                item: {
                  padding: '5px 15px',
                  borderBottom: '1px solid rgba(0,0,0,0.15)',
                  '& .focused': {
                    backgroundColor: '#f7f7f7'
                  }
                }
              }
            }}
            value={localData.value}
            onKeyUp={(e) => {
              cursorPositionRef.current = {
                selectionStart: e.target.selectionStart,
                selectionEnd: e.target.selectionEnd
              }
            }}
            inputRef={inputRef}
            textareaClass={transparent ? '' : 'p-2'}
            onClick={(e) => {
              cursorPositionRef.current = {
                selectionStart: e.target.selectionStart,
                selectionEnd: e.target.selectionEnd
              }
            }}
            onKeyDown={onKeyDown}
            triggerRef={triggerRef}
            defaultValue={defaultValue}
            onChange={(e, newval) => {
              if (disabled || isPublishedTab) return
              const text = newval.replace(/\${(.*?)}/g, '$1')
              setLocalData({ value: newval, plainText: text })
              onChangeFunction(text, newval)
            }}
            disabled={disabled}
            onBlur={(event, clickedSuggestion) => {
              if (disabled || isPublishedTab) return
              const text = localData.plainText
              getHtmlAndValue(text?.trim(), localData?.value?.trim())
              onBlurFunction(event, clickedSuggestion)
            }}
          >
            <Mention
              trigger={triggerRef.current}
              appendSpaceOnAdd={appendSpaces}
              // eslint-disable-next-line
              markup='${context__id__}'
              displayTransform={(id) => {
                id = `context${id}`
                return makeDislay(id)
              }}
              data={(text) => (!hideSuggestions ? filterArrayByText(suggestionListRef.current, text) : [])}
              style={{ backgroundColor: '#cee4e5' }}
              renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => {
                return (
                  <Box className={`user ${focused ? 'focused' : ''} render-suggestion flex justify-between items-start gap-2`}>
                    <Typography variant='base'>{highlightedDisplay}</Typography>
                    <Tooltip
                      placement='bottom'
                      title={typeof suggestion.value !== 'object' ? `${suggestion.value}` : JSON.stringify(suggestion.value, null, 2)}
                    >
                      <Typography
                        variant='caption'
                        className='text-ellipsis overflow-hidden'
                        fontWeight={typeof suggestion.value === 'object' ? '600' : ''}
                      >
                        {suggestion.value && typeof suggestion.value === 'object' ? null : `${suggestion.value}`}
                      </Typography>
                    </Tooltip>
                  </Box>
                )
              }}
            />
          </MentionsInput>

          {!hideSuggestions && !hidePlusButton && !isShowLoader && !disabled && !hideAddVariablesButton && !isPublishedTab && (
            <AddVariableButton useIt={handleAddSuggestion} isHidden />
          )}
          {isShowLoader && (
            <IconButton className='bg-primary m-2'>
              <Psychology color='white' fontSize='small' />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(CustomAutoSuggestV2), [
    ParamsEnums.stepId,
    ParamsEnums.sectionIdOrScriptId,
    ParamsEnums.slugName,
    ParamsEnums.tabName
  ])
)
