import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import CreateNewProjectButton from '../../AppsExplore/CreateNewProjectButton.tsx'

interface FolderListProps {
  activePauseProject: object
  renderProjectItem(value: any, index: number): React.ReactNode
}
function FolderList({ activePauseProject, renderProjectItem }: FolderListProps) {
  return (
    <Box className='flex flex-col w-full'>
      <Box className='flex items-center justify-between'>
        <Typography className='projectSliderHeading px-1 pb-1 font-bold'>Folders</Typography>
        <CreateNewProjectButton />
      </Box>
      {/* <FolderListAccordion> */}
      {/* <StyledAccordionDetails className='w-full'> */}
      {activePauseProject.map((value, index) => {
        return <>{renderProjectItem(value, index)}</>
      })}
      {/* </StyledAccordionDetails> */}
      {/* </FolderListAccordion> */}
    </Box>
  )
}

export default FolderList
