import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import { Notes } from '../../utils/utilities'
import CustomMarkdown from '../Markdown/CustomMarkdown'

export default function HelpButton({ type }) {
  const handleNotes = () => {
    document.getElementById('notes-box')?.classList.toggle('d-none')
  }

  return (
    <Box className='w-full flex flex-col'>
      <Button className='notes-bg cursor-pointer !ml-auto' onClick={handleNotes}>
        &#128161; Help
      </Button>
      <Box id='notes-box' className='d-none'>
        <Box className='notes-bg p-3 mt-3'>
          <CustomMarkdown help={Notes[type]} />
        </Box>
      </Box>
    </Box>
  )
}
