/* eslint-disable */
import { Children, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { inline } from 'substyle'
import { defaultStyle, getSuggestionHtmlId } from './utils'

import Suggestion from './Suggestion'
import LoadingIndicator from './LoadingIndicator'

function SuggestionsOverlay({
  id,
  suggestions = {},
  a11ySuggestionsListLabel,
  focusIndex,
  position,
  left,
  right,
  top,
  scrollFocusedIntoView,
  isLoading,
  isOpened,
  onSelect = () => null,
  ignoreAccents,
  containerRef,
  children,
  style,
  customSuggestionsContainer,
  onMouseDown,
  onMouseEnter
}) {
  const [ulElement, setUlElement] = useState(undefined)

  useEffect(() => {
    if (!ulElement || ulElement.offsetHeight >= ulElement.scrollHeight || !scrollFocusedIntoView) {
      return
    }
    const scrollTop = ulElement.scrollTop

    let { top, bottom } = ulElement.children[focusIndex].getBoundingClientRect()
    const { top: topContainer } = ulElement.getBoundingClientRect()
    top = top - topContainer + scrollTop
    bottom = bottom - topContainer + scrollTop

    if (top < scrollTop) {
      ulElement.scrollTop = top
    } else if (bottom > ulElement.offsetHeight) {
      ulElement.scrollTop = bottom - ulElement.offsetHeight
    }
  }, [focusIndex, scrollFocusedIntoView, ulElement])

  const renderSuggestions = () => {
    let globalIndex = 0
    const groupsRendered = new Set()
    const suggestionsToRender = (
      <ul ref={setUlElement} id={id} role='listbox' aria-label={a11ySuggestionsListLabel} {...style('list')}>
        {Object.values(suggestions).reduce((accResults, { results, queryInfo }) => {
          return [
            ...accResults,
            ...results.map((result) => {
              const lastIndexOfDot = result.display.lastIndexOf('.')
              const group = lastIndexOfDot > 0 ? result.display.slice(0, lastIndexOfDot) : undefined
              const newgroup = groupsRendered.has(group) ? undefined : group
              if (newgroup) {
                groupsRendered.add(newgroup)
              }
              return renderSuggestion(result, queryInfo, globalIndex++, newgroup)
            })
          ]
        }, [])}
      </ul>
    )

    if (customSuggestionsContainer) return customSuggestionsContainer(suggestionsToRender)
    return suggestionsToRender
  }

  const renderSuggestion = (result, queryInfo, index, group) => {
    const isFocused = index === focusIndex
    const { childIndex, query } = queryInfo
    const { renderSuggestion } = Children.toArray(children)[childIndex].props

    return (
      <Box key={`${childIndex}-${getID(result.display)}`}>
        {group ? (
          <Box className={`p-2 w-100 ${index ? 'border-top-1' : ''}`}>
            <Typography fontWeight='600' className='ellipses-revert no-wrap'>
              {group?.replaceAll('.', ' / ')}
            </Typography>
          </Box>
        ) : null}
        <Suggestion
          style={style('item')}
          id={getSuggestionHtmlId(id, index)}
          query={query}
          index={index}
          ignoreAccents={ignoreAccents}
          renderSuggestion={renderSuggestion}
          suggestion={result}
          focused={isFocused}
          onClick={() => select(result, queryInfo)}
          onMouseEnter={() => handleMouseEnter(index)}
        />
      </Box>
    )
  }

  const renderLoadingIndicator = () => {
    if (!isLoading) {
      return
    }
    return <LoadingIndicator style={style('loadingIndicator')} />
  }

  const handleMouseEnter = (index) => {
    if (onMouseEnter) {
      onMouseEnter(index)
    }
  }

  const select = (suggestion, queryInfo) => {
    onSelect(suggestion, queryInfo)
  }

  const getID = (suggestion) => {
    if (typeof suggestion === 'string') {
      return suggestion
    }
    return suggestion.id
  }

  if (!isOpened) {
    return null
  }

  return (
    <div {...inline({ position: position || 'absolute', left, right, top }, style)} onMouseDown={onMouseDown} ref={containerRef}>
      {renderSuggestions()}
      {renderLoadingIndicator()}
    </div>
  )
}

SuggestionsOverlay.propTypes = {
  id: PropTypes.string.isRequired,
  suggestions: PropTypes.object.isRequired,
  a11ySuggestionsListLabel: PropTypes.string,
  focusIndex: PropTypes.number,
  position: PropTypes.string,
  left: PropTypes.number,
  right: PropTypes.number,
  top: PropTypes.number,
  scrollFocusedIntoView: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpened: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  ignoreAccents: PropTypes.bool,
  customSuggestionsContainer: PropTypes.func,
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: typeof Element === 'undefined' ? PropTypes.any : PropTypes.instanceOf(Element)
    })
  ])
}

const styled = defaultStyle({
  zIndex: 1,
  backgroundColor: 'white',
  maxWidth: '400px',
  marginTop: 22,
  list: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  }
})

export default styled(SuggestionsOverlay)
