import { Box, List, ListItem, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getAllTemplatesOfOrg } from './api'
import addUrlDataHoc from './hoc/addUrlDataHoc.tsx'
import AddTemplateButton from './AddTemplateButton.tsx'
import { ParamsEnums } from './enums'

function NewTemplateslider({ orgId }) {
  const [templates, setTemplates] = useState([])

  async function getTemplates() {
    const results = await getAllTemplatesOfOrg(orgId)
    setTemplates(results)
  }
  useEffect(() => {
    getTemplates()
  }, [])

  return (
    <Box className='flex flex-col w-full  overflow-scroll-y h-screen border-r-sharp ' sx={{ maxWidth: '300px' }}>
      <Typography variant='h5' className='p-2'>
        Templates
      </Typography>
      <List className='overflow-scroll-y'>
        {templates?.map((template) => {
          return (
            <ListItem key={template?.id} divider>
              {template.title || '[NO NAME]'}
            </ListItem>
          )
        })}
      </List>
      <AddTemplateButton setTemplates={setTemplates} />
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(NewTemplateslider), [ParamsEnums.orgId]))
