// import { SearchOutlined } from '@mui/icons-material'
// import React, { useState } from 'react'
// import { ClickAwayListener, Box, IconButton,  } from '@mui/material'
import React, { useEffect, useRef } from 'react'
// import ReactDOM from 'react-dom';
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
// import Search from './Search'
import ReactChatbot from '../../utils/react-chatbot/index'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { ParamsEnums } from '../../enums'

/**
 * DrawerWithSearch component
 *
 * This component renders a search drawer that can be toggled open or closed.
 * It uses Material-UI's Drawer and ClickAwayListener components to handle the drawer's behavior.
 * The drawer contains a Search component and is controlled by a state variable.
 * The component also conditionally renders a SearchBar or SearchIcon based on the isProjectSliderOpen state.
 *
 * @param {Object} props - The component props
 * @param {string} props.scriptId - The script ID used to determine the width of the container
 * @returns {JSX.Element} The rendered component
 */

function DrawerWithSearch({ orgId, chatState, setChatState }) {
  const { chatbottoken } = useCustomSelector((state) => ({
    chatbottoken: state.appInfo.chatbottoken
  }))

  const chatbotref = useRef()
  useEffect(() => {
    if (!chatState.searchedQuery) chatbotref.current?.clearText()
  }, [chatState.searchedQuery])
  const functionSliderProps = {
    threadId: orgId,
    ref: chatbotref,
    bridgeName: 'orgOrProject',
    onChange: (e) => {
      setChatState({ searchedQuery: e, chatwithAI: chatState.chatwithAI })
    },
    variables: {},
    additionalFunctionOnSend: () => {
      setChatState({ searchedQuery: '', chatwithAI: true })
    },
    placeholder: 'Search or Ask AI',
    componentJson: {},
    embedToken: chatbottoken,
    ai_div_class: 'project-slider-ai-div',
    isAutoSuggestTextField: false
  }
  return <ReactChatbot {...functionSliderProps} />
}
export default React.memo(addUrlDataHoc(React.memo(DrawerWithSearch), [ParamsEnums.orgId]))
