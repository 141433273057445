/* eslint-disable */
import { Box, Chip, LinearProgress, Typography } from '@mui/material'
import { createContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import WebSocketClient from 'rtlayer-client'
import { getPreviousMessage, sendDataToAction } from './apis.ts'
import ChatbotTextField from './ChatbotTextField.tsx'
import './ChatbotComponent.scss'
import MessageList from './MessageList.tsx'
import CloseSlidersButtonV2 from '../../components/common/closeSlidersButtonV2.tsx'

const client = new WebSocketClient('lyvSfW7uPPolwax0BHMC', 'DprvynUwAdFwkE91V5Jj')

interface InterfaceChatbotProps {
  inpreview: boolean
  interfaceId: string
}

interface MessageType {
  content: string
  role: string
  responseId?: string
  wait?: boolean
  timeOut?: boolean
  createdAt?: string
  function?: () => void
  id?: string
}
export const MessageContext = createContext<{
  messages: MessageType[] | []
  addMessage?: (message: string) => void
  setMessages?: (message: MessageType[]) => void
}>({
  messages: []
})

function ChatbotComponent(props: InterfaceChatbotProps) {
  const {
    inpreview = true,
    interfaceId,
    bridgeName,
    threadId,
    variables,
    componentJson,
    onNewMessageRecieved = () => {},
    placeholder,
    initialComponent,
    showInitialComponent = false,
    disabled = false,
    textfieldLabel = '',
    versionId,
    additionalFunctionOnSend = () => {},
    onChange = () => {},
    chatbotRef,
    ai_div_class = 'ai-div',
    isAutoSuggestTextField = true
  } = props
  const [chatsLoading, setChatsLoading] = useState(false)
  const timeoutIdRef = useRef<any>(null)
  const textfieldRef = useRef()
  const userId = localStorage.getItem('chatbotUserId')
  const [loading, setLoading] = useState(false)
  const messageRef = useRef<any>()
  const [options, setOptions] = useState<any>([])
  const [bridgeVersionId, setBridgeVersionId] = useState(versionId)
  const [messages, setMessages] = useState<MessageType[]>(
    useMemo(
      () =>
        !inpreview
          ? [
              { content: 'hello how are you ', role: 'user' },
              {
                responseId: 'Response24131',
                content: '{\n  "response": "Our AI services are available for you anytime, Feel free to ask anything"\n}',
                role: 'assistant'
              }
            ]
          : [],
      [inpreview]
    )
  )

  const addMessage = (message: string) => {
    onSend(message)
  }

  const startTimeoutTimer = () => {
    timeoutIdRef.current = setTimeout(() => {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages.slice(0, -1), { role: 'assistant', wait: false, timeOut: true }]
        setLoading(false)
        return updatedMessages
      })
    }, 240000)
  }
  useImperativeHandle(chatbotRef, () => ({
    clearText: () => textfieldRef?.current?.emptyTextfield()
  }))
  const handleParentCommunication = (event: CustomEvent<{ data: any; action: string }>) => {
    switch (event?.detail?.action) {
      case 'SEND_MESSAGE':
        onSend(event.detail?.data?.message)
        break
      case 'CHANGE_BRIDGE_VERSION':
        if (event.detail?.data?.versionId) setBridgeVersionId(event.detail?.data?.versionId)
        break
      default:
        break
    }
  }

  useEffect(() => {
    setBridgeVersionId(versionId)
  }, [versionId])

  useEffect(() => {
    window.addEventListener(`${bridgeName}_${threadId}_communication`, handleParentCommunication)
    return () => window.removeEventListener(`${threadId}_communication`, handleParentCommunication)
  }, [threadId, bridgeName])

  const getallPreviousHistory = async () => {
    if (threadId && interfaceId) {
      setChatsLoading(true)
      try {
        const previousChats = await getPreviousMessage(threadId, bridgeName)
        if (Array.isArray(previousChats)) {
          setMessages(previousChats.length === 0 ? [] : [...previousChats])
        } else {
          setMessages([])
          console.error('previousChats is not an array')
        }
      } catch (error) {
        console.error('Error fetching previous chats:', error)
        setMessages([])
      } finally {
        setChatsLoading(false)
      }
    }
  }

  useEffect(() => {
    const subscribe = () => {
      client.subscribe(interfaceId + (threadId || userId) + userId)
    }
    client.on('open', subscribe)
    subscribe()
    getallPreviousHistory()

    const handleMessage = (message: string) => {
      // Parse the incoming message string into an object
      const parsedMessage = JSON.parse(message || '{}')

      // Check if the status is "connected"
      if (parsedMessage?.status === 'connected') {
        return
      }

      // Check if the function call is present
      if (parsedMessage?.response?.function_call && !parsedMessage?.response?.message) {
        setMessages((prevMessages) => [...prevMessages.slice(0, -1), { role: 'assistant', wait: true, content: 'Function Calling' }])
      } else if (parsedMessage?.response?.function_call && parsedMessage?.response?.message) {
        // Check if the function call is false and no response is provided
        setMessages((prevMessages) => [...prevMessages.slice(0, -1), { role: 'assistant', wait: true, content: 'Talking with AI' }])
      } else if (!parsedMessage?.response?.data && parsedMessage?.error) {
        // Check if there is an error and no response data
        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          {
            role: 'assistant',
            content: `${parsedMessage?.error || 'Error in AI'}`
          }
        ])
        setLoading(false)
        clearTimeout(timeoutIdRef.current)
      } else if (parsedMessage?.response?.data?.role === 'reset') {
        // all previous message and new object inserted
        setMessages((prevMessages) => [...prevMessages, { role: 'reset', content: 'Resetting the chat' }])
      } else if (parsedMessage?.response?.data) {
        // Handle the new structure with response data
        // const content = parsedMessage.response.data.content;
        onNewMessageRecieved(parsedMessage.response.data)
        setLoading(false)
        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          {
            role: parsedMessage.response?.data?.role || 'assistant',
            ...(parsedMessage.response.data || {})
          }
        ])
        setOptions(parsedMessage.response?.options || [])
        clearTimeout(timeoutIdRef.current)
      } else {
        // Handle any other cases
        console.error('Unexpected message structure:', parsedMessage)
      }
    }

    client.on(interfaceId + (threadId || userId) + userId, handleMessage)

    return () => {
      textfieldRef?.current?.emptyTextfield()
      clearTimeout(timeoutIdRef.current)
      client.unsubscribe(interfaceId + (threadId || userId) + userId)
      client.removeListener('message', handleMessage)
    }
  }, [threadId, interfaceId, userId, bridgeName])

  const sendMessage = async (message: string, variables = {}, thread = '', bridge = '') => {
    const interfaceContextData = variables || {}
    interfaceContextData.userMessage = message
    const response = await sendDataToAction({
      message,
      userId,
      interfaceContextData,
      threadId: thread || threadId,
      slugName: bridge || bridgeName,
      chatBotId: interfaceId,
      version_id: bridgeVersionId
    })
    if (!response?.success) {
      setMessages((prevMessages) => prevMessages.slice(0, -1))
      setLoading(false)
    }
  }

  const onSend = (msg?: string, apiCall: boolean = true) => {
    const textMessage = msg || messageRef?.current?.value
    if (!textMessage) return
    startTimeoutTimer()
    apiCall && sendMessage(textMessage, variables)
    setLoading(true)
    setOptions([])
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: textMessage },
      { role: 'assistant', wait: true, content: 'Talking with AI' }
    ])
    if (messageRef.current) messageRef.current.value = ''
    additionalFunctionOnSend()
  }

  const messagesPresent = Boolean(messages?.length) || !showInitialComponent
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        overflowY: 'scroll',
        position: 'relative'
      }}
    >
      <MessageContext.Provider value={{ messages: messages, addMessage, setMessages }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            position: 'relative'
          }}
        >
          {chatsLoading ? (
            <LinearProgress variant='indeterminate' color='secondary' sx={{ height: 4 }} />
          ) : !messagesPresent ? (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                padding: 2
              }}
            >
              {initialComponent(onSend, textfieldRef?.current?.getCurrentText())}
            </Box>
          ) : (
            <MessageList componentJson={componentJson} />
          )}
          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              width: '100%',
              backgroundColor: 'white !important',
              zIndex: 1
            }}
            className={`${!disabled ? ai_div_class : 'disabled-ai-div'}`}
          >
            {textfieldLabel ? (
              <Box className='w-100 p-2 flex-spaceBetween-center' sx={{ backgroundColor: '#f7f7f7' }}>
                <Typography variant='h6'>{textfieldLabel}</Typography>
                <CloseSlidersButtonV2 type='icon' />
              </Box>
            ) : null}

            <Box className='w-100'>
              <ChatbotTextField
                loading={loading}
                options={options}
                ref={textfieldRef}
                onSend={onSend}
                disabled={disabled}
                hideSuggestions={bridgeName === 'orgOrProject'}
                onChange={onChange}
                messageRef={messageRef}
                placeholder={placeholder}
                isAutoSuggestTextField={isAutoSuggestTextField}
              />
            </Box>
          </Box>
        </Box>
      </MessageContext.Provider>
    </Box>
  )
}

export default ChatbotComponent
