import React, { useEffect, useMemo, useState } from 'react'
import { Box, Input, Typography } from '@mui/material'
import ReactVTree from '../../responseAndConsoleTabs/ReactVTree.tsx'
import { doesValueExists, getLevelsOfNestingObject } from '../../../utils/utilities'
import OldTree from './OldTree.tsx'
import sizeof from '../../../utils/bufferSizeUtility.ts'

function Tree({ data, parentName = 'Response', path = 'context.res', disablecopypath = false, showSearch = true, searchText = '' }) {
  const [searchTerm, setSearchTerm] = useState(searchText)
  const dataToShow = searchTerm ? getSearchedObject(data, path) : objectToTree(data, path)
  const dataLength = useMemo(() => getLevelsOfNestingObject(data), [data])
  useEffect(() => {
    setSearchTerm(searchText)
  }, [searchText])
  function objectToTree(obj, path) {
    const tree = {
      name: parentName,
      id: path,
      children: [],
      pathLocation: path,
      valueToCopy: typeof obj === 'string' ? obj : JSON.stringify(obj),
      valueToShow: '',
      valueOfKeyToShow: parentName,
      value: path
    }
    if (typeof obj !== 'object' || obj === null) {
      tree.valueToShow = obj
      return tree
    }
    if (typeof obj === 'object' && obj !== null) {
      if (Array.isArray(obj)) {
        tree.valueToShow = `[${obj.length}]`
      } else {
        tree.valueToShow = `{${Object.keys(obj).length}}`
      }
    }
    // eslint-disable-next-line
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key]
        const pathLocation = `${path}${Array.isArray(obj) ? `?.[${key}]` : `?.${key}`}`
        const node = { id: pathLocation, name: key, pathLocation, value: pathLocation }
        node.valueToCopy = typeof value === 'string' ? value : JSON.stringify(value)
        node.valueOfKeyToShow = key
        if (typeof value === 'object' && value !== null) {
          node.children = objectToTree(value, pathLocation).children
          if (Array.isArray(value)) {
            node.valueToShow = `[${value.length}]`
          } else {
            node.valueToShow = `{${Object.keys(value).length}}`
          }
        } else {
          node.valueToShow = value
          node.value = pathLocation
        }
        tree.children.push(node)
      }
    }
    return tree
  }

  function getSearchedObject(obj, path) {
    const searchTree = (obj, path, forceInclude = false) => {
      const result = []
      if (typeof obj !== 'object' || obj === null) {
        return result
      }
      Object.keys(obj).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key]
          const pathLocation = `${path}${Array.isArray(obj) ? `?.[${key}]` : `?.${key}`}`
          if (typeof value === 'object' && value !== null) {
            const children = searchTree(
              value,
              pathLocation,
              forceInclude || JSON.stringify(key)?.toLowerCase().includes(searchTerm?.toLowerCase())
            )
            if (children.length > 0) {
              result.push({
                id: pathLocation,
                name: key,
                pathLocation,
                value: pathLocation,
                valueToCopy: typeof value === 'string' ? value : JSON.stringify(value),
                valueOfKeyToShow: key,
                valueToShow: Array.isArray(value) ? `[${value.length}]` : `{${Object.keys(value).length}}`,
                children
              })
            }
          } else if (
            forceInclude ||
            (doesValueExists(value) &&
              (key?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                JSON.stringify(value)?.toLowerCase().includes(searchTerm?.toLowerCase())))
          ) {
            result.push({
              id: pathLocation,
              name: key,
              pathLocation,
              value: pathLocation,
              valueToCopy: typeof value === 'string' ? value : JSON.stringify(value),
              valueOfKeyToShow: key,
              valueToShow:
                typeof value === 'object' ? (Array.isArray(value) ? `[${value?.length}]` : `{${Object.keys(value || {})?.length}}`) : value,
              children:
                typeof value === 'object' && value !== null
                  ? searchTree(value, pathLocation, forceInclude || JSON.stringify(key)?.toLowerCase().includes(searchTerm.toLowerCase()))
                  : []
            })
          }
        }
      })
      return result
    }
    const valueToReturn = {
      name: parentName,
      id: path,
      children: searchTree(obj, path),
      pathLocation: path,
      valueToCopy: typeof obj === 'string' ? obj : JSON.stringify(obj),
      valueToShow: null,
      valueOfKeyToShow: parentName,
      value: path
    }
    if (!(typeof obj === 'object' && obj !== null) && JSON.stringify(obj)?.toLowerCase().includes(searchTerm?.toLowerCase())) {
      valueToReturn.valueToShow = obj
    }
    return valueToReturn
  }
  useEffect(() => {
    if (dataLength < 10) {
      setSearchTerm('')
    }
  }, [dataLength])
  return sizeof(data) < 1000000 ? (
    <Box>
      <OldTree data={data} parentName={parentName} path={path} disablecopypath={disablecopypath} />
    </Box>
  ) : (
    <Box>
      <Box className='flex items-center' sx={{ mb: 2 }}>
        {showSearch && typeof data === 'object' && data != null && dataLength > 10 && (
          <Input
            placeholder='Search...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: '100%',
              padding: '8px 16px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              transition: 'border-color 0.3s',
              '&:focus': {
                borderColor: '#3f51b5',
                outline: 'none'
              }
            }}
            disableUnderline
          />
        )}
      </Box>
      {dataToShow.children.length > 0 || doesValueExists(dataToShow.valueToShow) ? (
        <ReactVTree disablecopypath={disablecopypath} height={dataLength > 20 ? 400 : dataLength * 25} data={dataToShow} />
      ) : (
        <Typography height={dataLength > 20 ? 400 : dataLength * 25}>No Data found</Typography>
      )}
    </Box>
  )
}

export default React.memo(Tree)
