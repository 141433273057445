import React, { useEffect, useRef, useState } from 'react'
import './variableSlider.scss'
import { useDispatch } from 'react-redux'
import { Box, Divider, Typography } from '@mui/material'
import CustomAutoSuggestV2 from '../../../utils/customAutoSuggestV2/customAutoSuggestV2'
import { BlockTypes, ParamsEnums } from '../../../enums'
import { errorToast } from '../../customToast'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import { evalVariableAndCodeFromContext } from '../../../utils/codeUtility.ts'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import { setKeyValueInstance } from '../../../store/stepsV2/stepSliceV3.ts'
import ResponseAndConsoleTabsV2 from '../../responseAndConsoleTabs/responseAndConsoleTabsV2.tsx'
import SaveButtonV3 from '../../saveButton/saveButtonV3.tsx'
import { optimizeCodeByAI } from '../../../api/index'
import CompareSliderButton from '../../stepNameComponent/compareSliderButton'
import { filterObjectByKey } from '../../../store/invocationV2/invocationSelectorV2'
import StepNameComponentV2 from '../../stepNameComponent/stepNameComponentV2.tsx'
import HelpButton from '../../helpButton/helpButton'
import PreviewButton from '../dryRunButtonForFunctionApiPlugin/testButtonForIfVariable.tsx'
import { handleAddStepsV2 } from '../../../store/invocationV2/invocationSliceV2.ts'

export const getCompliedValueOfVariable = async (code, slugName, context) => {
  let response
  try {
    response = evalVariableAndCodeFromContext(`var ${slugName} =  ${code || "''"}; return ${slugName}`, context.context)
    response = JSON.parse(JSON.stringify(response))
  } catch (error) {
    response = { message: 'Error: An error occurred. Please review your code.', success: false }
  }
  return response
}

/**
 * @param {string} scriptId - The ID of the script.
 * @param {string} slugName - The slug name.
 * @param {string} tabName - The name of the tab.
 * @param {string} stepId - The ID of the step.
 */
function VariableSlider({ scriptId, slugName, tabName, stepId, threadId }) {
  // USE-DISPATCH.
  const dispatch = useDispatch()
  // USE-SELECTORS.
  const { context, stepInstance, hasUnsavedCode } = useCustomSelector((state) => ({
    context: filterObjectByKey(state, slugName, scriptId),
    stepInstance: state.stepsDataV3?.[scriptId]?.[stepId]?.[tabName],
    hasUnsavedCode: state?.stepsDataV3?.[scriptId]?.[stepId]?.hasUnsavedCode
  }))

  // USE-REF.
  const variableCustomSuggestRef = useRef()

  // USE-STATES.
  const [aiCall, setAiCall] = useState(false)

  // USE-EFFECTS.
  useEffect(() => {
    if (!variableCustomSuggestRef.current) return

    variableCustomSuggestRef.current.innerHTML = stepInstance?.codeHTML || stepInstance?.code
  }, [slugName])

  const handleValueChange = (text, html) => {
    setCodeToFunctionJson(text, html)
  }
  const setCodeToFunctionJson = (code, codeHTML) => {
    dispatch(setKeyValueInstance({ code, codeHTML }))
  }

  // HANDLER-FUNCTIONS.
  const createVariable = async () => {
    let code = stepInstance?.code
    let html = stepInstance?.codeHTML
    let variableCompiledResponse = await getCompliedValueOfVariable(code, slugName, context)
    if (variableCompiledResponse.success === false) {
      errorToast(variableCompiledResponse?.message)
      setAiCall(true)
      try {
        const payload = {
          code,
          result: variableCompiledResponse,
          context,
          bridgeName: 'variable',
          threadId
        }
        const optimizedresponse = await optimizeCodeByAI('https://flow.sokt.io/func/scri7Y5x7Osh', payload)
        const parsedCode = JSON.parse(optimizedresponse || '{}')
        html = parsedCode?.fullyWrapped
        code = parsedCode?.conditionallyWrapped
        setCodeToFunctionJson(code, html)
        variableCompiledResponse = await getCompliedValueOfVariable(code, slugName, context)
        setCodeToFunctionJson(code, html)
      } catch (error) {
        console.error(error)
      } finally {
        setAiCall(false)
      }
    }
    const data = {
      payload: {
        key: slugName,
        value: variableCompiledResponse?.message
      },
      type: BlockTypes.VARIABLE
    }
    dispatch(handleAddStepsV2(data))
    return { response: variableCompiledResponse, code, html }
  }

  return (
    <Box className='flex-col h-100 w-100'>
      <StepNameComponentV2 />
      <Box className='flex-col gap-2 py-3 w-100'>
        <Box className='w-100 column gap-2 px-3'>
          <CustomAutoSuggestV2
            id='variable-slider'
            withQuestionMark
            isAiCalling={aiCall}
            placeholder='Enter a value to be assigned to this variable.'
            editableDivRef={variableCustomSuggestRef}
            getHtmlAndValue={handleValueChange}
            value={stepInstance?.codeHTML}
            plainText={stepInstance?.code}
            copyText
            onChangeFunction={(text, html) => {
              if (!hasUnsavedCode) handleValueChange(text, html)
            }}
          />
          <Box className='flex-spaceBetween-center'>
            <PreviewButton testFunction={createVariable} isRunning={aiCall} />
            <CompareSliderButton />
          </Box>
          {aiCall && <Typography className='mt-1'> Correcting your input, please wait...</Typography>}
        </Box>
        <ResponseAndConsoleTabsV2 inVariableSlider />
      </Box>
      <Divider />
      <HelpButton type='variable' />
      <SaveButtonV3 key={stepId} />
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(VariableSlider), [
    ParamsEnums.scriptId,
    ParamsEnums.slugName,
    ParamsEnums.tabName,
    ParamsEnums.stepId,
    ParamsEnums.threadId
  ])
)
