import { createAsyncThunk } from '@reduxjs/toolkit'
import { all, call, put } from 'redux-saga/effects'
import { dhCreateApi, dhGetApi, dhUpdateApi } from '../../../api/developerHubApis/developerHubApis.ts'
import { DhGetActionsTriggersSuggestionApi, DhGetBrandDetailsFromAiAndTOBApi } from '../../../api/developerHubApis/dhAiApis.ts'
import { errorToast } from '../../../components/customToast'
import config from '../../../config'
import { DH_FLOW_FILTERS, SECTIONS, STATUS_TYPE, tableName } from '../../../pages/developerHub/constants/developerHubConstants.ts'
import { PluginFields } from '../../../types/DHPlugin.ts'
import actionType from '../../../types/utility.ts'
import { getInfoParamtersFromUrl } from '../../../utils/utilities'
import { bulkActionCreateSuccess, getAllActionStart } from '../DHActions/dhActionSlice.ts'
import { getAuthDetailStart } from '../DHAuthentication/dhAuthenticationSlice.ts'
import {
  createNewPluginError,
  createNewPluginSuccess,
  deletePluginError,
  deletePluginSuccess,
  getAllPluginError,
  getAllPluginSuccess,
  getPluginDetailError,
  getPluginDetailSuccess,
  updatePluginDetailsError,
  updatePluginDetailsInRedux,
  updatePluginDetailsSuccess
} from './dhPluginSlice.ts'

export function* getAllPluginSaga(action: actionType<{ orgId: string }>) {
  const payload = {
    ...action.payload,
    filter: DH_FLOW_FILTERS.GET_ALL_PLUGIN_START,
    identifier: action.payload.orgId
  }
  const { isSuccess, data } = yield call(dhGetApi, payload, tableName.plugins)
  if (isSuccess) {
    yield put(getAllPluginSuccess(data))
  } else {
    yield put(getAllPluginError({}))
    errorToast('error fetching all plugins')
  }
}
export function* updatetPluginDetailsSaga(action: actionType<PluginFields>) {
  let currentPluginRowId = getInfoParamtersFromUrl().pluginId
  if (action.payload.rowid) {
    currentPluginRowId = action.payload.rowid
  }
  const payload = {
    dataToUpdate: action?.payload,
    filter: DH_FLOW_FILTERS.UPDATE_PLUGIN_DETAILS_START,
    identifier: currentPluginRowId
  }
  const { isSuccess, data, errorMessage } = yield call(dhUpdateApi, payload, tableName.plugins)
  if (isSuccess) {
    yield put(updatePluginDetailsSuccess(data))
    // successToast('Your data is saved successfully')
  } else {
    errorToast(
      typeof errorMessage === 'string' && errorMessage?.includes('Error updating row duplicate key value violates unique constraint')
        ? 'Plugin with this name already exist, please change your name to publish or contact support@viasocket.com'
        : 'error in updating plugin details'
    )
    yield put(updatePluginDetailsError(action.payload))
  }
}
export function* createNewPlugInSaga(action: actionType<{ payload: { name: string; orgId: string }; navigate: any }>) {
  const { payload, navigate } = action.payload
  const { orgId } = action.urlData
  const { developerBaseUrl } = config
  const { isSuccess, data } = yield call(dhCreateApi, payload, tableName.plugins)
  if (isSuccess) {
    // successToast('Your data is saved successfully')
    yield put(createNewPluginSuccess(data))
    navigate(`${developerBaseUrl}/${orgId}/plugin/${data.rowid}/${SECTIONS.auth}`)
  } else {
    errorToast('error occured while creating new plugin !!. Please Try Again')
    yield put(createNewPluginError(payload))
    navigate(`${developerBaseUrl}/${orgId}`)
  }
}

export function* getPluginDetailsSaga(action: actionType<{ filter: string; rowid: string }>) {
  const payload = {
    ...action.payload,
    filter: DH_FLOW_FILTERS.GET_PLUGIN_DETAIL_START,
    identifier: action.payload.rowid
  }
  const { isSuccess, data } = yield call(dhGetApi, payload, tableName.plugins)
  if (isSuccess) {
    yield put(getPluginDetailSuccess(data))
  } else {
    yield put(getPluginDetailError({ rowid: action.payload.rowid }))
    errorToast('error fetch plugin details')
  }
}

export function* getAuthAndActionsSaga(action: actionType<PluginFields[]>) {
  yield put(getAuthDetailStart({ pluginId: action?.payload[0]?.rowid }))
  yield put(
    getAllActionStart({
      pluginId: action?.payload[0]?.rowid
    })
  )
}

export const deletePluginThunk = createAsyncThunk('dhPlugin/deletePluginThunk', async (payload, { dispatch }) => {
  const { pluginId, appslugname } = payload
  const dataToSend = {
    dataToUpdate: { status: STATUS_TYPE.deleted, appslugname },
    filter: DH_FLOW_FILTERS.UPDATE_PLUGIN_DETAILS_START,
    identifier: pluginId
  }

  try {
    const { isSuccess } = await dhUpdateApi(dataToSend, 'plugins')
    if (isSuccess) {
      dispatch(deletePluginSuccess({ pluginId }))
      // successToast('Deleted Plugin Successfully')
    } else {
      dispatch(deletePluginError({ pluginId }))
      // errorToast('Error in Deleting plugin');
    }
  } catch (error) {
    dispatch(deletePluginError({ pluginId }))
    // errorToast('Error in Deleting plugin');
  }
})

export function* generatePluginDetailsAndActionsFromAI(action: actionType<{ name: string; rowid: string; domain: string }>) {
  const { rowid, name, domain } = action.payload
  yield all([call(getBrandDetailsFromAiAndTOB, rowid, domain, name), call(getActionsTriggersSuggestionsFromAI, rowid, name)])
}

export function* getActionsTriggersSuggestionsFromAI(rowid: string, name: string) {
  const { isSuccess, data } = yield call(DhGetActionsTriggersSuggestionApi, name, rowid)
  if (isSuccess) {
    yield put(bulkActionCreateSuccess({ data: data, pluginId: rowid }))
  } else {
    yield put(createNewPluginError({ rowid: rowid }))
    errorToast('Issue while creating Actions and Triggers from AI')
  }
}

export function* getBrandDetailsFromAiAndTOB(rowid: string, domain: string, name: string) {
  const { isSuccess, data } = yield call(DhGetBrandDetailsFromAiAndTOBApi, rowid, domain, name)
  if (isSuccess) {
    yield put(updatePluginDetailsInRedux({ ...data, rowid: rowid }))
  } else {
    yield put(updatePluginDetailsInRedux({ rowid: rowid, name, domain }))
    errorToast('Issue while creating Plugin details from AI')
  }
}
