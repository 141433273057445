import React, { useState } from 'react'
import { Avatar, Typography, Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import config from '../../config'
import { openModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import { clearScriptsList } from '../../store/scripts/scriptsSlice'
import { MiscTypes, ModalTypeEnums, ParamsEnums } from '../../enums'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { errorToast } from '../customToast'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'
import CustomModal from '../customModal/customModal'
import AuthorizeOrgModal from '../oauthAuthorization/OauthAuthorizationModal'
import { OrganizationInfoType } from '../../types/Organization.ts'

function AllOrgs({ orgListVariable, orgId, isModal, serviceId, orgSearch = '' }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { appInfo } = useCustomSelector((state: $ReduxCoreType) => ({
    appInfo: state.appInfo
  }))

  const [selectedOrg, setSelectedOrg] = useState({})
  const filteredRows = orgListVariable.filter((row: any) =>
    Object.values(row).some((value) => {
      const stringValue = value ? value.toString().toLowerCase() : ''

      if (typeof value === 'object' && value?.domainUrl) {
        const normalizedDomainUrl = value.domainUrl
          .toLowerCase()
          .replace(/^https?:\/\//, '') // Remove 'http://' or 'https://'
          .replace(/^www\./, '') // Remove 'www.'

        if (normalizedDomainUrl.includes(orgSearch.toLowerCase())) {
          return true
        }
      }

      if (stringValue.includes(orgSearch.toLowerCase())) {
        return true
      }

      return false
    })
  )

  const columns = [
    {
      field: 'name',
      headerName: 'Workspace',
      flex: 1.5,
      renderCell: (params) => (
        <Box className='flex-verticalcenter gap-2 h-100'>
          {params.row.meta?.iconUrl ? (
            <IconWrapper size='24px' iconUrl={params.row.meta.iconUrl} />
          ) : (
            <Avatar
              variant='square'
              sx={{
                width: 26,
                height: 26,
                backgroundColor: 'grey.200',
                color: 'grey'
              }}
            >
              {params.row.name?.charAt(0).toUpperCase()}
            </Avatar>
          )}
          <Typography variant='body2'>{params.row.name}</Typography>
        </Box>
      ),
      disableColumnMenu: true
    },
    {
      field: 'domainUrl',
      headerName: 'Domain',
      flex: 1,
      valueGetter: (_, row) => row?.meta?.domainUrl || '',
      disableColumnMenu: true
    },
    {
      field: 'timezone',
      headerName: 'Time Zone',
      flex: 1,
      disableColumnMenu: true
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      flex: 1,
      type: 'data',
      valueGetter: (params) => params?.split('T')[0],
      disableColumnMenu: true
    },
    {
      field: 'email',
      headerName: 'Owner',
      flex: 1.5,
      disableColumnMenu: true
    }
  ]
  async function selectWorkspace(newOrg: OrganizationInfoType) {
    const newOrgId = newOrg.id
    setSelectedOrg(newOrg)
    if (`${newOrgId}` === orgId) {
      return
    }
    if (appInfo.mode === MiscTypes.OAUTH_MODE) {
      dispatch(openModalRedux({ modalType: ModalTypeEnums.AUTHORIZE_ORG }))
      return
    }
    try {
      dispatch({ type: 'RESET_STATE' })
    } catch (error) {
      errorToast("You don't have access to this organization")
      navigate(`${config.orgBaseUrl}`)
    }
    // if (!orgData[newOrgId]?.meta?.org_status) {
    //   window.location.href = `/activate/${newOrgId}`
    // } else {
    // }
    navigate(
      serviceId && !isModal
        ? `${config.projectsBaseUrl}/${newOrgId}/appsexplore/servicepage/${serviceId}?skipactionselection=true`
        : window.location.pathname === '/connections'
        ? `/auth/${newOrgId}`
        : `${config.projectsBaseUrl}/${newOrgId}`
    )
    dispatch(clearScriptsList())
  }

  return (
    <Box className='h-100 w-full mb-4'>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        autoHeight
        pagination
        onRowClick={(params) => selectWorkspace(params.row)}
        sx={{
          overflow: 'auto',
          cursor: 'pointer',
          '.MuiDataGrid-columnHeader': {
            paddingLeft: '10px'
          }
        }}
      />
      <CustomModal
        className='orgbox__model'
        fullScreen={false}
        openModal={appInfo.mode === 'oauth' && appInfo.isModalOpen && appInfo.modalType === ModalTypeEnums.AUTHORIZE_ORG}
      >
        <AuthorizeOrgModal selectedOrg={selectedOrg} />
      </CustomModal>
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(AllOrgs), [ParamsEnums.orgId, ParamsEnums.serviceId]))
