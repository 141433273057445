import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { getDataFromRedisDb } from './api'
import { getProxyAuthToken } from './utils/utilities'

function AuthWrapper() {
  const queryParams = new URLSearchParams(window.location.search)
  const redisKey = queryParams.get('_key')
  const [renderOutlet, setRenderOutlet] = useState(!redisKey)
  async function setTokenInSession() {
    if (getProxyAuthToken()) setRenderOutlet(true)
    else if (redisKey) {
      try {
        // Use the queryParamsJson in the getDataFromRedisDb call
        const getDataFromRedis = await getDataFromRedisDb(redisKey)
        if (getDataFromRedis) {
          sessionStorage.setItem('ssoEmbedToken', getDataFromRedis?.token)
          setRenderOutlet(true)
        }
      } catch (error) {
        console.error('Failed to initialize WebSocket client:', error)
      }
    }
  }
  useEffect(() => {
    setTokenInSession()
  }, [])
  return renderOutlet ? <Outlet /> : null
}
export default AuthWrapper
