import { Box, Divider } from '@mui/material'
import React from 'react'
import { useCustomSelector } from '../../../../utils/deepCheckSelector'
import { ParamsEnums } from '../../../../enums'
import addUrlDataHoc from '../../../../hoc/addUrlDataHoc.tsx'

/**
 * @param {object} root0 - props object
 * @param {string} root0.scriptId - The script ID
 * @param {string} root0.stepId - The step ID
 * @param {string} root0.slugName - The slug name
 * @param {string} root0.tabName - The tab name
 * @returns {void} - jsx element
 */
function ShowPayload({ slugName: currentSlugName, sectionIdOrScriptId: scriptId, sectionKey }) {
  // This component is used to show paylaod in api slider
  const slugName = sectionKey || currentSlugName
  const { stepInstancePayload } = useCustomSelector((state) => ({
    stepInstancePayload: state.invocationV2?.[scriptId]?.invocationData?.stepRequestSnapshot?.[slugName]
  }))

  const RenderElements = () => {
    return Object.entries(stepInstancePayload || {}).map(([key, value]) => {
      if (key === 'httpsAgent') {
        return null
      }

      return (
        <Box key={key}>
          <Divider />
          <Box className='flex-center'>
            <Box className='p-1 font-bold'>{key.toUpperCase()} -</Box>
            {typeof value === 'string' && <Box className='p-1 word_break-all'>&#34;{value}&#34;</Box>}
          </Box>
          <Divider />
          {value !== null &&
            typeof value === 'object' &&
            Object.entries(value).map(([k, val]) => {
              return (
                <Box key={k}>
                  <Box className='p-1'>
                    {k}&ensp;:&ensp;{val !== null ? JSON.stringify(val) : 'null'}
                  </Box>
                </Box>
              )
            })}
        </Box>
      )
    })
  }

  return <>{RenderElements()}</>
}
export default React.memo(
  addUrlDataHoc(React.memo(ShowPayload), [ParamsEnums.slugName, ParamsEnums.sectionIdOrScriptId, ParamsEnums.sectionKey])
)
