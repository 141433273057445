export default function NotFoundPageSvg() {
  return (
    <svg width='120' height='40' viewBox='0 0 159 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M61.8011 5.7258C61.1022 5.16129 60.2151 4.83871 59.1129 4.78494H54.8118C53.5215 4.83871 52.5538 5.18817 51.9624 5.83333C51.371 6.47849 51.0753 7.2043 51.0753 8.06451V16.2366C51.0753 17.1237 51.5323 17.7957 52.3925 18.1989C53.0914 18.5215 53.7903 18.6828 54.4624 18.6828H58.414C59.543 18.6828 59.8118 18.871 59.8387 18.871C59.8925 18.9247 60.0269 19.0323 60.0269 19.4086V26.828C60.0269 27.3387 59.9194 27.7419 59.7312 28.0107C59.543 28.2796 59.0591 28.3333 58.7097 28.3333H55.4301C54.4355 28.3333 54.2204 28.0914 54.1936 28.0645C54.0591 27.9032 53.9247 27.5538 53.9247 26.8548V21.6667H51.0484V27.7688C51.0484 28.5484 51.4247 29.2473 52.1774 29.8387C52.8763 30.4032 53.7634 30.7258 54.8656 30.7796H59.1667C60.457 30.7258 61.4247 30.3763 62.0161 29.7312C62.6075 29.086 62.9032 28.3602 62.9032 27.5V18.629C62.9032 18.1989 62.7419 17.7957 62.4462 17.5C62.2043 17.2581 61.9355 17.0161 61.6398 16.828C61.3441 16.6398 60.9946 16.5054 60.6452 16.3978C60.2688 16.2903 59.9731 16.2366 59.7312 16.2366H55.1075C54.328 16.2366 54.1129 16.129 54.086 16.1559C54.0054 15.9946 53.9516 15.7527 53.9516 15.4301V8.70967C53.9516 8.19892 54.0323 7.76881 54.2204 7.5C54.3548 7.31182 54.7312 7.2043 55.2688 7.2043H58.5484C59.4892 7.2043 59.7312 7.41935 59.7849 7.5C59.9731 7.74193 60.0538 8.17204 60.0538 8.73655V13.871H62.9301V7.79569C62.9301 7.01612 62.5538 6.3172 61.8011 5.7258Z'
        fill='white'
      />
      <path
        d='M80.8602 5.7258C80.1613 5.16129 79.2742 4.83871 78.172 4.78494H73.8979C72.5807 4.78494 71.6129 5.1344 71.0215 5.80645C70.4301 6.45161 70.1344 7.2043 70.1344 8.06451V27.5C70.1344 28.2796 70.4032 29.0054 70.9677 29.6774C71.5323 30.3495 72.5 30.7258 73.8172 30.7796H78.4946H78.5215C79.6505 30.7258 80.5108 30.4032 81.1022 29.7849C81.6936 29.1667 82.0161 28.4677 82.0161 27.715V7.82258C81.9893 7.01613 81.6129 6.3172 80.8602 5.7258ZM79.1129 8.73655V26.7742C79.1129 27.3387 79.0054 27.7688 78.8441 28.0107C78.7903 28.0914 78.5484 28.3064 77.6075 28.3064H75C73.6828 28.3064 73.3602 28.0376 73.3065 27.9839C73.0914 27.7419 72.9839 27.3387 72.9839 26.8011V8.70967C72.9839 8.2258 73.1183 7.87634 73.3602 7.60752C73.629 7.33871 73.9247 7.2043 74.3011 7.2043H77.5807C78.5215 7.2043 78.7635 7.41935 78.8172 7.5C79.0054 7.74193 79.1129 8.17204 79.1129 8.73655Z'
        fill='white'
      />
      <path
        d='M99.8925 5.7258C99.1936 5.16129 98.3065 4.83871 97.2043 4.78494H92.9301C91.7742 4.78494 90.8333 5.10752 90.1613 5.77957C89.4893 6.45161 89.1667 7.2043 89.1667 8.06451V27.5C89.1667 28.2796 89.4355 29.0054 90 29.6774C90.5645 30.3495 91.5054 30.7258 92.8495 30.7796H97.5269C98.6559 30.7258 99.5161 30.4032 100.134 29.7849C100.726 29.1667 101.048 28.4677 101.048 27.715V21.6667H98.1721V26.8011C98.1721 27.3656 98.0645 27.7957 97.9032 28.0376C97.8495 28.1183 97.6075 28.3333 96.6667 28.3333H94.1667C92.9032 28.3333 92.5269 28.0914 92.4194 27.9839C92.1774 27.7419 92.0699 27.3387 92.0699 26.828V8.70967C92.0699 8.2258 92.2043 7.87634 92.4463 7.60752C92.7151 7.33871 93.0108 7.2043 93.3871 7.2043H96.6667C97.6613 7.2043 97.8764 7.44623 97.9032 7.47311C98.0377 7.63441 98.1721 7.98387 98.1721 8.68279V13.8441H101.048V7.79569C101.022 7.01612 100.645 6.3172 99.8925 5.7258Z'
        fill='white'
      />
      <path
        d='M120.591 4.78494H117.419L111.075 15.914V4.78494H108.226V30.7527H111.075V21.1021L112.634 18.5215L117.661 30.7527H120.672L114.516 15.5914L120.591 4.78494Z'
        fill='white'
      />
      <path
        d='M127.554 30.7527H139.462V28.3064H130.43V18.6021H138.441V16.1828H130.43V7.2043H139.462V4.78494H127.554V30.7527Z'
        fill='white'
      />
      <path d='M146.64 4.78494V7.2043H151.156V30.7527H154.086V7.2043H158.602V4.78494H146.64Z' fill='white' />
      <path
        d='M15.6183 11.8817C15.6183 10.5376 14.5161 9.43549 13.1721 9.43549C11.828 9.43549 10.7258 10.5376 10.7258 11.8817C10.7258 13.2258 11.828 14.328 13.1721 14.328C14.5161 14.328 15.6183 13.2258 15.6183 11.8817Z'
        fill='white'
      />
      <path
        d='M29.5161 11.8817C29.5161 10.5376 28.414 9.43549 27.0699 9.43549C25.7258 9.43549 24.6237 10.5376 24.6237 11.8817C24.6237 13.2258 25.7258 14.328 27.0699 14.328C28.414 14.328 29.5161 13.2258 29.5161 11.8817Z'
        fill='white'
      />
      <path
        d='M39.4893 36.9086H36.6398V34.6774H39.4893C40 34.6774 40.4032 34.2742 40.4032 33.7634C40.4032 33.2527 40 32.8495 39.4893 32.8495H36.6398V32.5269C36.6398 32.0161 36.2366 31.6129 35.7258 31.6129C33.4409 31.6129 31.8011 32.6344 31.2097 34.328C31.129 34.3011 31.0484 34.2742 30.9409 34.2742H3.06452V3.06452H36.7473V24.5699C36.7473 25.4032 37.4462 26.1022 38.2796 26.1022C39.1129 26.1022 39.8118 25.4301 39.8118 24.5699V1.53226C39.8118 0.698924 39.1129 0 38.2796 0H1.53226C0.698925 0 0 0.672043 0 1.53226V35.8065C0 36.6398 0.672043 37.3387 1.53226 37.3387H30.9409C31.0215 37.3387 31.1022 37.3118 31.1828 37.2849C31.8548 39.2473 33.8441 40 35.7258 40C36.2366 40 36.6398 39.5968 36.6398 39.086V38.7634H39.4893C40 38.7634 40.4032 38.3602 40.4032 37.8495C40.4032 37.3387 40 36.9086 39.4893 36.9086ZM34.8118 38.0914C33.8172 37.9032 32.7688 37.3387 32.7688 35.8065C32.7688 34.2473 33.8172 33.7097 34.8118 33.5215V38.0914Z'
        fill='white'
      />
      <path
        d='M20.5645 25.6452C17.8495 25.6452 15.6183 25.2419 15.4839 25.2419C14.5699 25.0807 13.9785 24.2204 14.1398 23.3065C14.3011 22.3925 15.1344 21.8011 16.0753 21.9624C16.129 21.9624 20.6989 22.7688 24.2742 21.9624C25.1882 21.7742 26.0753 22.3387 26.2634 23.2258C26.4516 24.1398 25.8871 25.0269 25 25.2151C23.5215 25.5376 21.9893 25.6452 20.5645 25.6452Z'
        fill='white'
      />
    </svg>
  )
}
