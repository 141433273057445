import React from 'react'
import PropTypes from 'prop-types'
import useStyles from 'substyle'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'

const defaultStyle = {
  fontWeight: 'inherit',
  wordBreak: 'break-all'
}

function Mention({ display, style, className, classNames }) {
  const styles = useStyles(defaultStyle, { style, className, classNames })
  return <strong {...styles}>{display}</strong>
}

Mention.propTypes = {
  onAdd: PropTypes.func,
  renderSuggestion: PropTypes.func,
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]),
  markup: PropTypes.string,
  displayTransform: PropTypes.func,
  isLoading: PropTypes.bool
}

Mention.defaultProps = {
  trigger: '@',
  markup: '@[__display__](__id__)',
  displayTransform: function (id, display) {
    return display || id
  },
  onAdd: () => null,

  renderSuggestion: null,
  isLoading: false
}

export default React.memo(addUrlDataHoc(React.memo(Mention), []))
