import { Box, TextField, Typography, useTheme } from '@mui/material'
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react'
import { MessageContext } from './ChatbotComponent.tsx'
import CustomAutoSuggestV2 from '../customAutoSuggestV2/customAutoSuggestV2'

interface ChatbotTextFieldType {
  onSend?: any
  loading?: boolean
  messageRef?: any
  disabled?: boolean
  options?: any[]
  placeholder: string
  isAutoSuggestTextField?: boolean
}
const ChatbotTextField = forwardRef(
  (
    {
      onSend = () => {},
      loading,
      messageRef,
      disabled = false,
      options = [],
      placeholder = 'Enter your message',
      threadId,
      hideSuggestions = false,
      onChange = () => {},
      isAutoSuggestTextField = true
    }: ChatbotTextFieldType,
    ref
  ) => {
    const theme = useTheme() // Hook to access the theme
    const [message, setMessage] = useState('')
    useImperativeHandle(ref, () => ({
      emptyTextfield: () => setMessage(''),
      getCurrentText: () => message
    }))
    const MessagesList: any = useContext(MessageContext)
    const { addMessage } = MessagesList

    function sendMessagefunction() {
      onSend(message)
      setMessage('')
    }
    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' && !event.shiftKey && !loading) {
        event.preventDefault()
        sendMessagefunction()
      }
    }

    const handleMessage = useCallback((event: MessageEvent) => {
      if (event?.data?.type === 'open') {
        messageRef?.current?.focus()
      }
    }, [])

    useEffect(() => {
      window.addEventListener('message', handleMessage)
      return () => {
        window.removeEventListener('message', handleMessage)
      }
    }, [handleMessage])

    const sharedProps = {
      placeholder,
      autoFocus: true,
      id: threadId,
      onKeyDown: handleKeyDown,
      disabled,
      inputRef: messageRef
    }

    return (
      <Box className='w-100'>
        <Box className={`${!isAutoSuggestTextField && 'bg-[var(--col-grey-background)]'} w-100 flex-start-end h-full px-2 py-3`}>
          {options && options.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                gap: theme.spacing(1),
                flexWrap: 'wrap',
                padding: theme.spacing(1),
                animation: 'fadeIn 0.5s ease-in-out',
                '@keyframes fadeIn': {
                  '0%': { opacity: 0 },
                  '100%': { opacity: 1 }
                }
              }}
            >
              {options?.map((option) => (
                <Box
                  key={option}
                  onClick={() => addMessage(option)}
                  className='border-p5 p-2 cursor-pointer flex-center-center'
                  sx={{
                    borderRadius: 7,
                    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <Typography variant='caption'>{option}</Typography>
                </Box>
              ))}
            </Box>
          )}
          <Box className='w-100 pt-2 pl-3 flex items-center gap-2 !text-base !font-semibold'>
            <AutoAwesomeRoundedIcon className='text-[#4318FF] !size-6' />
            {isAutoSuggestTextField ? (
              <CustomAutoSuggestV2
                {...sharedProps}
                onChangeFunction={(_, messagequery) => {
                  setMessage(messagequery)
                  onChange(messagequery)
                }}
                height='30px'
                transparent
                noborder
                value={message}
                hideSuggestionsOnDryRun={false}
                appendSpaces
                hidePlusButton={hideSuggestions}
              />
            ) : (
              <TextField
                projectSlider='true'
                noborder='true'
                fullWidth
                multiline
                defaultValue={message}
                onChange={(e) => {
                  const value = e.target.value
                  setMessage(value)
                  onChange(value)
                }}
                {...sharedProps}
              />
            )}
          </Box>
        </Box>
      </Box>
    )
  }
)

export default ChatbotTextField
ChatbotTextField.displayName = 'ChatbotTextField'
