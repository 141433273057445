import { Box, Typography } from '@mui/material'
import React from 'react'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'
import './ActionsListComponent.scss'

type Action = {
  rowid: string
  name: string
}

interface ActionsListComponentProps {
  actionsArray: Action[]
  addActionFunction: (action: Action) => void
  serviceIconUrl: string
  serviceName: string
  isPluginVerified: boolean
}

/**
 * ActionsListComponent is a React component that renders a list of actions
 * associated with a particular service. Each action is displayed as a clickable card.
 *
 * @component
 * @param {Object} props - The component props
 * @param {Array} props.actionsArray - An array of action objects to be displayed
 * @param {Function} props.addActionFunction - Function to be called when an action is clicked
 * @param {string} props.serviceIconUrl - URL of the service icon to be displayed
 * @param {string} props.serviceName - Name of the service to be displayed
 * @returns {JSX.Element} The rendered component
 */

function ActionsListComponent({
  actionsArray,
  addActionFunction,
  serviceIconUrl,
  serviceName,
  isPluginVerified,
  showHeader = true
}: ActionsListComponentProps) {
  return (
    <Box className='flex flex-col w-full'>
      {showHeader && (
        <Box className='flex-start-center gap-2 px-3 py-2'>
          <IconWrapper iconUrl={serviceIconUrl} size='24px' />
          <Box className='flex-start-center'>
            <Box className='w-100'>
              <Typography variant='smallHeading' className='w-100'>
                {serviceName}
              </Typography>
              {!isPluginVerified && <sup className='sup-style'>(&beta;eta)</sup>}
            </Box>
          </Box>
        </Box>
      )}
      {actionsArray?.map((action, index) => {
        return (
          <Box
            onClick={() => {
              if (action.rowid?.startsWith('custom-action-ai')) addActionFunction(actionsArray[0], false, true)
              else addActionFunction(action)
            }}
            sx={{
              borderBottom: actionsArray?.length - 1 !== index ? '0.2px solid #ccc' : 'unset'
            }}
            className='px-3 py-2 cur-pointer hoverable-block w-100 gap-2 flex-start-center'
            key={action.rowid}
          >
            <Box className='column'>
              <Box className='flex-verticalcenter'>
                <Typography variant='h7'>{action?.name}</Typography>
                {(!isPluginVerified || action.isaiaction) && action.rowid !== `custom-action-ai-${action.pluginrecordid}` && (
                  <sup className='sup-style flex'>(&beta;eta)</sup>
                )}
              </Box>
              <Typography behaviour='colored' color='gray' className='one-line-trim'>
                {action?.description}
              </Typography>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(ActionsListComponent), [
    ParamsEnums.scriptId,
    ParamsEnums.stepId,
    ParamsEnums.slugName,
    ParamsEnums.tabName,
    ParamsEnums.projectId,
    ParamsEnums.orgId,
    'position'
  ])
)
