import { Box, Typography } from '@mui/material'
import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { MiscTypes, ParamsEnums } from '../../enums'
import { useFetchPlugins } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'
import { SkeletonForListOfPlugs } from '../../pages/Metrics/SkeletonLoader.tsx'

/**
 * A functional component that renders a list of plugins for a given organization and project.
 * Displays skeleton loader while fetching data and renders plugin cards when data is loaded.
 *
 * @param {Object} props - Component props
 * @param {string} props.orgId - The organization ID
 * @param {string} props.projectId - The project ID
 * @param {Function} [props.onClickOnElement] - Callback function for element click, defaults to a no-op function
 *
 * @returns {JSX.Element} The rendered component
 */
function AllAppsAddStep({
  orgId,
  projectId,
  triggerServiceIdToSkip,
  onClickOnElement = () => {}
}: {
  orgId: string
  projectId: string
  triggerServiceIdToSkip?: string
  onClickOnElement?: (plug: any) => void
}): JSX.Element {
  const { filteredPlugins } = useCustomSelector((state) => {
    const filteredServices =
      (state.appInfo.mode === MiscTypes.EMBED_MODE
        ? state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings?.config?.filteredServices
        : state.appInfo.localConfiguration?.filteredServices) || {}
    return {
      filteredPlugins: Object.keys(filteredServices) || []
    }
  })

  const { data: plugins, isLoading: loading } = useFetchPlugins(orgId, [], filteredPlugins)
  const pluginsArray = loading ? [] : Object.values(plugins || {})?.filter((plug) => plug.rowid !== triggerServiceIdToSkip)
  const pluginsArrayLength = pluginsArray?.length
  return (
    <Box className='column  w-100'>
      {loading
        ? SkeletonForListOfPlugs
        : pluginsArray?.map((plug, index) => {
            return (
              <Box
                sx={{
                  borderBottom: pluginsArrayLength - 1 !== index ? '0.2px solid #ccc' : 'unset'
                }}
                key={plug.rowid}
                onClick={() => onClickOnElement(plug)}
                className='px-3 py-2 cur-pointer hoverable-block w-100 gap-2 flex-spaceBetween-center'
              >
                <Box className='flex-start-center gap-3'>
                  <IconWrapper iconUrl={plug.iconurl} size='36px' />
                  <Box className='column'>
                    <Typography variant='h7'>{plug?.name}</Typography>
                    <Typography behaviour='colored' color='gray' className='one-line-trim'>
                      {plug?.description}
                    </Typography>
                  </Box>
                </Box>
                <ArrowForwardIosIcon className='opacity-0' fontSize='small' />
              </Box>
            )
          })}
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(AllAppsAddStep), [ParamsEnums.projectId, ParamsEnums.orgId]))
