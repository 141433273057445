import { useGridApiContext, gridColumnsTotalWidthSelector, gridColumnPositionsSelector } from '@mui/x-data-grid'
import { Box, Skeleton, LinearProgress, styled } from '@mui/material'
import React, { useMemo } from 'react'

function mulberry32(a: number): () => number {
  return () => {
    /* eslint-disable */
    let t = (a += 0x6d2b79f5)
    t = Math.imul(t ^ (t >>> 15), t | 1)
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61)
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296
    /* eslint-enable */
  }
}

function randomBetween(seed: number, min: number, max: number): () => number {
  const random = mulberry32(seed)
  return () => min + (max - min) * random()
}

const SkeletonCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`
}))

export default function SkeletonLoadingOverlay() {
  const apiRef = useGridApiContext()

  const dimensions = apiRef.current?.getRootDimensions()
  const viewportHeight = dimensions?.viewportInnerSize.height ?? 0

  // @ts-expect-error Function signature expects to be called with parameters, but the implementation suggests otherwise
  const rowHeight = apiRef.current.unstable_getRowHeight()
  const skeletonRowsCount = Math.ceil(viewportHeight / rowHeight)

  const totalWidth = gridColumnsTotalWidthSelector(apiRef)
  const positions = gridColumnPositionsSelector(apiRef)
  const inViewportCount = useMemo(() => positions.filter((value) => value <= totalWidth).length, [totalWidth, positions])
  const columns = apiRef.current.getVisibleColumns().slice(0, inViewportCount)

  const children = useMemo(() => {
    // reseed random number generator to create stable lines betwen renders
    const random = randomBetween(12345, 25, 75)
    const array: React.ReactNode[] = []

    /* eslint-disable */
    for (let i = 0; i < skeletonRowsCount; i += 1) {
      for (const column of columns) {
        const width = Math.round(random())
        array.push(
          <SkeletonCell key={`column-${i}-${column.field}`} sx={{ justifyContent: column.align }}>
            <Skeleton sx={{ mx: 1 }} width={`${width}%`} />
          </SkeletonCell>
        )
      }
      array.push(<SkeletonCell key={`fill-${i}`} />)
    }
    return array
  }, [skeletonRowsCount, columns])

  const rowsCount = apiRef.current.getRowsCount()

  return rowsCount > 0 ? (
    <LinearProgress />
  ) : (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `${columns.map(({ computedWidth }) => `${computedWidth}px`).join(' ')} 1fr`,
        gridAutoRows: rowHeight
      }}
    >
      {children}
    </div>
  )
}
export const SkeletonForShowGroupActionsAndPlug = (
  <Box className='gap-3 flex flex-col w-full'>
    {Array.from(new Array(2)).map((index) => (
      <React.Fragment key={index}>
        <Skeleton animation='wave' height={20} width='40%' />
        <Box className='gap-2 flex flex-col w-full'>
          {Array.from(new Array(5)).map((idx) => (
            <Skeleton variant='rectangle' width='100%' height='40px' />
          ))}
        </Box>
      </React.Fragment>
    ))}
  </Box>
)

export const SkeletonForListOfPlugs = (
  <Box className='gap-2 flex flex-col w-full py-2'>
    {Array.from(new Array(5)).map((index) => (
      <Skeleton key={index} variant='rectangle' width='100%' height='40px' />
    ))}
  </Box>
)

export function FlowJsonSkeleton(isVersioning, isPublishedTab) {
  return (
    <Box className=' w-full flex flex-col justify-start items-center pl-11'>
      <Box className=' w-full flex flex-col justify-start items-start gap-3 '>
        <Skeleton variant='text' className='w-1/2 font-3rem' />
        <Box className='w-full flex justify-start items-center gap-1'>
          <Skeleton variant='rectangular' width={90} height={30} />
          <Skeleton variant='rectangular' width={90} height={30} />
        </Box>
        <Box>
          <Box className='w-full flex items-center gap-2 mb-2'>
            <Skeleton variant='text' width={75} className='font-2rem' />
            <Skeleton variant='text' width={200} className='font-1rem' />
          </Box>
          <Skeleton variant='text' className='w-full font-1rem' />
        </Box>
        <Box className='w-full'>
          <Skeleton variant='text' width={50} className='font-2rem' />
          <Box className='flex flex-col justify-start items-center gap-2'>
            <Skeleton variant='rectangular' width={500} height={50} />
            <Skeleton variant='rectangular' width={500} height={50} />
            <Skeleton variant='rectangular' width={500} height={50} />
            <Box className='flex items-center w-full gap-3'>
              <Skeleton variant='rectangular' width={30} height={30} />
              <Skeleton variant='rectangular' width={454} height={30} />
            </Box>
          </Box>
        </Box>
        <Box>
          {isVersioning && (
            <Box className='flex flex-col gap-1'>
              <Skeleton variant='text' width={100} className='font-2rem' />
              <Skeleton variant='text' width={250} className='font-1rem' />
            </Box>
          )}
        </Box>
      </Box>
      {isPublishedTab && <Skeleton variant='text' width={70} className='font-1rem' />}
      <Box className='workflow__dryrun flex justify-around items-center py-2 w-full gap-3 cursor-pointer '>
        <Skeleton variant='rectangular' width={454} height={30} />
      </Box>
    </Box>
  )
}
