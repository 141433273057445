import { Code } from '@mui/icons-material'
import React, { useMemo, useRef, useState } from 'react'
import { Box, Chip, TextField, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp'
import JavascriptIcon from '@mui/icons-material/Javascript'
import ApiIcon from '@mui/icons-material/Api'
import AltRouteIcon from '@mui/icons-material/AltRoute'
import { BlockTypes, ParamsEnums, STEP_OPERATION_STATUS, Tabnames, sliderTypes } from '../../enums'
import { setKeyValueInstance } from '../../store/stepsV2/stepSliceV3.ts'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { warningToast } from '../customToast'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'
import { replaceUnderscoreWithSpace } from '../../utils/utilities'

function StepTitleTextfieldComponent({ slugName, stepId, tabName, scriptId }) {
  const dispatch = useDispatch()
  const isPublishedTab = tabName === Tabnames.PUBLISH

  const { title, currentStepType, iconUrl, publishedFlowJson, flowJson, usedVariablesPayloadData, serviceName, actionName, isSwitchChild } =
    useCustomSelector((state: $ReduxCoreType) => {
      const flowJson = state?.flowJsonV2?.[scriptId]?.flowJson
      const stepData = state.stepsDataV3?.[scriptId]?.[stepId]?.[tabName]
      return {
        title: stepData?.title,
        serviceName: stepData?.pluginData?.serviceName,
        actionName: stepData?.pluginData?.actionName,
        currentStepType: flowJson?.blocks?.[slugName]?.type || state.appInfo.currentStepType,
        iconUrl:
          stepId === 'CRON_PRE_PROCESS'
            ? state?.stepsDataV3?.[scriptId]?.[stepId]?.[tabName]?.iconUrl
            : tabName === 'published'
            ? state?.flowJsonV2?.[scriptId]?.publishedFlowJson?.blocks?.[slugName]?.iconUrl
            : state?.flowJsonV2?.[scriptId]?.draftedFlowJson?.blocks?.[slugName]?.iconUrl || '',
        // iconUrl: state.stepsDataV3?.[scriptId]?.[stepId]?.[tabName]?.iconUrl,
        publishedFlowJson: state?.flowJsonV2?.[scriptId]?.publishedFlowJson || {},
        flowJson,
        usedVariablesPayloadData: state?.scripts?.scripts?.[scriptId]?.used_variables?.payloadData,
        isSwitchChild: Boolean(flowJson?.blocks?.[slugName]?.parent)
      }
    })
  const stepNametextfield = useRef()
  const [error, setError] = useState(false)

  const setEditToggleCallBack = (val: boolean) => {
    dispatch(setKeyValueInstance({ editToggle: val, hasUnsavedCode: false }))
    document.getElementById('function-title-textfield')?.blur()
  }

  const pattern = /^[a-zA-Z0-9_]+$/
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') setEditToggleCallBack(true)
  }

  const handleNameChange = (e) => {
    if (usedVariablesPayloadData?.context?.res?.[slugName] || usedVariablesPayloadData?.context?.vals?.[slugName]) {
      warningToast('The function name cannot be modified as it is used by another step.')
      return
    }
    if (error) setError(false)
    let newTitle = title
    newTitle = e.target.value.replace(/\s+/g, '_')
    dispatch(setKeyValueInstance({ title: newTitle }))
  }

  const computedValue = useMemo(() => {
    if (currentStepType === 'ifBlock') {
      const ifBlock = flowJson?.blocks?.[title]

      if (title === ifBlock?.identifier) {
        const switchArray = flowJson?.order?.[flowJson?.blocks?.[title]?.parent] || []
        const indexOfIfBlock = switchArray.indexOf(title) + 1
        return `Path ${indexOfIfBlock}`
      }
    }
    if (title) return title
    if (currentStepType === BlockTypes.RESPONSE) return 'Response'
    return ''
  }, [title, flowJson?.trigger?.type, flowJson?.trigger?.name, tabName])

  const returnIcon = () => {
    let icon = null
    if (iconUrl || currentStepType === sliderTypes.PLUG) return <IconWrapper iconUrl={iconUrl} size='48px' />
    if (currentStepType === sliderTypes.API) icon = <ApiIcon className='w-100 h-100' />
    else if (currentStepType === sliderTypes.VARIABLE) icon = <Code className='w-100 h-100' />
    else if (currentStepType === sliderTypes.IFBLOCK)
      icon = isSwitchChild ? (
        <AltRouteIcon className='transform-180' />
      ) : (
        <Chip label='If' color='primary' size='medium' variant='contained' />
      )
    else if (flowJson?.blocks?.[slugName]?.aistep) icon = <AutoAwesomeSharpIcon className='w-100 h-100' />
    else icon = <JavascriptIcon className='w-100 h-100' />
    return <IconWrapper component={icon} size='48px' />
  }

  const returnEditNameComponent = () => {
    return (
      <TextField
        key={tabName}
        steptitle='true'
        onKeyDown={handleKeyDown}
        placeholder={`${currentStepType?.charAt(0)?.toUpperCase() + currentStepType?.slice(1) ?? currentStepType} Name`}
        error={error}
        size='small'
        fullWidth
        inputRef={stepNametextfield}
        autoFocus={!slugName}
        onBlur={(e) => {
          if (title?.length >= 4) {
            setEditToggleCallBack(true)
          }
          if (e.target.value?.length < 4 || e.target.value > 25 || !pattern.test(e.target.value)) setError(true)
        }}
        className='title-textfield'
        id='function-title-textfield'
        noborder='true'
        inputProps={{ maxLength: 50 }}
        value={replaceUnderscoreWithSpace(computedValue)}
        onChange={handleNameChange}
        disabled={
          isPublishedTab ||
          (currentStepType === BlockTypes.TRIGGER && publishedFlowJson?.trigger && flowJson?.trigger?.type !== 'delete') ||
          flowJson?.blocks?.[slugName]?.status === STEP_OPERATION_STATUS.DELETE
        }
      />
    )
  }
  return (
    <Box className='flex-start-center w-100 gap-2' onClick={(e) => e.stopPropagation()}>
      {currentStepType !== BlockTypes.RESPONSE && returnIcon()}
      <Box className='column w-100'>
        <Box className='flex-start-center w-100'>
          {slugName === 'CRON_PRE_PROCESS' ? (
            <Box className='flex-start-center gap-1'>
              <Typography variant='h5'>Retrieve Data</Typography>
            </Box>
          ) : (
            returnEditNameComponent()
          )}
        </Box>
        {currentStepType === 'plugin' && serviceName && (
          <Typography variant='subtitle'>
            {!computedValue?.toLowerCase()?.includes(actionName?.trim()?.toLowerCase()?.replaceAll(' ', '_'))
              ? `${actionName?.replaceAll('_', ' ')} in `
              : ''}
            {serviceName}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(StepTitleTextfieldComponent), [
    ParamsEnums.slugName,
    ParamsEnums.stepId,
    ParamsEnums.tabName,
    ParamsEnums.scriptId
  ])
)
