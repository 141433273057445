import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, THUNK_CALL_STATUS } from '../../enums'
import { saveScripts, saveScriptTemplate } from '../../store/scripts/scriptsThunk'
import { sendDataToParentInEmbed } from '../../utils/utilities'
import { errorToast } from '../customToast'

function EmbedWrapper({ orgId, projectId }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [parameters, setParameters] = useState({})
  // const {flow_id, flow_hit_id, meta, create_default_flow=false, create_default_flow_with_api=false, create_default_flow_with_function=false}=json
  window.addEventListener('message', (e) => {
    if (e.data?.type === 'script_id' && orgId && projectId) {
      setParameters(e.data)
      if (e.data?.scriptId && e.data?.flowHitId)
        navigate(`/integrations/embed/${orgId}/${projectId}/workflow/${e.data?.scriptId}/logs?flowhitid=${e.data?.flowHitId}`)
      else if (e.data?.scriptId) navigate(`/integrations/embed/${orgId}/${projectId}/workflow/${e.data?.scriptId}/draft`)
      else if (e.data?.templateId) {
        dispatch(saveScriptTemplate({ projectId, templateId: e.data?.templateId })).then((e) => {
          sendDataToParentInEmbed('initiated', e?.payload)
          if (e?.meta?.requestStatus === THUNK_CALL_STATUS?.FULFILLED && e?.payload?.id)
            navigate(`/integrations/embed/${orgId}/${projectId}/workflow/${e?.payload?.id}/draft`)
        })
      } else if (e.data?.create_default_flow_with_api || e.data?.create_default_flow_with_function) {
        dispatch(
          saveScripts({
            project_id: projectId,
            org_id: orgId,
            script: '//write your code here.',
            triggerDetails: {
              triggerType: 'webhook',
              type: 'add'
            },
            functionDetails: {
              type: e.data?.create_default_flow_with_api ? 'api' : 'function'
            },
            isBasicFlow: true,
            meta: e.data?.meta
          })
        ).then((e) => {
          const scriptId = e.payload?.id
          if (scriptId && e?.meta?.requestStatus === THUNK_CALL_STATUS.FULFILLED) {
            sendDataToParentInEmbed('initiated', e.payload)
            navigate(`/integrations/embed/${orgId}/${projectId}/workflow/${scriptId}/draft`)
          } else errorToast('Flow creation failed.')
        })
      } else navigate(`/integrations/embed/${orgId}/${projectId}`)
    }
  })
  return <Outlet context={parameters} />
}
export default React.memo(addUrlDataHoc(React.memo(EmbedWrapper), [ParamsEnums.orgId, ParamsEnums.projectId]))
