/* eslint-disable */
import { Avatar } from '@mui/material'

export default function IconWrapper({
  hideBorder = false,
  component = null,
  size,
  iconUrl = 'https://cdn-icons-png.flaticon.com/512/380/380127.png',
  onClick = () => {}
}) {
  component = component || <img src={iconUrl} alt='' onClick={onClick} className='w-full' />
  return (
    <Avatar noCustomStyle={hideBorder} sx={{ height: size || 'default-size', width: size || 'default-size', margin: 0 }}>
      {component}
    </Avatar>
  )
}
