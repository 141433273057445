import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit'
import { initialState, reducers } from './appInfoReducer.ts'
import { AppInfoReduxType } from '../../types/AppInfoState.ts'

const appInfoSlice = createSlice<AppInfoReduxType, SliceCaseReducers<AppInfoReduxType>, 'appInfo'>({
  name: 'appInfo',
  initialState,
  reducers
})

export const {
  setIsScriptDrawerOpen,
  setShowFullScreen,
  closeStepSlider,
  openStepSlider,
  clearAppInfo,
  openModalRedux,
  updateAppInfoState,
  closeModalRedux,
  toggleLogsSlider,
  setIsDryRunResizerOpen,
  setIsDryRunInProcessForScript,
  toggleProjectSlider,
  openAiSlider,
  closeAiSlider,
  setIsAlertOnPublishSliderOpen,
  togglePluginActionSelect,
  togglePluginDashboard,
  compareSliderOpen,
  setSupportedLibraries,
  toggleisFeedbackSubmitted
} = appInfoSlice.actions
export default appInfoSlice.reducer
