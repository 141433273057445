import React, { memo } from 'react'
import { Collapse, Typography, Box, Chip } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import './Tree.scss'

/**
 * Displays brackets with the length of data if it's an object or array.
 * @param {{ data: object | array }} props - The data to be processed.
 * @returns {React.ReactNode | null} The component displaying the data count or null if data is not valid.
 */
function ShowBrackets({ data }) {
  if (!data || typeof data !== 'object') return null
  const length = Object.keys(data)?.length
  return (
    <Typography fontWeight={500} className='opacity-half ml-1' fontSize={12}>
      {Array.isArray(data) ? `[${length}]` : `{${length}}`}
    </Typography>
  )
}

/**
 * Renders buttons to copy value and path of the data.
 * @param {*} value - The value to be copied.
 * @param {string} newPath - The path to be copied.
 * @returns {React.ReactNode} The buttons for copying value and path.
 */
function rightButtons(value, newPath, disablecopypath) {
  const handleCopy = (value) => {
    const valuetocopy = typeof value === 'string' ? value : JSON.stringify(value, null, 2)
    if (window.parent !== window) {
      window?.parent?.postMessage({ type: 'copy', message: valuetocopy }, '*')
    } else navigator.clipboard.writeText(valuetocopy)
  }

  return (
    // eslint-disable-next-line
    <span
      className='right-button gap-1'
      onMouseDown={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <Chip
        size='extrasmall'
        className='px-1'
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleCopy(value?.toString())
        }}
        label='Copy Value'
      />

      {!disablecopypath && (
        <Chip
          size='extrasmall'
          className='ml-2 px-1'
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleCopy(newPath)
          }}
          label='Copy Path'
        />
      )}
    </span>
  )
}

/**
 * A recursive component that renders JSON data as a collapsible tree structure.
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.callFromChildFunction - Function called from child components.
 * @param {Object | Array} props.data - The data to render.
 * @param {string} [props.parentName='context'] - The parent node name.
 * @param {string} [props.path=''] - The path of the current data structure.
 * @param {Function} props.handleAddSuggestion - Function to handle addition of suggestions.
 * @param {Array} props.search - Array containing search terms.
 * @param {number} props.nestedLevel - The current level of nesting in the data structure.
 * @param {Array} props.stepOrder - Array representing the order of steps.
 * @param {boolean} props.dull - If true, reduces the opacity for certain UI elements.
 * @returns {React.ReactNode} The tree structure of the JSON data.
 */
const OldTree = memo(
  ({
    callFromChildFunction,
    data,
    parentName = 'context',
    path = '',
    handleAddSuggestion,
    search = [],
    nestedLevel = 0,
    stepOrder = [],
    dull = false,
    disablecopypath = false
  }) => {
    if (!path) path = parentName
    const isObject = data !== null && typeof data === 'object'
    const current = search?.find((el) => el.path.includes(path) || path.includes(el.path))
    if (search?.length > 0 && !current) return null

    const [open, setOpen] = React.useState(current?.path.includes(path) ? true : nestedLevel <= 2)
    const handleClick = () => {
      setOpen(!open)
    }

    function callFromChild() {
      callFromChildFunction()
    }

    return (
      <Box className='flex flex-col w-full'>
        {/* Header section with click to collapse or expand the view */}
        <Box
          onClick={handleClick}
          className={`cursor-pointer w-full flex justify-start items-center gap-1 list-item  ${
            dull || stepOrder.indexOf(parentName) !== -1 ? 'opacity-50' : ''
          }`}
          sx={{ padding: '4px 8px', fontSize: '14px', color: '#333' }}
        >
          <ChevronRightIcon style={{ fontSize: '18px', transform: open ? 'rotate(90deg)' : '' }} />
          <Typography fontFamily='monospace' fontWeight={500} sx={{ backgroundColor: '#D8D8D8	', padding: '0px 8px' }}>
            {parentName}
          </Typography>
          <ShowBrackets data={data} />
          {rightButtons(JSON.stringify(data), path, disablecopypath)}
        </Box>
        <Collapse
          in={open}
          timeout={0}
          unmountOnExit
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            width: 'calc(100% - 14px) !important',
            marginLeft: '14px',
            '::before': {
              content: '""',
              position: 'absolute',
              left: '2px',
              top: 0,
              bottom: 0,
              width: '2px',
              backgroundColor: '#ccc'
            }
          }}
        >
          {isObject ? (
            Object.keys(data).map((k, index) => {
              const newPath = `${path}${Array.isArray(data) ? `[${k}]` : `.${k}`}`
              return data[k] != null && typeof data[k] === 'object' ? (
                <OldTree
                  key={`${nestedLevel}_${newPath}`}
                  data={data[k]}
                  parentName={Array.isArray(data) ? index : k}
                  path={newPath}
                  handleAddSuggestion={handleAddSuggestion}
                  search={search}
                  nestedLevel={nestedLevel + 1}
                  callFromChildFunction={callFromChild}
                  disablecopypath={disablecopypath}
                />
              ) : (
                <Box
                  className={`list-item cursor-pointer flex justify-start items-start flex-wrap gap-1  ${
                    dull || stepOrder.indexOf(parentName) !== -1 ? 'opacity-50' : ''
                  }`}
                  key={`${nestedLevel}_${newPath}`}
                  sx={{
                    fontSize: '14px',
                    padding: '6px',
                    color: '#333',
                    marginLeft: '25px',
                    width: 'calc(100% - 25px) !important'
                  }}
                >
                  <Box>
                    <Typography fontFamily='monospace' fontWeight={500} sx={{ backgroundColor: '#D8D8D8	', padding: '0px 8px' }}>
                      {!Array.isArray(data) ? `${k}` : index}
                    </Typography>
                  </Box>
                  <Typography fontFamily='monospace' className='pre-word-wrap' sx={{ color: '#333' }}>
                    {typeof data[k] === 'string' ? (
                      `"${data[k]}"` // Show strings in double quotes
                    ) : typeof data[k] === 'boolean' ? (
                      <span style={{ color: '#d14' }}>{data[k].toString()}</span> // Color booleans in crimson
                    ) : typeof data[k] === 'number' ? (
                      <span style={{ color: '#28b' }}>{data[k]}</span> // Color numbers in royal blue
                    ) : data[k] === null || data[k] === undefined ? (
                      <span style={{ color: '#666' }}>null</span> // Dull color for null/undefined
                    ) : (
                      data[k]?.toString()
                    )}
                    <span> {rightButtons(data[k], newPath, disablecopypath)}</span>
                  </Typography>
                </Box>
              )
            })
          ) : (
            <Typography fontFamily='monospace' className='pre-word-wrap ml-3'>
              {typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean' ? data.toString() : 'null'}
            </Typography>
          )}
        </Collapse>
      </Box>
    )
  }
)

OldTree.displayName = 'OldTree'
export default OldTree
