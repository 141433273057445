import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import CreateNewProjectButton from '../../AppsExplore/CreateNewProjectButton.tsx'
import { StyledAccordion, StyledAccordionSummary } from '../ScriptListItemComponent.tsx'

function FolderListAccordion({ children, HeadingComponent, isFolderOpen }) {
  const [expanded, setExpanded] = useState({
    folder: isFolderOpen || false
  })

  const handleAccordionChange = (key) => {
    setExpanded((prev) => ({
      ...prev,
      [key]: !prev[key]
    }))
  }
  return (
    <StyledAccordion expanded={expanded.folder} onChange={() => handleAccordionChange('folder')} className='w-full accordionSummary'>
      <StyledAccordionSummary styledSummery className='w-full accordionSummary'>
        {HeadingComponent ? (
          <HeadingComponent
            icon={<ArrowForwardIosSharpIcon expandAcoordion isExpanded={expanded.folder} />}
            onClickHeading={() => handleAccordionChange('folder')}
          />
        ) : (
          <List className='w-full' bgflowhover='true' isProjectSlider>
            <ListItemButton
              className='list-item py-2 truncated-text group'
              onClick={(e) => {
                e.stopPropagation()
                handleAccordionChange('folder')
              }}
            >
              <Box className='flex items-center gap-1 w-full'>
                <Box className='flex'>
                  <ListItemIcon>
                    <ArrowForwardIosSharpIcon expandAcoordion isExpanded={expanded.folder} />
                  </ListItemIcon>
                  <ListItemIcon>
                    <DescriptionOutlinedIcon className='!size-5' />
                  </ListItemIcon>
                </Box>
                <ListItemText className='group-hover:pl-1 transition-all duration-500' primary='Folders' />
              </Box>
              <CreateNewProjectButton />
            </ListItemButton>
          </List>
        )}
      </StyledAccordionSummary>
      {children}
    </StyledAccordion>
  )
}

export default FolderListAccordion
