/* eslint-disable no-lonely-if */
import cloneDeep from 'lodash.clonedeep'
import set from 'lodash.set'
import { dryRunFunction } from '../../../api'
import {
  attachAttachmentForPlugin,
  getContextFromInvocation,
  removeGarbageDataFromSelectedValues
} from '../../../components/plugin/pluginUtils/plugin_utility.ts'
import { BlockTypes, sliderTypes } from '../../../enums'
import { SECTIONS } from '../../../pages/developerHub/constants/developerHubConstants.ts'
import { getInfoParamtersFromUrl } from '../../../utils/utilities'
import { updateResultOfSlider } from '../../developerHub/DHActionVersion/dhActionVersionSlice.ts'
import { handleAddStepsV2, setInputDataOfJson, updateTriggerDataInInvocation } from '../../invocationV2/invocationSliceV2.ts'
import { addDryRunSliderData, successDryRun } from '../../stepsV2/stepSliceV3.ts'
import { flattenObject } from './utility.ts'
import { getApiInstanceDataFromLogCode } from '../../stepsV2/stepReducerV3.ts'

export const startDryRunApiStartThunk = (payload) => async (dispatch, getState) => {
  const { sectionIdOrScriptId, versionIdOrStepId, tabName, slugName } = getInfoParamtersFromUrl()
  const { optimizeCode, readCodeFromThisSlug } = payload
  try {
    const state = getState()
    const { currentSelectedPlugin, invocationData, dhSource, steps, blocks, context, invocationContext } = {
      currentSelectedPlugin: state.stepsDataV3?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName || 'draft'],
      invocationData: state?.invocationV2?.[sectionIdOrScriptId]?.invocationData,
      dhSource: optimizeCode
        ? state.DHActionVersion?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.optimizedCode?.[readCodeFromThisSlug || slugName]
        : state.DHActionVersion?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[readCodeFromThisSlug || slugName] || '',
      steps: state.stepsDataV3?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName || 'draft']?.pluginData?.inputJson?.steps,
      blocks: state.stepsDataV3?.[sectionIdOrScriptId]?.[versionIdOrStepId]?.[tabName || 'draft']?.pluginData?.inputJson?.blocks,
      context: state.invocationV2?.[sectionIdOrScriptId].context,
      invocationContext: getContextFromInvocation(state?.invocationV2?.[sectionIdOrScriptId])
    }
    const appInfo = state.appInfo

    const cloneDeepObj = cloneDeep(currentSelectedPlugin.pluginData.selectedValues)
    const newInputData = removeGarbageDataFromSelectedValues(cloneDeepObj.inputData, steps, blocks, invocationContext)

    const isUserOnDh = window.location.pathname.includes(SECTIONS.action) || window.location.pathname.includes(SECTIONS.trigger)
    cloneDeepObj.inputData = newInputData

    let datatosend = {}
    let source

    if (isUserOnDh) {
      source = dhSource
    } else if (appInfo?.currentStepType === sliderTypes.TRIGGER) {
      if (currentSelectedPlugin?.pluginData?.modifyTriggerData) {
        source = `async function performList(){${currentSelectedPlugin?.pluginData?.performList}}\n context.req.body = await performList();\n async function manipulationFunction(){${currentSelectedPlugin?.pluginData?.modifyTriggerData}} return await manipulationFunction()`
      } else {
        source = currentSelectedPlugin?.pluginData?.performList
      }
    } else {
      source = currentSelectedPlugin?.pluginData?.source
    }

    if (isUserOnDh && readCodeFromThisSlug === 'performunsubscribe' && window.location.pathname.includes(SECTIONS.trigger)) {
      cloneDeepObj.inputData = { ...flattenObject(invocationData?.inputData), ...cloneDeepObj.inputData }
    }

    const variables = cloneDeep(context)

    if (currentSelectedPlugin?.customPayload) {
      Object.entries(currentSelectedPlugin?.customPayload)?.forEach(([key, value]) => {
        set(variables, key, value)
      })
    }

    attachAttachmentForPlugin(cloneDeepObj.inputData)
    datatosend = {
      type: appInfo?.currentStepType === BlockTypes.TRIGGER ? BlockTypes.TRIGGER : 'plugin',
      code: {
        source: source,
        selectedValues: cloneDeepObj
      },
      name: 'plugin',
      variables
    }

    const response = await dryRunFunction({
      scriptId: appInfo.currentScriptId || 'devhubPluginPreview',
      data: datatosend
    })
    const triggerData = response?.data?.data?.message
    if (isUserOnDh) {
      dispatch(
        updateResultOfSlider({
          data: { [readCodeFromThisSlug || slugName]: response?.data?.data },
          versionId: versionIdOrStepId,
          sectionId: sectionIdOrScriptId
        })
      )
      dispatch(setInputDataOfJson({ [readCodeFromThisSlug || slugName]: response?.data?.data?.message }))
    } else {
      if (appInfo?.currentStepType === BlockTypes.TRIGGER) {
        dispatch(updateTriggerDataInInvocation(response?.data?.data?.message))
      } else {
        const dataToSend = {
          payload: {
            key: appInfo.currentSlugName,
            value: response?.data?.data?.message,
            console: response?.data?.data?.console
          },
          type: BlockTypes.PLUG
        }
        dispatch(handleAddStepsV2(dataToSend))
      }
    }

    dispatch(successDryRun({}))
    if (!isUserOnDh && appInfo?.currentStepType === sliderTypes.TRIGGER) {
      const dataToUpdate = {
        ...getApiInstanceDataFromLogCode(
          {
            requestType: 'POST',
            body: triggerData,
            headers: {
              // eslint-disable-next-line
              ['content-type']: 'application/json'
            }
          },
          sectionIdOrScriptId
        )
      }
      dispatch(addDryRunSliderData({ dataToUpdate }))
    }
    return 'success'
  } catch (error) {
    dispatch(successDryRun({}))
    return 'error'
  }
}
