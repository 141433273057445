import { Box, Button, Card, Chip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { storeAuthDetailsToRedis } from '../../api/index'
import { errorToast } from '../../components/customToast'
import IconWrapper from '../../components/IconWrapper/IconWrapper.tsx'
import config from '../../config'
import { ALERTTYPES, MiscTypes, ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useAllAuthenticatedPlugins } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import selectActiveUser from '../../store/user/userSelector'
import { sendAlert } from '../../utils/alertUtility.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { generateRandomString, isColorVisible } from '../../utils/utilities'

type AuthenticationsforSpecificplugProps = {
  orgId: string
  selectedService: {
    rowid: string
  }
  projectId: string
  scriptId: string
  skipactionselection?: string
  filteredActions?: any[]
}

/**
 * AuthenticationsforSpecificplug component displays authentications for a specific plugin.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.orgId - The organization ID
 * @param {Object} props.selectedService - The selected service object
 * @param {string} props.selectedService.rowid - The row ID of the selected service
 * @param {string} props.projectId - The project ID
 * @param {string} props.scriptId - The script ID
 *
 * @returns {JSX.Element} The rendered AuthenticationsforSpecificplug component
 *
 * @description
 * This component fetches and displays authentication data for a specific plugin.
 * It allows users to view and manage authentications, open authentication popups,
 * and handles real-time updates through message event listeners.
 */

function AuthenticationsforSpecificplug({
  orgId,
  selectedService,
  projectId,
  scriptId,
  filteredActions = [],
  skipactionselection = '',
  setSearchParams
}: AuthenticationsforSpecificplugProps) {
  const { userData, orgName, mode, embedProjectName } = useCustomSelector((state) => ({
    userData: selectActiveUser(state),
    orgName: state?.orgs?.orgs?.[orgId]?.name,
    mode: state?.appInfo?.mode,
    embedProjectName: state?.projects?.embedProject?.[orgId]?.active?.[projectId]?.title
  }))

  const { data: allAuthData, isLoading } = useAllAuthenticatedPlugins(orgId)
  const [authData, setAuthData] = useState([])

  useEffect(() => {
    if (!isLoading && selectedService?.rowid) setAuthData(allAuthData?.filter((row) => row.service_id === selectedService?.rowid))
  }, [isLoading, selectedService?.rowid])

  function messageEventListener(event) {
    if (event.data.payload) return
    if (event.data.error) {
      errorToast(event?.data?.error)
      return
    }
    if (!event?.data?.id) {
      return
    }
    const objToAdd = event?.data || {}
    objToAdd.service_id = selectedService?.rowid
    objToAdd.user_id = userData?.userId
    objToAdd.updated_at = new Date()
    const newAuthData = [...(Array.isArray(authData) ? authData : []), objToAdd] // Create a new array
    setAuthData(newAuthData)
  }

  const openPopup = (row, update = false) => {
    const toSkipActionPage = skipactionselection === 'true'
    if (toSkipActionPage) setSearchParams({})
    let key
    if (mode === MiscTypes.EMBED_MODE) {
      try {
        key = generateRandomString()
        storeAuthDetailsToRedis({ token: sessionStorage.getItem('ssoEmbedToken') }, key)
      } catch (error) {
        sendAlert(error, ALERTTYPES.FRONTEND, 'storeAuthDetailsToRedis api alert')
      }
    }

    // const popUpWindowUrl = update
    // ? `${process.env.REACT_APP_AUTH_URL}/auth/service/${row.rowid}`
    // : `${process.env.REACT_APP_AUTH_URL}${config.servicePage}`

    const popUpWindowUrl = row?.rowid
      ? `${process.env.REACT_APP_AUTH_URL}/auth/service/${row?.rowid}`
      : `${process.env.REACT_APP_AUTH_URL}${config.servicePage}`
    const params = {
      userid: userData?.userId,
      orgid: orgId,
      orgId: orgId,
      projectid: projectId,
      scriptid: scriptId,
      authidtoupdatetoken: row.id,
      level: mode === MiscTypes.EMBED_MODE ? 'project' : 'org',
      mode,
      _key: key,
      orgOrProjectName: mode === MiscTypes.EMBED_MODE ? embedProjectName : orgName,
      serviceid: row?.rowid,
      authrowid: row?.preferedauthversion,
      isUpdate: update,
      filteredActions: filteredActions,
      skipactionselection: toSkipActionPage
    }

    const queryParams = Object.entries(params)
      .filter(([, value]) => value !== '' && value !== undefined) // Exclude empty strings and undefined values
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
    // const targetUrl = `${popUpWindowUrl}?${queryParams}`

    const targetUrl =
      row?.preferedauthtype === 'Basic'
        ? `${popUpWindowUrl}/auth/${row?.preferedauthversion}?${queryParams}`
        : `${popUpWindowUrl}?${queryParams}`

    const popupWindow = window.open(targetUrl, 'PopupPage', 'height=600,width=800')
    window.addEventListener('message', messageEventListener)

    const checkWindowClosed = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
        window.removeEventListener('message', messageEventListener)
        clearInterval(checkWindowClosed)
      }
    }, 100)
  }

  return (
    <Card
      variant='outlined'
      sx={{
        ...(selectedService?.brandcolor && isColorVisible(selectedService?.brandcolor)
          ? { borderTop: `2px solid ${selectedService?.brandcolor}` }
          : {}),
        maxHeight: '100px !important',
        height: '100% !important'
      }}
      className='service-block pos-rel  flex-spaceBetween-center  w-100'
    >
      <Box className='flex-spaceBetween-center w-100'>
        <Typography className='service-description '>{selectedService?.description}</Typography>
        <Box>
          {authData?.length ? (
            <Chip label={`${authData?.length} ${authData?.length === 1 ? 'Connection' : 'Connections'}`} />
          ) : (
            <Button
              variant='outlined'
              onClick={() => openPopup(selectedService, false)}
              startIcon={<IconWrapper iconUrl={selectedService?.iconurl} size={24} />}
            >
              Connect {selectedService?.name}
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(AuthenticationsforSpecificplug), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    'skipactionselection'
  ])
)
