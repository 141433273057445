import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import addUrlDataHoc from './hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from './enums'
import { useCustomSelector } from './utils/deepCheckSelector'
import WorkspacesComponent from './components/workspaceComponent/WorkspacesComponent.tsx'

function ServicePageRedirector({ serviceId }) {
  const navigate = useNavigate()
  const { orgs } = useCustomSelector((state) => ({
    orgs: state.orgs
  }))
  const orgIdarray = Object.keys(orgs.orgs)
  const currentOrg = orgs.currentOrg || orgIdarray?.[0]
  useEffect(() => {
    if (orgIdarray?.length === 1) navigate(`/projects/${currentOrg}/appsexplore/servicepage/${serviceId}?skipactionselection=true`)
  }, [currentOrg])
  return <WorkspacesComponent />
}
export default React.memo(addUrlDataHoc(React.memo(ServicePageRedirector), [ParamsEnums.serviceId]))
