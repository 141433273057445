import React from 'react'
import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import { closeAiSlider, setShowFullScreen, updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { closeSuggestionBox } from '../../store/chip/allChip/allChip.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from '../../enums'

function CompareSliderButton({ scriptId, stepId, tabName }) {
  const dispatch = useDispatch()

  // Fetching data using useCustomSelector hook
  const { compareSliderOpen, isAiSliderOpen, stepStatus, isBasicFlow } = useCustomSelector((state) => ({
    compareSliderOpen: state.appInfo.compareSliderOpen,
    isAiSliderOpen: state?.appInfo?.isAiSliderOpen,
    stepStatus: state.flowJsonV2[scriptId]?.metaData?.drafted?.versioning?.[stepId]?.status,
    isBasicFlow: state?.flowJsonV2?.[scriptId]?.metaData?.isBasicFlow
  }))

  const handleCompareSlider = () => {
    if (isAiSliderOpen) {
      dispatch(closeAiSlider())
    }
    dispatch(updateAppInfoState({ compareSliderOpen: !compareSliderOpen }))
    dispatch(setShowFullScreen(false))
    dispatch(closeSuggestionBox())
  }

  return (
    !isBasicFlow &&
    stepStatus === 'MODIFY' &&
    !compareSliderOpen &&
    tabName === 'draft' && (
      <Box className='cursor-pointer pr-3' onClick={handleCompareSlider}>
        Compare Old v/s New
      </Box>
    )
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(CompareSliderButton), [ParamsEnums.scriptId, ParamsEnums.stepId, ParamsEnums.tabName]) // Memoized component
)
