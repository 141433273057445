import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined'
import { Box, Chip, Divider, Stack, Typography, useTheme } from '@mui/material'
import React from 'react'
import isColorLight, { isJSONString } from './utility.ts'
import './Message.scss'

function ResetHistoryLine() {
  return (
    <Divider className='mb-2'>
      <Chip label='History cleared' size='small' color='error' />
    </Divider>
  )
}

const UserMessageCard = React.memo(({ message, textColor }: any) => {
  return (
    <>
      <Box
        sx={{
          padding: '10px 0px 0px 0px',
          boxSizing: 'border-box',
          height: 'fit-content',
          minWidth: '150px',
          wordBreak: 'break-word',
          whiteSpace: 'normal',
          overflowWrap: 'break-word'
        }}
        // variant='elevation'
      >
        <Typography
          fontWeight='500'
          sx={{
            color: textColor,
            whiteSpace: 'pre-wrap'
          }}
        >
          {message?.content}
        </Typography>
      </Box>
      {message?.is_reset && <ResetHistoryLine />}
    </>
  )
})

const AssistantMessageCard = React.memo(({ message, isError = false, handleFeedback = () => {}, componentJson = {} }: any) => {
  return (
    <Box className='assistant_message_card'>
      <Stack
        sx={{
          alignItems: 'flex-end',
          gap: '10px',
          maxWidth: '90%',
          '@media(max-width:479px)': {
            height: 'fit-content',
            columnGap: '5px'
          },
          marginBottom: '10px'
        }}
        direction='row'
      >
        <Box
          sx={{
            padding: 0,
            boxSizing: 'border-box',
            height: 'fit-content',
            minWidth: '150px',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            maxWidth: '100%',
            color: 'black',
            backgroundColor: 'transparent',
            whiteSpace: 'pre-wrap'
          }}
        >
          {message?.wait ? (
            <Box className='flex-start-center w-100 gap-3' sx={{ padding: '10px' }}>
              <div className='loader' />
              <Typography variant='body1'>{message?.content}</Typography>
            </Box>
          ) : message?.timeOut ? (
            <Box className='flex-start-center w-100 gap-5 p-1'>
              <Typography variant='body1'>Timeout reached. Please try again later.</Typography>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px', alignItems: 'flex-start' }}>
              {(() => {
                const parsedContent = isJSONString(isError ? message?.error : message?.chatbot_message || message?.content)
                  ? JSON.parse(isError ? message.error : message?.chatbot_message || message?.content)
                  : null
                if (parsedContent?.response && Array.isArray(parsedContent?.response))
                  return parsedContent?.response?.map((res) => {
                    const rendererFunction = componentJson?.[res.type] || (() => null)
                    return rendererFunction({ props: res, fullData: parsedContent })
                  })

                return !isError ? message?.chatbot_message || message?.content : message.error
              })()}
            </Box>
          )}
        </Box>
      </Stack>

      {!message?.wait && !message?.timeOut && !message?.error && (
        <Box className='icon-box flex flex-row  gap-1  hover-and-see'>
          {message?.message_id && (
            <>
              {message?.user_feedback === 1 ? (
                <ThumbUpIcon
                  fontSize='inherit'
                  sx={{
                    fontSize: '16px',
                    color: 'green'
                  }}
                  onClick={() => handleFeedback(message?.message_id, 1, message?.user_feedback)}
                  className='cursor-pointer'
                />
              ) : (
                <ThumbUpAltOutlinedIcon
                  fontSize='inherit'
                  sx={{
                    '&:hover': { color: 'green' },
                    fontSize: '16px',
                    color: 'inherit'
                  }}
                  onClick={() => handleFeedback(message?.message_id, 1, message?.user_feedback)}
                  className='cursor-pointer'
                />
              )}
              {message?.user_feedback === 2 ? (
                <ThumbDownIcon
                  fontSize='inherit'
                  sx={{
                    color: 'red',
                    fontSize: '16px'
                  }}
                  onClick={() => handleFeedback(message?.message_id, 2, message?.user_feedback)}
                  className='cursor-pointer'
                />
              ) : (
                <ThumbDownOffAltOutlinedIcon
                  fontSize='inherit'
                  sx={{ '&:hover': { color: 'red' }, fontSize: '16px' }}
                  onClick={() => handleFeedback(message?.message_id, 2, message?.user_feedback)}
                  className='cursor-pointer'
                />
              )}
            </>
          )}
        </Box>
      )}
      {message?.is_reset && <ResetHistoryLine />}
    </Box>
  )
})

function Message({ message, handleFeedback, addMessage, componentJson }: any) {
  const theme = useTheme()
  const backgroundColor = theme.palette.primary.main
  const textColor = isColorLight(backgroundColor) ? 'black' : 'white'

  return (
    <Box className='w-100'>
      {message?.role === 'user' ? (
        <>
          <UserMessageCard message={message} theme={theme} textColor={textColor} />
          {message?.error && (
            <AssistantMessageCard
              message={message}
              isError
              theme={theme}
              componentJson={componentJson}
              textColor={textColor}
              handleFeedback={handleFeedback}
              addMessage={addMessage}
            />
          )}
        </>
      ) : message?.role === 'assistant' ? (
        <AssistantMessageCard
          message={message}
          theme={theme}
          componentJson={componentJson}
          textColor={textColor}
          handleFeedback={handleFeedback}
          addMessage={addMessage}
        />
      ) : message?.role === 'reset' ? (
        <ResetHistoryLine />
      ) : null}
    </Box>
  )
}

export default React.memo(Message)

UserMessageCard.displayName = UserMessageCard
AssistantMessageCard.displayName = AssistantMessageCard
