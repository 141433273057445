import React from 'react'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import ScriptListV3 from '../scriptListV3.tsx'
import './scriptSliderBody.scss'

// function ScriptSliderBodyV3({ orgId, projectId }) {
//   const { projectDetails, isProjectSliderOpen } = useCustomSelector((state) => {
//     return {
//       projectDetails: getProjectById(state, projectId, orgId),
//       isProjectSliderOpen: state.appInfo.isProjectSliderOpen
//     }
//   })
//   const dispatch = useDispatch()
//   const handleProjectTitleClick = () => {
//     if (!isProjectSliderOpen) dispatch(toggleProjectSlider(true))
//   }
//   return (
//     <Box className='slider__flow h-100 flex-col gap-2 p-3 border-r-sharp'>
//       <Box className='flex-start-center gap-2 px-1'>
//         <FolderOutlinedIcon />
//         <Typography variant='h7' onClick={handleProjectTitleClick} className='text-overflow-eclipse captilize-texttransform '>
//           {projectDetails?.title || ''}
//         </Typography>
//       </Box>
//       {/* Redering all the Active and Paused functions of a folder in a sliderbar */}
//       <Box className='transition slider__maincotainer column gap-3 w-100 h-100'>
//         <ScriptListV3 />
//       </Box>
//     </Box>
//   )
// }

function ScriptSliderBodyV3() {
  return <ScriptListV3 />
}
export default React.memo(addUrlDataHoc(React.memo(ScriptSliderBodyV3), []))
