import { Box } from '@mui/material'

import './consoleTab.css'
import Tree from '../../workFlow/Tree'

export default function ConsoleTab({ console, className = 'consoleTabMainContainer' }) {
  return (
    <Box className={`overflow-scroll bg-white ${className}`}>
      <Tree data={console} parentName='Console' disablecopypath />
    </Box>
  )
}
