import React from 'react'
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import AlarmOnSharpIcon from '@mui/icons-material/AlarmOnSharp'
import WebhookIcon from '@mui/icons-material/Webhook'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, Tabnames } from '../../enums'
import config from '../../config'
import './scriptListV2.scss'
import EditPencil from '../Icons/EditPencil.tsx'
import TriggerErrorTooltipIcon from './triggerErrorComponent.tsx'

export const StyledAccordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(() => ({
  // border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}))

export const StyledAccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(() => ({
  backgroundColor: 'rgba(255, 255, 255, .05)',
  flexDirection: 'row-reverse',
  minHeight: 'initial',
  margin: '0 !important',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '10px'
  }
}))

export const StyledAccordionDetails = styled(MuiAccordionDetails)(() => ({
  border: 'none',
  padding: 0
}))

function ScriptListItemComponent({ projectId, orgId, id, ...props }) {
  const navigate = useNavigate()
  const { allScripts, scriptObject, defaultProjectId } = props
  const finalProjectId = defaultProjectId || projectId
  const scriptObjectKeys = Object?.keys(scriptObject || {}) || []
  // console.log(Object.values(scriptObject).length)
  const appIntegrationKeys = Object?.keys(scriptObject?.appIntegration || {}) || []
  const allKeys = [...scriptObjectKeys, ...appIntegrationKeys]

  const [expanded, setExpanded] = React.useState(allKeys)
  const renderflows = (head, value) => {
    return (
      <div>
        {value &&
          Object.entries(value || {})?.map(
            ([key, value]) =>
              key === 'active' && (
                <div key={key}>
                  {head !== 'appIntegration' && value.length > 0 && (
                    <div>
                      {/* Sort the array alphabetically by script title */}
                      {value
                        .map((scriptId) => ({
                          scriptId,
                          title: allScripts?.[scriptId]?.title || '[Untitled]',
                          hasDraftedData: Boolean(Object.entries(allScripts?.[scriptId]?.metadata?.drafted?.versioning || {})?.length)
                        }))
                        .sort((a, b) => {
                          if (a.title === '[Untitled]') return 1
                          if (b.title === '[Untitled]') return -1
                          return a.title.localeCompare(b.title)
                        })
                        .map(({ scriptId, title, hasDraftedData }) => (
                          <List key={scriptId} bgflowhover='true'>
                            <ListItem
                              disablePadding
                              active={props.scriptId === scriptId}
                              onClick={() => {
                                navigate(
                                  `${config.projectsBaseUrl}/${orgId}/${finalProjectId}/service/${head}${config.workflowBaseUrl}/${scriptId}/${Tabnames.DRAFT}`
                                )
                              }}
                            >
                              <ListItemButton className='list-item p-1 truncated-text pl-4 group'>
                                <ListItemText
                                  className='pl-2 group-hover:!pl-3 transition-all duration-500'
                                  sx={{ fontSize: '16px' }}
                                  primary={title}
                                />
                                <TriggerErrorTooltipIcon scriptId={scriptId} />
                                {hasDraftedData && <EditPencil />}
                              </ListItemButton>
                            </ListItem>
                          </List>
                        ))}
                    </div>
                  )}
                </div>
              )
          )}
      </div>
    )
  }

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      if (isExpanded) {
        return [...prevExpanded, panel] // Add panel to expanded array if it's being expanded
      }
      return prevExpanded.filter((item) => item !== panel) // Remove panel from expanded array if it's being collapsed
    })
  }
  const handleIconClick = (panel) => (event) => {
    event.stopPropagation()
    const isExpanded = expanded.includes(panel)
    handleAccordionChange(panel)(event, !isExpanded)
  }

  return (
    <Box>
      {scriptObject &&
        Object?.entries(scriptObject || {})?.map(
          ([key, value]) =>
            key !== 'appIntegration' &&
            (value?.active?.length > 0 || value?.pause?.length > 0 || value?.delete?.length > 0) && (
              <StyledAccordion key={key} expanded={expanded.includes(key)}>
                <StyledAccordionSummary
                  aria-controls={`panel-${key}-content`}
                  id={`panel-${key}-header`}
                  styledSummery
                  className='w-100 accordionSummary'
                  onClick={() => {
                    navigate(
                      key === 'webhook'
                        ? `${config.projectsBaseUrl}/${orgId}/${finalProjectId}/appsexplore/servicepage/${key}`
                        : `${config.projectsBaseUrl}/${orgId}/${finalProjectId}/service/${key}`
                    )
                  }}
                >
                  {/* </Button> */}
                  <List key={key} className='w-100 ' bgflowhover='true' isProjectSlider>
                    <ListItem
                      active={(props.serviceId === key || props?.firstServiceId === key) && projectId === id && !props.scriptId}
                      disablePadding
                    >
                      <ListItemButton className='list-item py-2 truncated-text group'>
                        <Box className='flex items-center gap-1 w-full'>
                          <Box className='flex'>
                            <ListItemIcon>
                              <ArrowForwardIosSharpIcon
                                expandAcoordion
                                isExpanded={expanded.includes(key)}
                                onClick={handleIconClick(key)}
                              />
                            </ListItemIcon>
                            <ListItemIcon>
                              {key === 'cron' ? (
                                <AlarmOnSharpIcon variant='customSize' />
                              ) : key === 'webhook' ? (
                                <WebhookIcon variant='customSize' />
                              ) : key === 'email' ? (
                                <AlternateEmailIcon variant='customSize' />
                              ) : (
                                <AdjustOutlinedIcon variant='customSize' />
                              )}
                            </ListItemIcon>
                          </Box>
                          <ListItemText
                            className='group-hover:pl-1 transition-all duration-500'
                            primary={key?.charAt(0).toUpperCase() + key?.slice(1)}
                          />
                        </Box>
                        {value?.active?.length > 0 && (
                          <Typography className='opacity-40 group-hover:opacity-100'>{value?.active?.length}</Typography>
                        )}
                      </ListItemButton>
                    </ListItem>
                  </List>
                </StyledAccordionSummary>
                <StyledAccordionDetails className='w-100 '>{renderflows(key, value)}</StyledAccordionDetails>
              </StyledAccordion>
            )
        )}
      {scriptObject && scriptObject?.appIntegration && (
        <>
          {Object.entries(scriptObject.appIntegration || {}).map(
            ([key, value]) =>
              value?.usedAsTrigger &&
              (value?.usedAsTrigger?.active?.length > 0 ||
                value?.usedAsTrigger?.pause?.length > 0 ||
                value?.usedAsTrigger?.delete?.length > 0) && (
                <StyledAccordion key={`${key}-accordion`} expanded={expanded.includes(key)}>
                  <StyledAccordionSummary
                    aria-controls={`panel-${key}-content`}
                    id={`panel-${key}-header`}
                    styledSummery
                    className='w-100 accordionSummary'
                    onClick={() => {
                      navigate(`${config.projectsBaseUrl}/${orgId}/${finalProjectId}/appsexplore/servicepage/${key}`)
                    }}
                  >
                    <List key={key} className='w-100' bgflowhover='true' isProjectSlider>
                      <ListItem active={props.serviceId === key && projectId === id && !props.scriptId} disablePadding>
                        <ListItemButton className='list-item py-2 truncated-text group MuiGroupHover-root'>
                          <Box className='flex items-center gap-1 w-full'>
                            <Box className='flex'>
                              <ListItemIcon>
                                <ArrowForwardIosSharpIcon
                                  expandAcoordion
                                  isExpanded={expanded.includes(key)}
                                  onClick={handleIconClick(key)}
                                />
                              </ListItemIcon>
                              <ListItemIcon sx={{ width: '20px', height: 'auto' }}>
                                <img src={scriptObject?.appIntegration?.[key]?.iconUrl} alt='' width='18px' />
                              </ListItemIcon>
                            </Box>
                            {/* <ListItemText primary={key?.charAt(0).toUpperCase() + key?.slice(1)} /> */}
                            <ListItemText
                              className='captilize-texttransform group-hover:pl-1 transition-all duration-500'
                              variant='fontOne'
                              primary={scriptObject?.appIntegration?.[key]?.serviceName}
                            />
                          </Box>
                          {value?.usedAsTrigger?.active?.length > 0 && (
                            <Typography className='opacity-40 group-hover:opacity-100'>{value?.usedAsTrigger?.active?.length}</Typography>
                          )}
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </StyledAccordionSummary>
                  <StyledAccordionDetails className='w-100'>{renderflows(key, value.usedAsTrigger)}</StyledAccordionDetails>
                </StyledAccordion>
              )
          )}
        </>
      )}
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(ScriptListItemComponent), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.tabName,
    ParamsEnums.serviceId,
    ParamsEnums.firstServiceId
  ])
)
