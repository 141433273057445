import React, { useState, useEffect } from 'react'
import { Box, Switch } from '@mui/material'
import { useDispatch } from 'react-redux'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { BlockTypes, ParamsEnums, STEP_OPERATION_STATUS } from '../../enums'
import { createOrUpdateFunctionThunk } from '../../store/stepsV2/stepThunkV2'

function SwitchSteps({ slugName, scriptId }: { slugName: string; scriptId: string }) {
  const { flowJsonBlock } = useCustomSelector((state: $ReduxCoreType) => ({
    flowJsonBlock: state?.flowJsonV2?.[scriptId]?.flowJson?.blocks?.[slugName]
  }))

  const [active, setActive] = useState(true)
  const [disabled, setDisiabled] = useState(false)
  const dispatch = useDispatch()

  const handleSwitchChange = async (e) => {
    // optimization: @author Akshat
    setActive(e.target.checked)
    setDisiabled(true)
    await dispatch(
      createOrUpdateFunctionThunk({
        data: {
          status: e.target.checked ? STEP_OPERATION_STATUS.ACTIVE : STEP_OPERATION_STATUS.INACTIVE,
          type: flowJsonBlock?.type,
          title: slugName
        }
      })
    )
    setDisiabled(false)
  }

  useEffect(() => {
    if (!slugName) return
    setActive(flowJsonBlock?.status === STEP_OPERATION_STATUS.ACTIVE)
  }, [slugName, flowJsonBlock?.status])

  return (
    <Box>
      {slugName &&
        flowJsonBlock?.status !== STEP_OPERATION_STATUS.DRAFTED &&
        flowJsonBlock?.status !== STEP_OPERATION_STATUS.DELETE &&
        [BlockTypes.API, BlockTypes.FUNCTION, BlockTypes.PLUG].includes(flowJsonBlock?.type) && (
          <Switch
            size='small'
            checked={active}
            disabled={disabled}
            onClick={(e) => {
              handleSwitchChange(e)
            }}
            color='success'
          />
        )}
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(SwitchSteps), [ParamsEnums.slugName, ParamsEnums.scriptId]))
