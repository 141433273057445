import { Add } from '@mui/icons-material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import CreateNewFlowModal from '../createNewFlowModal/CreateNewFlowModal.tsx'
import CustomModal from '../customModal/customModal'
import './scriptListV2.scss'

interface CreateNewPopoverProps {
  projectId?: string
  defaultProjectId?: string
  isButton?: boolean
  toolTipText?: string
}

/**
 * CreateNewPopover component for creating new items in a project dashboard.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.projectId - The ID of the current project.
 * @param {string} props.defaultProjectId - The default project ID to use if projectId is not provided.
 * @returns {JSX.Element} The rendered CreateNewPopover component.
 */

function CreateNewPopover({ projectId, defaultProjectId, isButton = true, toolTipText = '' }: CreateNewPopoverProps) {
  const dispatch = useDispatch()
  const { modalType } = useCustomSelector((state) => ({
    modalType: state.appInfo.modalType
  }))
  const finalProjectId = defaultProjectId || projectId
  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(updateAppInfoState({ modalType: `create_new_flow_${finalProjectId}` }))
  }
  return (
    <Box key={finalProjectId}>
      {isButton ? (
        <Box className='mx-2'>
          <Button variant='contained' createPopOverButton onClick={(e) => handleClick(e)}>
            Create New Flow
            <AddOutlinedIcon fontSize='small' />
          </Button>
        </Box>
      ) : (
        <Tooltip title={toolTipText} placement='top'>
          <IconButton variant='outlined' onClick={(e) => handleClick(e)}>
            <Add variant='customSize' folderBtn className='group-hover:text-black' />
          </IconButton>
        </Tooltip>
      )}
      <Box
        onClick={(e) => {
          e.stopPropagation() // Prevent the click from propagating to the parent
          e.preventDefault() // Prevent the default action
        }}
      >
        <CustomModal openModal={modalType === `create_new_flow_${finalProjectId}`} width='1100px'>
          {/* <AppsExplore finalProjectId={finalProjectId} /> */}
          <CreateNewFlowModal finalProjectId={finalProjectId} />
          {/* <CreateNewFlowModal finalProjectId={finalProjectId} /> */}
        </CustomModal>
      </Box>
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(CreateNewPopover), [ParamsEnums.orgId, ParamsEnums.projectId]))
