import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Chip } from '@mui/material'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { $ReduxCoreType } from '../../types/reduxCore.ts'

function TriggerErrorTooltipIcon({ scriptId, showChip }: { scriptId: string; showChip: boolean }) {
  const { scriptData } = useCustomSelector((state: $ReduxCoreType) => ({
    scriptData: state?.scripts?.scripts[scriptId]
  }))

  const isAuthDeleted =
    scriptData?.published_json_script?.trigger?.removedAuth === true &&
    scriptData?.published_json_script?.trigger?.selectedValues?.authData?.id ===
      scriptData?.json_script?.trigger?.selectedValues?.authData?.id
  return (
    isAuthDeleted && (
      <Tooltip title='Trigger Paused due to Connection deleted '>
        {showChip ? (
          <Chip color='error' label='Expired' icon={<ErrorOutlineIcon />} />
        ) : (
          <ErrorOutlineIcon color='error' sx={{ fontSize: '18px !important', color: '#f2786d !important' }} />
        )}
      </Tooltip>
    )
  )
}

export default TriggerErrorTooltipIcon
