import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import './localNotification.scss'
import { cancelNotification } from '../../store/notification/notificationSlice'

interface LocalNotificationProps {
  address: string
  type: string
  message?: string
  funcButtonText?: string
  handleClickFunction?: () => void
  ignoreTime?: boolean
  parentClass?: string
  optionalComponent: any
}

function LocalNotification({
  optionalComponent,
  ignoreTime,
  address,
  type,
  message,
  funcButtonText,
  handleClickFunction = () => {},
  parentClass
}: LocalNotificationProps) {
  const isShowNotification = useSelector((state) => state.notification.localNotification[address])
  const dispatch = useDispatch()

  useEffect(() => {
    if (isShowNotification && !ignoreTime) {
      const timeoutId = setTimeout(() => {
        dispatch(cancelNotification(address))
      }, 2000)
      return () => {
        clearTimeout(timeoutId)
      }
    }
    return undefined
  }, [isShowNotification])

  const handleClose = () => {
    dispatch(cancelNotification(address))
  }

  const getNotificationDesign = () => {
    if (type === 'Error') {
      return { icon: <ErrorIcon />, iconColor: 'red', textColor: 'red', buttonColor: 'white', closeColor: 'red', showCloseButton: true }
    }
    if (type === 'Success') {
      return {
        icon: <CheckCircleIcon style={{ color: 'white' }} />,
        iconColor: 'white',
        textColor: 'white',
        buttonColor: 'white',
        showCloseButton: false
      }
    }
    if (type === 'AI') {
      return {
        icon: <AutoAwesomeOutlinedIcon style={{ color: 'white' }} sx={{ fontSize: 80 }} />,
        iconColor: 'white',
        textColor: 'white',
        buttonColor: 'white'
      }
    }
    return { icon: null, iconColor: 'white', textColor: 'black', buttonColor: 'black', closeColor: 'black' }
  }
  if (type === 'AI') {
    message = isShowNotification?.message
  }
  const { icon, iconColor, textColor, buttonColor, closeColor, showCloseButton } = getNotificationDesign()
  if (isShowNotification?.show) {
    return optionalComponent ? (
      optionalComponent()
    ) : (
      <Box
        className={
          parentClass ||
          `flex justify-between items-center ${
            type === 'AI' ? 'info-color' : type === 'Error' ? 'error-notification-design' : 'bg-green'
          } my-2 p-2`
        }
      >
        <Box className='flex items-center'>
          {icon && React.cloneElement(icon, { sx: { color: iconColor, padding: '5px' } })}
          <Typography color={textColor}>{message}</Typography>
        </Box>
        {funcButtonText && (
          <Box className='flex items-center gap-3'>
            <Button variant='contained' onClick={handleClickFunction} className={`bg-${buttonColor}`} color={buttonColor}>
              {funcButtonText}
            </Button>
          </Box>
        )}
        {showCloseButton && <CloseIcon sx={{ color: closeColor, cursor: 'pointer' }} onClick={handleClose} />}
      </Box>
    )
  }
}

LocalNotification.defaultProps = {
  funcButtonText: '',
  ignoreTime: false,
  handleClickFunction: () => {}
}

export default LocalNotification
