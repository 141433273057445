import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Chip, Skeleton, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined'

import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from '../../enums'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { getActionAndTrigger, useFetchPlugins } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { getProjectById } from '../../store/projects/projectsSelector'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import '../../scss/utilities/_flex.scss'
import '../../scss/utilities/_margin.scss'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'

interface BreadcrumbsProps {
  orgId: string
  serviceId: string
  eventId: string
  scriptId: string
  projectId: string
  interfaceId: string
  pluginName: string
  visibilityStatus: string
  sectionName: string
  developer: boolean
  firstServiceId: string
  isEmbedUrl: boolean
}

/**
 * Sets the document title.
 * @param {string} newTitle - The new title to set.
 */
function setTabName(newTitle: string) {
  document.title = newTitle
}

/**
 * Breadcrumbs component to display navigation breadcrumbs.
 * @param {BreadcrumbsProps} props - The properties for the component.
 * @returns {JSX.Element} The Breadcrumbs component.
 */
function Breadcrumbs({
  orgId,
  serviceId,
  eventId,
  scriptId,
  projectId,
  interfaceId,
  pluginName,
  visibilityStatus,
  sectionName,
  developer,
  firstServiceId,
  isEmbedUrl,
  setSearchParams
}: BreadcrumbsProps): JSX.Element {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { script, orgIconUrl, projectName, isProjectSliderOpen, interfaceTitle, orgName } = useCustomSelector((state) => ({
    script: state.scripts.scripts[scriptId],
    orgIconUrl: state?.orgs?.orgs?.[orgId]?.meta?.iconUrl || '',
    orgName: state?.orgs?.orgs?.[orgId]?.name || '',
    projectName: getProjectById(state, projectId, orgId)?.title,
    isProjectSliderOpen: state?.appInfo?.isProjectSliderOpen,
    interfaceTitle: state.Interface?.interfaceData?.[interfaceId]?.title
  }))
  const { data: enabledPlugins, isLoading: serviceLoading } = useFetchPlugins(orgId, [], [firstServiceId, serviceId])

  const filter = eventId ? { rowid: [eventId] } : {}
  const { data: actionData, isLoading: actionLoading } = getActionAndTrigger(filter)
  const isLoading = actionLoading || serviceLoading
  const [openHoverSlider, setOpenHoverSlider] = useState(false)

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (event.clientX <= window.innerWidth * 0.15) setOpenHoverSlider(true)
      else setOpenHoverSlider(false)
    }
    window.addEventListener('mousemove', handleMouseMove)
    return () => window.removeEventListener('mousemove', handleMouseMove)
  }, [])

  const eventName = eventId ? actionData?.[0]?.name : false

  /**
   * Updates the document title based on various conditions.
   */
  function updateTabName() {
    if (scriptId)
      setTabName(
        script?.title || `${script?.json_script?.trigger?.actionName || script?.json_script?.trigger?.triggerType || 'Blank'} flow`
      )
    else if (pluginName) setTabName(pluginName)
    else if (enabledPlugins?.[serviceId]?.name) setTabName(enabledPlugins?.[serviceId]?.name)
    else if (enabledPlugins?.[firstServiceId]?.name) setTabName(enabledPlugins?.[firstServiceId]?.name)
    else if (eventName) setTabName(eventName)
    else if (interfaceTitle) setTabName(interfaceTitle)
    else if (projectName) setTabName(projectName)
    else setTabName('SocketFlow')
  }

  useEffect(() => {
    updateTabName()
  }, [
    scriptId,
    enabledPlugins?.[serviceId]?.name,
    enabledPlugins?.[firstServiceId]?.name,
    eventName,
    interfaceTitle,
    projectName,
    pluginName
  ])

  useEffect(() => {
    return () => {
      document.title = 'ViaSocket'
    }
  }, [])
  const breadcrumbs = [
    {
      label: 'Available Integrations',
      condition: isEmbedUrl,
      onClick: () => navigate(`/integrations/embed/${orgId}/${projectId}`)
    },
    {
      label: orgIconUrl ? (
        <IconWrapper iconUrl={orgIconUrl} size={18} />
      ) : (
        <Chip size='small' sx={{ fontWeight: '600', height: '18px', color: 'white' }} label={orgName?.slice(0, 2).toUpperCase()} />
      ),
      condition: !isEmbedUrl && orgName,
      onClick: () => dispatch(updateAppInfoState({ isProjectSliderOpen: true }))
    },
    {
      label: <Typography className='cursor-pointer'>{projectName}</Typography>,
      condition: !isEmbedUrl && projectName,
      onClick: () => dispatch(updateAppInfoState({ isProjectSliderOpen: true }))
    },
    {
      label: <Typography className='cursor-pointer'>My all Apps</Typography>,
      condition: window.location.pathname.includes('appsexplore') && !projectId,
      onClick: () => {
        navigate(`/projects/${orgId}/appsexplore/servicepage`)
      }
    },
    {
      label: 'Plugin Builder',
      condition: developer,
      onClick: () => navigate(`/developer/${orgId}`)
    },
    {
      label: (
        <Box className='cursor-pointer flex justify-center items-center gap-1'>
          <Typography>{pluginName}</Typography>
        </Box>
      ),
      condition: pluginName,
      onClick: () => {}
    },
    {
      label: sectionName,
      condition: sectionName,
      onClick: () => {}
    },
    {
      label: visibilityStatus,
      condition: visibilityStatus,
      onClick: () => {}
    },
    {
      label: (
        <Box className='flex justify-start items-center gap-1'>
          {isLoading ? (
            <Skeleton className='w-20' />
          ) : (
            <Typography className='header-iframe-title cursor-pointer flex justify-start items-center gap-1 '>
              {typeof enabledPlugins?.[firstServiceId] === 'object' ? enabledPlugins?.[firstServiceId]?.name || '' : firstServiceId}
            </Typography>
          )}
        </Box>
      ),
      condition: firstServiceId && !isEmbedUrl,
      onClick: () => {
        navigate(`/projects/${orgId}${projectId ? `/${projectId}` : ''}/appsexplore/servicepage/${firstServiceId}`)
      }
    },
    {
      label: (
        <Typography
          className='cursor-pointer no-wrap flex gap-2 '
          onClick={() => navigate(`/integrations/embed/${orgId}/${projectId}/event/${eventId}`)}
        >
          {isLoading ? <Skeleton /> : eventName}
        </Typography>
      ),
      condition: (isLoading || eventName) && eventId && !['api', 'function', 'webhook'].includes(eventId),
      onClick: () =>
        navigate(
          isEmbedUrl
            ? `/integrations/embed/${orgId}/${projectId}/event/${eventId}`
            : `/projects/${orgId}${projectId ? `/${projectId}` : ''}/appsexplore/servicepage/${firstServiceId || serviceId}/${eventId}`
        )
    },
    {
      label: (
        <Box className='flex justify-start items-center gap-1'>
          {isLoading ? (
            <Skeleton className='w-20' />
          ) : (
            <Typography className='header-iframe-title cursor-pointer flex justify-start items-center gap-1 '>
              {typeof enabledPlugins?.[serviceId] === 'object' ? enabledPlugins?.[serviceId]?.name || '' : serviceId}
            </Typography>
          )}
        </Box>
      ),
      condition: serviceId,
      onClick: () => {
        if (isEmbedUrl) navigate(`/integrations/embed/${orgId}/${projectId}/${eventId ? `event/${eventId}/` : ''}service/${serviceId}`)
        else if (['cron', 'email', 'others'].includes(serviceId))
          navigate(`/projects/${orgId}${projectId ? `/${projectId}` : ''}/service/${serviceId}`)
        else navigate(`/projects/${orgId}${projectId ? `/${projectId}` : ''}/appsexplore/servicepage/${serviceId}`)
      }
    },
    {
      label: script?.title || `${script?.json_script?.trigger?.actionName || script?.json_script?.trigger?.triggerType || 'Blank'} flow`,
      condition: scriptId,
      onClick: () => dispatch(updateAppInfoState({ isProjectSliderOpen: true }))
    },
    {
      label: interfaceTitle,
      condition: interfaceId,
      onClick: () => dispatch(updateAppInfoState({ isProjectSliderOpen: true }))
    }
  ]
  return (
    <Box className='flex justify-start items-center gap-1 p-2 my-3 w-full header-iframe-preview-section no-wrap '>
      {!isProjectSliderOpen && window.parent === window && (scriptId || interfaceId) && (
        <Box
          className='flex justify-center items-center'
          onClick={() => {
            setSearchParams({})
            dispatch(updateAppInfoState({ isProjectSliderOpen: true, isAiSliderOpen: false }))
          }}
        >
          {openHoverSlider ? (
            <KeyboardDoubleArrowRightOutlinedIcon className='cursor-pointer' fontSize='small' />
          ) : (
            <MenuRoundedIcon className='cursor-pointer' fontSize='small' />
          )}
        </Box>
      )}
      {breadcrumbs
        .filter((b) => b.condition)
        .map((breadcrumb, index) => (
          // eslint-disable-next-line
          <Box key={index} className='flex justify-center items-center gap-1'>
            <Box onClick={breadcrumb.onClick} className='flex justify-center items-center cursor-pointer' color='black'>
              {breadcrumb.label}
            </Box>
            {index < breadcrumbs.filter((b) => b.condition).length - 1 && <Typography className='opacity-50'>/</Typography>}
          </Box>
        ))}
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(Breadcrumbs), [
    ParamsEnums.orgId,
    ParamsEnums.serviceId,
    ParamsEnums.eventId,
    ParamsEnums.scriptId,
    ParamsEnums.projectId,
    ParamsEnums.interfaceId,
    ParamsEnums.firstServiceId,
    ParamsEnums.isEmbedUrl
  ])
)
