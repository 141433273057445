import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { ListItem, ListItemButton, ListItemText, Skeleton, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import React, { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ParamsEnums } from '../../../enums'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import CreateOrgModal from '../../../pages/formbilling/createOrgModal.tsx'
import { openModalRedux, setIsScriptDrawerOpen, updateAppInfoState } from '../../../store/appInfo/appInfoSlice.ts' // v2 change by chanchal
import { changeProjectStatus, updateProjects } from '../../../store/projects/projectsThunk'
import { ProjectsInfoType } from '../../../types/Projects.ts'
import { $ReduxCoreType } from '../../../types/reduxCore.ts'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import CustomModal from '../../customModal/customModal'
import { errorToast } from '../../customToast'
import DuplicateFlowModal from '../../functiondashboard/workFlow/duplicateFlowModal/duplicateFlowModal.tsx'
import FunctionsActionsButton from '../../functiondashboard/workFlow/workFlowComponent/functionsActionsButton/functionsActionsButton'
import CreateNewPopover from '../createNewPopover.tsx'
import ScriptListItemComponent from '../ScriptListItemComponent.tsx'
import FolderList from './FolderList.tsx'
import FolderListAccordion from './FolderListAccordion.tsx'
import './projectList.scss'

function Projectlist(props: any) {
  const { orgId, projectId: currentProjectId } = props
  const dispatch = useDispatch()
  const projectRef = useRef()
  const navigate = useNavigate()
  const [newTitle, setNewTitle] = useState({ title: '', error: false })
  const { allProjects, isProjectListLoading, isModalOpen, modalType, scriptObject, allScripts, projectWiseFlows } = useCustomSelector(
    (state: $ReduxCoreType) => ({
      allProjects: state.projects?.projects?.[orgId],
      isProjectListLoading: state?.projects?.isLoading,
      isModalOpen: state.appInfo.isModalOpen,
      modalType: state.appInfo.modalType,
      scriptObject: state?.scripts?.projects?.[`proj${orgId}`],
      allScripts: state?.scripts?.scripts,
      projectWiseFlows: state?.scripts?.projects
    })
  )
  const activePauseProject = allProjects ? Object.values(allProjects['active'] || []).sort((a, b) => a?.title?.localeCompare(b.title)) : []
  const deleteProject = allProjects ? Object.values(allProjects['deleted'] || []).sort((a, b) => a?.title?.localeCompare(b.title)) : []
  const [projectId, setProjectId] = useState('')

  const isProjectNameExists = (projectName: string) => {
    return (
      activePauseProject.find((project: ProjectsInfoType) => project.title === projectName) ||
      deleteProject.find((project: ProjectsInfoType) => project.title === projectName)
    )
  }

  const renameProject = async (title, id) => {
    const dataTosend = {
      title: newTitle.title || title,
      project_id: id,
      org_id: orgId
    }
    dispatch(updateProjects({ id, dataTosend }))
    setProjectId('')
    return null
  }
  const handleActionBtns = async (option, projectInfo) => {
    switch (option) {
      case 'Rename':
        setProjectId(projectInfo?.project?.id)
        break
      case 'Move To Trash':
        await changeStatus(projectInfo.project, '0')
        break
      case 'Pause':
        await changeStatus(projectInfo.project, '2')
        break
      case 'Active':
        await changeStatus(projectInfo.project, '1')
        break
      case 'Move':
        projectRef.current = { projectId: projectInfo.project.id, status: projectInfo.project.status }
        dispatch(openModalRedux({ modalType: 'MoveProject' }))
        break
      default:
        break
    }
  }
  const changeStatus = async (projectInfo, status) => {
    dispatch(changeProjectStatus({ projectInfo, status }))
  }

  const renderProjectItem = useCallback(
    (value, index) => {
      const checkProjectTitle = () => {
        if (newTitle.title && newTitle.title !== value?.title)
          if (newTitle.title.trim().length > 4) {
            if (isProjectNameExists(newTitle.title.trim())) {
              setNewTitle({ ...newTitle, error: true })
              return errorToast('Folder name already exists')
            }
            renameProject(value?.title, value?.id)
          } else {
            setNewTitle({ ...newTitle, error: true })
            return errorToast('Folder name too short!')
          }
        return null
      }
      const handleOnClick = () => {
        dispatch(setIsScriptDrawerOpen(true))
        dispatch(updateAppInfoState({ currentProjectId: value.id }))
        createRouteForProject(value)
      }

      const options = ['Active', 'Rename', 'Pause', 'Move To Trash']

      const createRouteForProject = async (projectInfo) => {
        navigate(`/projects/${projectInfo.org_id}/${projectInfo.id}`)
      }
      // eslint-disable-next-line
      const HeadingComponent = (props) => {
        const { icon } = props
        return (
          <ListItem disablePadding active={currentProjectId === value?.id} key={value?.id} title={value?.title} className='org-row m-0'>
            <ListItemButton className='org-row p-1 group list-item' disableGutters>
              <Box
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  props?.onClickHeading()
                }}
              >
                {icon && icon}
              </Box>
              <Box
                className='flex items-center justify-between w-full'
                onClick={(e) => {
                  e.stopPropagation()
                  handleOnClick()
                  props?.onClickHeading()
                }}
              >
                <FolderOutlinedIcon className='mr-1' />
                <ListItemText
                  className='text-ellipsis overflow-hidden group-hover:pl-1 transition-all duration-500'
                  primary={value?.title}
                />
                {value?.status === '2' ? (
                  <>
                    <FiberManualRecordIcon fontSize='small' className='status-type always-visible' />
                    <div className='hover-visible'>
                      <FunctionsActionsButton
                        parentId={value?.title}
                        options={options}
                        handleOptionsBtn={handleActionBtns}
                        componentDetails={{ index, project: value }}
                      />
                    </div>
                  </>
                ) : (
                  <div className='hover-visible hover:flex items-center'>
                    <CreateNewPopover isButton={false} toolTipText='Create New Flow +' defaultProjectId={value?.id} />
                    <FunctionsActionsButton
                      parentId={value?.title}
                      options={options}
                      handleOptionsBtn={handleActionBtns}
                      componentDetails={{ index, project: value }}
                    />
                  </div>
                )}
              </Box>
            </ListItemButton>
          </ListItem>
        )
      }

      return projectId === value.id ? (
        <Box className='flex justify-between items-center !w-full' key={value?.id}>
          <TextField
            inputProps={{ maxLength: 50 }}
            size='small'
            fullWidth
            noborder='true'
            error={newTitle.error}
            autoFocus
            projectname='true'
            className='title-textfield'
            onBlur={() => {
              checkProjectTitle()
              setNewTitle({ ...newTitle, error: false })
              setProjectId('')
            }}
            defaultValue={value?.title}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkProjectTitle()
              }
            }}
            onChange={(e) => {
              setNewTitle({ title: e.target.value, error: false })
            }}
          />
        </Box>
      ) : (
        <FolderListAccordion HeadingComponent={HeadingComponent} isFolderOpen={currentProjectId === value?.id}>
          <div className='ml-2'>
            <ScriptListItemComponent
              scriptObject={projectWiseFlows?.[value?.id]}
              allScripts={allScripts}
              defaultProjectId={value?.id}
              id={value?.id}
            />
          </div>
        </FolderListAccordion>
      )
    },
    [currentProjectId, projectWiseFlows, handleActionBtns]
  )

  return isProjectListLoading ? (
    <Box className='flex flex-col'>
      <Box>
        <Skeleton width={80} variant='text' className='font-1rem' />
      </Box>

      <Box className='p-2 project_list__projects flex flex-col justify-start items-center gap-2'>
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
      </Box>

      <Box className='flex !w-full mt-2'>
        <Skeleton className='ml-2' variant='rectangular' width={120} height={30} />
      </Box>
    </Box>
  ) : (
    <Box className='project_list my-2'>
      <FolderList activePauseProject={activePauseProject} renderProjectItem={renderProjectItem} />
      <Box className='flex items-center justify-between mt-3 pb-1'>
        <Typography className='projectSliderHeading px-1 font-bold'>Flows</Typography>
        <CreateNewPopover defaultProjectId={`proj${orgId}`} isButton={false} toolTipText='Create New Flow' />
      </Box>
      <ScriptListItemComponent scriptObject={scriptObject} allScripts={allScripts} defaultProjectId={`proj${orgId}`} id={`proj${orgId}`} />

      <Box className='flex flex-col w-full mt-3'>
        {deleteProject.length > 0 && (
          <>
            <Box className='flex justify-between items-center p-2 pb-1 w-full'>
              <Typography className='w-full pb-2 projectSliderHeading'>Deleted Folders</Typography>
              {/* <Typography variant='smallHeading'>Deleted Folders</Typography> */}
            </Box>
            {deleteProject.map((value, index) => {
              return <>{renderProjectItem(value, index)}</>
            })}
          </>
        )}
      </Box>

      <CustomModal fullScreen={false} openModal={isModalOpen && modalType === 'MoveProject'} onClose={() => dispatch(closeModalRedux())}>
        <Box className='dialogMinMax'>
          <DuplicateFlowModal data={projectRef} title='Move Folder' />
        </Box>{' '}
        {/*  used  to move a folder from one org to another org */}
      </CustomModal>
      <CreateOrgModal />
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(Projectlist), [ParamsEnums.orgId, ParamsEnums.projectId, ParamsEnums.firstServiceId, ParamsEnums.serviceId])
)
